import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import './index.css';

export default function CustomSelect({
    sx, disabled, set, label, filterTag, options, optionsText, current, ...rest
}) {
    const handleChange = (event) => {
        set(event.target.value, filterTag, event)
    };

    return (
        <Box
        >
            <FormControl
                sx={{ width: '100%' }}
            >
                <InputLabel size='small' id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    disabled={disabled}
                    sx={{ ...sx }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={current}
                    label={label}
                    displayEmpty={false}
                    onChange={handleChange}
                    size='small'
                    {...rest}
                >
                    {options.map((e, i) => {
                        return <MenuItem key={e} value={e}>{optionsText[i]}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Box >
    );
}
