import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { TaskContext } from "../../AppStates";
import { Editor, EditorState, RichUtils, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
// import { RichEditorExample } from '../Components/Editor'
import { stateToHTML } from 'draft-js-export-html';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { Switch, TextField } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Images from '../ImagesAddComponent';
import CustomButton from '../Mui/CustomButton';
import Select, { components } from 'react-select';
import { Loader } from "../Loader/Loader";
import { useStrapiObjectClass } from "../../hooks/useStrapiObject";


const NaujienosModal = ({ deploy, setIsOpen, r, id, newNaujiena, update, setAlert, imagesForCreate, imagesArr }) => {
    const { token, path, pathImg } = useContext(TaskContext)

    const { t } = useTranslation()
    const [info, setInfo] = useState({})
    const [product, setProduct] = useState([])
    const [kategorijaO, setkategorijaO] = useState([])
    const [dutyO, setdutyO] = useState('')

    const { data: produktai, getAll: gautiProduktus } = useStrapiObjectClass({ name: 'produktai' })

    useEffect(() => {

        setInfo(deploy)
    }, [r, deploy])
    useEffect(() => { gautiProduktus() }, [gautiProduktus])
    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            color: '#DB643E',
            background: '#f0eee7',
            width: '100%',
        }),


        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';


            return { ...provided, opacity, transition };
        }
    }
    const Input = ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />;
    if (!info || !info.title) {

        return (
            <Loader />
        )
    }


    return (
        <div>
            <div className="flyingOne">
                <button className='closeBtn' onClick={() => { setIsOpen(false) }}>✕</button>
                <CustomButton
                    className='width100'
                    onClick={() => {
                        setAlert(true)
                        setTimeout(() => { setAlert(false) }, 3000)
                        if (id) {
                            let save = {
                                produktai: info.produktai,
                                text: info.text, subtitle: info.subtitle, title: info.title,
                                buttonName: info?.buttonName || '', buttonLink: info?.buttonLink || '',
                                date: info?.date || new Date(),
                                show: info.show || false
                            }
                            update(id, save).then(() => { r() });
                        }
                        else {
                            newNaujiena(info)
                        }
                    }}
                    text='issaugoti'
                />
            </div>
            <div className='newsName marginV10'>
                <a>{id ? t('redaguoti_naujiena') : t('prideti_naujiena')}</a>
            </div>
            <div className='modalTekstai naujienos'>
                <div className='modalTextsNews'>
                    <div className='modalContVidus marginV10'>

                        <TextField
                            color="primary"
                            variant="outlined"
                            type="text"
                            label={t('pavadinimas')}
                            size="small"
                            margin="none"
                            value={info?.title || ''}
                            onChange={(value) => { setInfo({ ...info, title: value.target.value }) }}
                        />

                        <label key={'suber'} className='left'> <h2>{t('antraste')}</h2>
                            <ReactQuill key={'antraste'} id={'subtitle'} theme="snow" value={info?.subtitle || ''}
                                onChange={(value) => {
                                    setInfo({ ...info, subtitle: value })
                                }}
                            /></label>
                        <label key={'texter'} className='left'> <h3>{t('aprasymas')}</h3>
                            <ReactQuill id={'texter'} theme="snow" value={info?.text || ''}
                                onChange={(value) => {
                                    setInfo({ ...info, text: value })
                                }}
                            /></label>
                        <div>
                            <div>
                                <h3>{t('data_ijungimas')}</h3>
                                <input type="date" value={info?.date || new Date()} onChange={(value) => {
                                    setInfo({ ...info, date: value.target.value })
                                }} />
                                <Switch
                                    checked={info?.show || false}
                                    label={t('ijungimas')}
                                    onChange={(value) => {
                                        setInfo({ ...info, show: value.target.checked })
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            <div className='mainImg'>
                            </div>
                            <h3> {t('main_img')} </h3>
                            <Images
                                id={id}
                                count={1}
                                api={"naujiena"}
                                info={info && info.showImg ? [info.showImg.data] : []}
                                type={'showImg'}
                                imagesArr={imagesArr}
                                imagesForCreate={(v) => { imagesForCreate(v); }}
                                r={() => {
                                    if (id) r()
                                }}
                            />
                        </div>
                        <h3>{t('btnFields')}</h3>
                        <div className="gridColumn">

                            <div className="modalContVidus">

                                <TextField
                                    color="primary"
                                    variant="outlined"
                                    type="text"
                                    label={t('buttonLink')}
                                    size="small"
                                    margin="none"
                                    value={info?.buttonLink || ''}
                                    onChange={(value) => { setInfo({ ...info, buttonLink: value.target.value }) }}
                                />
                            </div>
                            <div className="modalContVidus">
                                <TextField
                                    color="primary"
                                    variant="outlined"
                                    type="text"
                                    label={t('buttonName')}
                                    size="small"
                                    margin="none"
                                    value={info?.buttonName || ''}
                                    onChange={(value) => { setInfo({ ...info, buttonName: value.target.value }) }}
                                />

                            </div>


                            <Select
                                ket={'produktai-select'}
                                styles={customStyles}
                                className="select"
                                placeholder={t('produktai')}
                                isMulti={true}
                                isSearchable={true}
                                options={produktai.map(e => {
                                    return { value: e.id, label: e.attributes.title }
                                }) || []}

                                value={
                                    info?.produktai?.produktai.map((e) => {
                                        return { label: e.title, value: e.id }
                                    }) || []
                                }
                                components={{
                                    Input
                                }}
                                onChange={(value) => {
                                    let arr = value.map(e => {
                                        return { title: e.label, id: e.value }
                                    })
                                    setInfo({
                                        ...info, produktai: { produktai: arr },
                                    })
                                }}

                            />

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )


}
export default NaujienosModal

const selecterinoMulti = (value) => {
    let arr = []
    value.map((e, i) => {
        arr.push(e.value)
    })
    return arr + ''
}
