export const ltLTGrid = {
    // Root
    noRowsLabel: 'Nėra eilučių',
    noResultsOverlayLabel: 'Rezultatų nerasta',

    // Density selector toolbar button text
    toolbarDensity: 'Tankis',
    toolbarDensityLabel: 'Tankis',
    toolbarDensityCompact: 'Kompaktiškas',
    toolbarDensityStandard: 'Standartinis',
    toolbarDensityComfortable: 'Patogus',

    // Columns selector toolbar button text
    toolbarColumns: 'Stulpeliai',
    toolbarColumnsLabel: 'Pasirinkti stulpelius',

    // Filters toolbar button text
    toolbarFilters: 'Filtrai',
    toolbarFiltersLabel: 'Rodyti filtrus',
    toolbarFiltersTooltipHide: 'Slėpti filtrus',
    toolbarFiltersTooltipShow: 'Rodyti filtrus',
    toolbarFiltersTooltipActive: (count) =>
        count > 1 ? `${count} aktyvūs filtrai` : `${count} aktyvus filtras`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Ieškoti...',
    toolbarQuickFilterLabel: 'Ieškoti',
    toolbarQuickFilterDeleteIconLabel: 'Išvalyti',

    // Export selector toolbar button text
    toolbarExport: 'Eksportuoti',
    toolbarExportLabel: 'Eksportuoti',
    toolbarExportCSV: 'Atsisiųsti kaip CSV',
    toolbarExportPrint: 'Spausdinti',
    toolbarExportExcel: 'Atsisiųsti kaip Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Paieškos stulpelis',
    columnsPanelTextFieldPlaceholder: 'Stulpelio pavadinimas',
    columnsPanelDragIconLabel: 'Perstumti stulpelį',
    columnsPanelShowAllButton: 'Rodyti viską',
    columnsPanelHideAllButton: 'Slėpti viską',

    // Filter panel text
    filterPanelAddFilter: 'Pridėti filtrą',
    // filterPanelRemoveAll: 'Remove all',
    filterPanelDeleteIconLabel: 'Ištrinti',
    filterPanelLogicOperator: 'Logikos operatorius',
    filterPanelOperator: 'Operatoriai',
    filterPanelOperatorAnd: 'Ir',
    filterPanelOperatorOr: 'Arba',
    filterPanelColumns: 'Stulpeliai',
    filterPanelInputLabel: 'Reikšmė',
    filterPanelInputPlaceholder: 'Filtro reikšmė',

    // Filter operators text
    filterOperatorContains: 'turi',
    filterOperatorEquals: 'lygu',
    filterOperatorStartsWith: 'prasideda nuo',
    filterOperatorEndsWith: 'baigiasi į',
    filterOperatorIs: 'yra',
    filterOperatorNot: 'nėra',
    filterOperatorAfter: 'po',
    filterOperatorOnOrAfter: 'arba po',
    filterOperatorBefore: 'prieš',
    filterOperatorOnOrBefore: 'arba prieš',
    filterOperatorIsEmpty: 'yra tuščias',
    filterOperatorIsNotEmpty: 'nėra tuščias',
    filterOperatorIsAnyOf: 'yra bet kuris iš',
    // 'filterOperator=': '=',
    // 'filterOperator!=': '!=',
    // 'filterOperator>': '>',
    // 'filterOperator>=': '>=',
    // 'filterOperator<': '<',
    // 'filterOperator<=': '<=',

    // Header filter operators text
    // headerFilterOperatorContains: 'Contains',
    // headerFilterOperatorEquals: 'Equals',
    // headerFilterOperatorStartsWith: 'Starts with',
    // headerFilterOperatorEndsWith: 'Ends with',
    // headerFilterOperatorIs: 'Is',
    // headerFilterOperatorNot: 'Is not',
    // headerFilterOperatorAfter: 'Is after',
    // headerFilterOperatorOnOrAfter: 'Is on or after',
    // headerFilterOperatorBefore: 'Is before',
    // headerFilterOperatorOnOrBefore: 'Is on or before',
    // headerFilterOperatorIsEmpty: 'Is empty',
    // headerFilterOperatorIsNotEmpty: 'Is not empty',
    // headerFilterOperatorIsAnyOf: 'Is any of',
    // 'headerFilterOperator=': 'Equals',
    // 'headerFilterOperator!=': 'Not equals',
    // 'headerFilterOperator>': 'Greater than',
    // 'headerFilterOperator>=': 'Greater than or equal to',
    // 'headerFilterOperator<': 'Less than',
    // 'headerFilterOperator<=': 'Less than or equal to',

    // Filter values text
    filterValueAny: 'bet koks',
    filterValueTrue: 'taip',
    filterValueFalse: 'ne',

    // Column menu text
    columnMenuLabel: 'Meniu',
    columnMenuShowColumns: 'Rodyti stulpelius',
    columnMenuManageColumns: 'Tvarkyti stulpelius',
    columnMenuFilter: 'Filtruoti',
    columnMenuHideColumn: 'Slėpti',
    columnMenuUnsort: 'Nerūšiuoti',
    columnMenuSortAsc: 'Rūšiuoti didėjančia tvarka',
    columnMenuSortDesc: 'Rūšiuoti mažėjančia tvarka',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count > 1 ? `${count} aktyvūs filtrai` : `${count} aktyvus filtras`,
    columnHeaderFiltersLabel: 'Rodyti filtrus',
    columnHeaderSortIconLabel: 'Rūšiuoti',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count > 1
            ? `${count.toLocaleString()} pasirinktos eilutės`
            : `${count.toLocaleString()} pasirinkta eilutė`,

    // Total row amount footer text
    footerTotalRows: 'Viso eilučių:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} iš ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Pažymėti langelį',
    checkboxSelectionSelectAllRows: 'Pažymėti visas eilutes',
    checkboxSelectionUnselectAllRows: 'Atžymėti visas eilutes',
    checkboxSelectionSelectRow: 'Pažymėti eilutę',
    checkboxSelectionUnselectRow: 'Atžymėti eilutę',

    // Boolean cell text
    booleanCellTrueLabel: 'taip',
    booleanCellFalseLabel: 'ne',

    // Actions cell more text
    actionsCellMore: 'daugiau',

    // Column pinning text
    pinToLeft: 'Prisegti kairėje',
    pinToRight: 'Prisegti dešinėje',
    unpin: 'Atsegti',

    // Tree Data
    treeDataGroupingHeaderName: 'Grupuoti',
    treeDataExpand: 'Rodyti vaikus',
    treeDataCollapse: 'Slėpti vaikus',

    // Grouping columns
    groupingColumnHeaderName: 'Grupuoti',
    groupColumn: (name) => `Grupuoti pagal ${name}`,
    unGroupColumn: (name) => `Negrupuoti pagal ${name}`,

    // Master/detail
    detailPanelToggle: 'Perjungti detalę',
    expandDetailPanel: 'Išskleisti',
    collapseDetailPanel: 'Sutraukti',

    // Row reordering text
    rowReorderingHeaderName: 'Perstumti eilutes',

    // Aggregation
    aggregationMenuItemHeader: 'Aggregacija',
    aggregationFunctionLabelSum: 'sum',
    aggregationFunctionLabelAvg: 'avg',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'dydis',
};