

import { useEffect } from "react";
import { useContext, useState } from "react";
import TaskContextProvider, { TaskContext } from "../AppStates";
import importAll from "../functions/importAll";
import DataGridDarbai from "../Components/DataGridDarbai";
import ArchiveModal from "../Components/ModalArchive";
import AddArchyve from "../Components/AddArchyve";
import DELETE from "../functions/delete";
import Modal from 'react-modal';
import { t } from 'i18next';
import LoadingTimer from "../functions/LoadingOn";
import { TextField } from "@mui/material";
import Search from "../assets/search.png";
import Archyvuoti from "../functions/archyvuoti";
import CustomButton from "../Components/Mui/CustomButton";
import { useUser } from "../hooks/useUser";
import AddIcon from '@mui/icons-material/Add';


const Darbai = () => {
    const { token, user, path, type } = useContext(TaskContext)
    const [darbai, setDarbai] = useState([])
    const [loading, setLoading] = useState(true)
    const [sortBy, setSortBy] = useState(0)
    const [tvarkyta, setTvarkya] = useState([])
    const [r, setR] = useState([])
    const [filter, setFilteris] = useState('')
    const [modal, setModal] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [id, setId] = useState('')
    const [id2, setId2] = useState('')
    const { users: useriai, getAllUsers } = useUser()
    const get = async () => {
        await importAll(path, token, 'darbai?pagination[limit]=9999999', setDarbai);
    }
    const [deleteModal, setDeleteModal] = useState(false)
    const [openSearch, setOpenSearch] = useState(false)


    useEffect(() => {
        get();
        getAllUsers()
    }, [])
    useEffect(() => { redoData(darbai, setTvarkya); }, [darbai, filter])
    useEffect(() => { get(); getAllUsers() }, [r])
    const matomiDaruotojui = [
        t('planuojama'),
        t('priskirtas'),
        t('vykdoma'),
        t('neapmoketa'),
        // t('apmoketa'),
        t('priskirtas_tesiamas'),
        // t('pavedimu'),
        // t('pavedimu_pratestas'),
        // t('nepriskirtas_pratestas'),
        // t('pabaigta'),
        // t('atsaukta'),
        t('samata'),
        // t('samata_baigta'),

    ]
    const checkStatuses = (status, arr) => {
        if (arr.includes(status)) {
            return true
        }
        else return false

    }
    const getVardas = (element) => {
        let id = element.attributes.darbuotojoId
        let vardas = ''
        useriai.map(darbuotojas => {
            if (darbuotojas.id == id) {
                vardas = darbuotojas.Pavarde
                return vardas
            }
        })
        return vardas
    }

    const redoData = async (darbai, setter) => {//data for dataGrid
        var arr = []
        // darbai= darbai.sort(()=>{})

        darbai = filtruoti(darbai)

        darbai.forEach(async (element) => {
            if (!user.is_admin && !user.is_buhaltere) {
                if (element.attributes.darbuotojoId != user.id || !checkStatuses(element.attributes.statusas, matomiDaruotojui)) {
                    return
                }
            }
            if (element.attributes.archyvuotas == true) {
                return
            }

            let vardas = getVardas(element) || ''
            arr.push(
                {
                    id: element.id,
                    seni: element.attributes.seni,
                    data: element.attributes.data[0],
                    // data: element.attributes.data[0] ? makeDate(element.attributes.data) + ' ' + element.attributes.Laikas : '',
                    laikas: element.attributes.Laikas[0] ? element.attributes.Laikas : '',
                    adresas: element.attributes.adress,
                    tel: element.attributes.tel,
                    kategorija: element.attributes.kategorija,
                    darbuotojoId: vardas,
                    statusas: element.attributes.statusas,
                    modal: (id) => { setModal(true); setId(id) },
                    delete: (id) => { setDeleteModal(true); setId(id) },
                    archyvui: (id) => { if (user.is_admin || user.is_buhalere) { setDeleteModal(true); setId(id) } }

                }
            )
        });
        setter(arr)
        return arr
    }
    const filtruoti = (arr) => {
        var ats = []
        if (filter) {
            arr.forEach(e => {
                e.id = e.id + ''
                if (e.id.toUpperCase().includes(filter.toUpperCase()) ||
                    e.attributes.data[0] && e.attributes.data[0].replaceAll('-', '.').toUpperCase().includes(filter.toUpperCase()) || e.attributes.statusas.toUpperCase().includes(filter.toUpperCase())) {
                    ats.push(e)
                }
            })
            return ats
        } else {
            return arr
        }
    }

    const handleEvent = (obj) => {
        setModal(true); setId(obj.id)
    };
    const handleEvent2 = (obj) => {
        setAddModal(true); setId2(obj.id)
    };
    return (
        <div className='container'>
            <LoadingTimer />
            {darbai.length == 0 ? (
                <div></div>
            ) : (
                <div className='archyvas mainarch'>
                    <div className="dataGridSearch spaceBetween">
                        <div>
                            <img
                                onClick={() => { openSearch ? setOpenSearch(false) : setOpenSearch(true) }}
                                src={Search} />
                            {
                                openSearch ?
                                    <TextField
                                        className="marginH10"
                                        key={'filtras'}
                                        label={t('paieska')}
                                        // multiline
                                        size="small"
                                        maxRows={1}
                                        value={filter}
                                        onChange={(value) => { setFilteris(value.target.value) }}
                                    />
                                    : ''
                            }
                        </div>
                        {
                            type == 2 ?
                                '' :
                                <AddIcon
                                    color="primary"
                                    fontSize="large"
                                    onClick={() => {
                                        setAddModal(true)
                                        setId(null)
                                    }}
                                />
                        }
                    </div>
                    <DataGridDarbai data={tvarkyta} user={user} onRowClick={handleEvent} />
                </div>
            )}
            {modal ?
                <ArchiveModal id={id} setIsOpen={setModal} modalIsOpen={modal} path={path} token={token} r={r} setR={() => setR(r + 1)} />
                :
                ''
            }
            {
                addModal ?
                    <AddArchyve id={id} setIsOpen={setAddModal} modalIsOpen={addModal} path={path} token={token} r={r} setR={() => setR(r + 1)} />
                    :
                    ''
            }


            <Modal
                isOpen={deleteModal}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setDeleteModal(false) }}
            >
                <div
                    className="centerContainer1 column">


                    <button className="closeBtnMod" onClick={() => {
                        setDeleteModal(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_tikrai_archyvuoti')}?</h4>
                    </div>
                    <div  >

                        <CustomButton
                            onClick={async () => {
                                await Archyvuoti(path, token, 'darbai/', id)
                                await get()

                                setDeleteModal(false); setR(r + 1)
                            }}
                            text='taip'
                        />
                    </div>
                </div>
            </Modal>

        </div>
    )
}
export default Darbai


const sorting = (item, i) => {
    let start = item.slice(0)
    var arr
    if (i == 0) {
        arr = item.sort((a, b) => parseInt(a.id) > parseInt(b.id) ? 1 : -1)
        return arr
    }
    else if (item != start) {

        arr = item.sort((a, b) => a.attributes[[weNeed.tags[i]]] > b.attributes[[weNeed.tags[i]]] ? 1 : -1)
        return arr
    }
    else {
        arr = item.sort((a, b) => a.attributes[[weNeed.tags[i]]] < b.attributes[[weNeed.tags[i]]] ? 1 : -1)
        return arr
    }
}

const weNeed = {
    names: ['ID', 'Data', 'Laikas', 'Kategorija', 'Statusas', 'Veiksmas'],
    tags: ['id', 'data', 'Laikas', 'kategorija', 'statusas', 'IDNr']
}

const makeDate = (date) => {
    // console.log(date)
    return new Date(date).toISOString().split('T')[0].replace('-', '.').replace('-', '.')
}