import * as React from "react";
import { DataGrid, GridComparatorFn } from "@mui/x-data-grid";
// import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ButtonUnstyled, Link } from '@mui/material';
import KategorijosNustatymas from "../functions/kategorijosNustatymas";
import Delete from "../assets/archive.png";
import Edit from "../assets/edit.png";
import { LineWeight } from "@mui/icons-material";
import Modal from 'react-modal';
import { t } from 'i18next';
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import MobileGrid from "./MobileGridDarbai";
import Delete1 from "../assets/delete.png";




const handleClick = (event, cellValues, props) => {
    console.log(props)
    // console.log(cellValues.row);
};

const handleCellClick = (param, event) => {
    event.stopPropagation();
};

const handleRowClick = (param, event) => {
    event.stopPropagation();
};
const dayInMonthComparator = (v1, v2) => {
    // v1 = v1.split(' ')
    // let v1l = parseInt(v1[1])
    // v1 = new Date(v1[0])
    // v1.setHours(v1l)
    // v2 = v2.split(' ')
    // let v2l = parseInt(v2[1])
    // v2 = new Date(v2[0])
    // v2.setHours(v2l)
    return new Date(v1).getTime() - new Date(v2).getTime()
}
const columns = [
    {
        field: "id", headerName: "ID", renderCell: (cellValues) => {
            var add = ''
            if (cellValues.row.seni) {
                add += '-' + parseInt(cellValues.row.seni.length + 1)
            } else {
                add += '-' + 1
            }
            var ats
            if (cellValues.row.data)
                ats = cellValues.row?.data.slice(0, 4) + '-' + cellValues.row.id + add;
            else {
                ats = '-' + cellValues.row.id + add
            }
            console.log(ats)
            return ats
        }
    },
    {
        field: "data", headerName: "Data", width: 100,
        // valueFormatter: ({ value }) => value.split(' ')[0], sortComparator: dayInMonthComparator 
    },
    // { field: "laikas", headerName: "Laikas", width: 200 },
    // {
    //     field: "kategorija", headerName: "Kategorija", width: 200, renderCell: (cellValues) => {
    //         return (
    //             <Tooltip title={cellValues.row.kategorija}>
    //                 <img
    //                     className={cellValues.row.kategorija}
    //                     src={KategorijosNustatymas(cellValues.row.kategorija)}
    //                     onClick={(event) => {
    //                         cellValues.row.modal(cellValues.row.id)
    //                         console.log('cellValue', cellValues.row)
    //                     }}
    //                 /></Tooltip>

    //         );
    //     }
    // },
    { field: "adresas", headerName: "Adresas", width: 220 },
    { field: "tel", headerName: "Tel. nr.", width: 150 },
    {
        field: "darbuotojoId", headerName: "Darbuotojas", width: 150,
        cellClassName: ({ value }) => value
    },
    {
        field: "statusas", headerName: "Statusas", width: 170,
        cellClassName: ({ value }) => value
    },

    {
        field: "Veiksmas", width: 100,
        sortable: false,
        filterable: false, hideable: false,
        showable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => {
            return (

                <div
                    className="deleteImg"
                    onClick={(event) => {
                        cellValues.row.modal(cellValues.row.id)
                    }}
                >
                    {/* { 'Redaguoti' : 'Priskirti'} */}
                    <img src={Edit} />
                </div>

            );
        }
    },
    {
        field: "Išarchyvuoti", width: 120,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => {
            return (
                !cellValues.row?.IDNr ?

                    <div
                        className="deleteImg"
                        onClick={(event) => {
                            cellValues.row.archyvui(cellValues.row.id)
                        }}
                    >
                        {/* {cellValues.row.IDNr ? 'Redaguoti' : 'Priskirti'} */}
                        {/* Istrinti */}
                        <img src={Delete} />
                    </div>
                    : ''

            );
        }
    },
    {
        field: "Ištrinti", width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => {
            return (
                !cellValues.row?.IDNr ?

                    <div
                        className="deleteImg1"
                        onClick={(event) => {
                            cellValues.row.delete(cellValues.row.id)
                        }}
                    >
                        <img src={Delete1} />
                    </div>
                    : ''

            );
        }
    }
];
export default function DataGridAdmin(props) {
    const rows = props.data
    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener('resize', () => {
        setWidth(window.innerWidth)

    });
    if (width < 1000) {
        return <MobileGrid
            rows={rows}
        />
    }
    return (
        <div className="dataGrid" >
            <DataGrid
                autoHeight={true}
                rowHeight={70}
                className={'dataGrid'}
                rows={rows}
                columns={columns}
                pageSize={25}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'statusas', sort: 'asc' }],
                    },
                }}
            // checkboxSelection
            // onCellClick={handleCellClick}
            // onRowClick={handleRowClick}
            />
        </div>
    );
}


const makeDate = (date) => {
    // console.log(date)
    return new Date(date).toISOString().split('T')[0].replace('-', '.').replace('-', '.')
}
const makeStatus = (status) => {
    let el = (<div className={status}>{status}</div>)
    console.log(el)
    return el
}