import * as React from "react";
import { DataGrid, GridComparatorFn } from "@mui/x-data-grid";
// import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ButtonUnstyled, Link } from '@mui/material';
import KategorijosNustatymas from "../functions/kategorijosNustatymas";
import Delete from "../assets/archive.png";
import Edit from "../assets/edit.png";
import { LineWeight } from "@mui/icons-material";
import Modal from 'react-modal';
import { t } from 'i18next';
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import MobileGrid from "./MobileGridDarbai";
import { DataGridPremium, GRID_AGGREGATION_FUNCTIONS, GridToolbar } from "@mui/x-data-grid-premium";
import { LicenseInfo } from '@mui/x-license-pro';
import { useTranslation } from "react-i18next";




const handleClick = (event, cellValues, props) => {
    console.log(props)
    // console.log(cellValues.row);
};

const handleCellClick = (param, event) => {
    event.stopPropagation();
};

const handleRowClick = (param, event) => {
    event.stopPropagation();
};
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'Eur',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});
const dayInMonthComparator = (v1, v2) => {
    v1 = v1.split(' ')
    let v1l = parseInt(v1[1])
    v1 = new Date(v1[0])
    v1.setHours(v1l)
    v2 = v2.split(' ')
    let v2l = parseInt(v2[1])
    v2 = new Date(v2[0])
    v2.setHours(v2l)
    return new Date(v1).getTime() - new Date(v2).getTime()
}
const columns = [

    { field: "data", headerName: "Data", width: 100, },
    {
        field: "darbuotojoId", headerName: "Darbuotojas", width: 150,
        cellClassName: ({ value }) => value
    },

    {
        field: "id", headerName: "ID", width: 100,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.id}>
                    <p onClick={() => {
                        cellValues.row.modal(cellValues.row.id)
                    }}>
                        {cellValues.row.id}
                    </p>

                </Tooltip>

            );
        }
    },
    {
        field: "time", headerName: "Išdirbtas laikas", width: 170,

    },
    {
        field: "visaKaina", type: 'number', headerName: "Visa suma", width: 170,
        cellClassName: ({ value }) => value
    },
    {
        field: "darboPajamos", type: 'number', headerName: "Darbo kaina", width: 170,
        cellClassName: ({ value }) => value
    },
    {
        field: "medziaguKaina", type: 'number', headerName: "Medžiagų kaina", width: 170,
        cellClassName: ({ value }) => value
    },
    {
        field: "sanaudos", headerName: "Darbo sąnaudos", width: 170,
        // cellClassName: ({ value }) => value,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.sanaudos}>
                    <p onClick={() => {
                        cellValues.row.sanaudosModal(cellValues.row.id, cellValues.row.Idnr)
                    }}>
                        {cellValues.row.sanaudos ? cellValues.row.sanaudos : 0}
                    </p>

                </Tooltip>

            );
        }
    },
    {
        field: "medziaguKainaPirkimas", type: 'number', headerName: "Medžiagų sąnaudos", width: 170,
        // cellClassName: ({ value }) => value,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.medziaguKainaPirkimas}>
                    <p onClick={() => {
                        cellValues.row.ivedamosMedziagosModal(cellValues.row.id, cellValues.row.Idnr)
                    }}>
                        {cellValues.row.medziaguKainaPirkimas ? cellValues.row.medziaguKainaPirkimas : 0}
                    </p>

                </Tooltip>

            );
        }
    },
    {
        field: "darboPelnas", type: 'number', headerName: "Darbo pelnas", width: 170,
        cellClassName: ({ value }) => value,
    },
    {
        field: "kategorija", type: 'number', headerName: "Kategorija", width: 90, renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.kategorija}>
                    <img
                        className={cellValues.row.kategorija}
                        src={KategorijosNustatymas(cellValues.row.kategorija)}
                        onClick={(event) => {
                            cellValues.row.modal(cellValues.row.id)
                        }}
                    /></Tooltip>

            );
        }
    },
    {
        field: "statusas", headerName: "Statusas", width: 170,
        cellClassName: ({ value }) => value
    },
];


export default function DataGridStatistika({ data, onRowClick }) {
    LicenseInfo.setLicenseKey('63cdcff003c86a961f1b47b5703dd5e0Tz0wLEU9MjUzNDA0ODY0MDAwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sS1Y9Mg==');
    const rows = data
    const [width, setWidth] = useState(window.innerWidth)
    const { t } = useTranslation()
    window.addEventListener('resize', () => {
        setWidth(window.innerWidth)

    });
    if (window.innerWidth < 1000) {
        return <MobileGrid
            rows={rows}

        />
    }
    const CustomAggregation = {
        label: "Time",
        getCellValue: ({ row }) => ({ time: row.timeCount }),
        apply: ({ values }) => {
            let sum = 0;
            values.forEach((value) => {
                if (value.time) {
                    sum += value.time;
                }
            });
            const { hours, minutes, seconds } = convertMillisecondsToHoursMinutes(sum);
            return hours + ':' + minutes;
        },
    };
    const CustomAggregationSanaudos = {
        label: "Sanaudos",
        getCellValue: ({ row }) => ({ sanaudos: row.sanaudos }),
        apply: ({ values }) => {
            let sum = 0;
            values.forEach((value) => {
                if (value.sanaudos) {
                    sum += value.sanaudos;
                }
            });
            return Math.round((sum) * 100) / 100 || 0;
        },
    };

    const CustomAggregationMedziaguKaina = {
        label: "Medžiagu sąnaudos",
        getCellValue: ({ row }) => ({ medziaguKainaPirkimas: row.medziaguKainaPirkimas }),
        apply: ({ values }) => {
            let sum = 0;
            values.forEach((value) => {
                if (value.medziaguKainaPirkimas) {
                    sum += value.medziaguKainaPirkimas;
                }
            });
            return sum;
        },
    };
    GRID_AGGREGATION_FUNCTIONS.time = CustomAggregation;
    GRID_AGGREGATION_FUNCTIONS.Sanaud = CustomAggregationSanaudos;
    GRID_AGGREGATION_FUNCTIONS.MedziaguPirkimas = CustomAggregationMedziaguKaina;
    return (
        <div className="dataGrid">
            <DataGridPremium
                slots={{ toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        printOptions: { disableToolbarButton: true },
                    },
                }}
                autoHeight={true}
                rowHeight={70}
                className={'dataGrid'}
                rows={data}
                pagination={true}
                columns={columns}
                localeText={ltLTGrid}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'data', sort: 'asc' }],
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    aggregation: {
                        model: {
                            visaKaina: 'sum',
                            darboPelnas: 'sum',
                            darboPajamos: 'sum',
                            sanaudos: 'Sanaud',
                            medziaguKainaPirkimas: 'MedziaguPirkimas',
                            medziaguKaina: 'sum',
                            time: 'time'
                        },
                    },
                }}

                // checkboxSelection
                // onCellClick={handleCellClick}
                // onRowClick={handleRowClick}
                onRowClick={(e) => { onRowClick(e) }}

            />
        </div>
    );
}
function convertMillisecondsToHoursMinutes(milliseconds) {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return { hours, minutes, seconds };
}

const makeDate = (date) => {
    // console.log(date)
    return new Date(date).toISOString().split('T')[0].replace('-', '.').replace('-', '.')
}
const makeStatus = (status) => {
    let el = (<div className={status}>{status}</div>)
    console.log(el)
    return el
}

export const ltLTGrid = {
    // Root
    noRowsLabel: 'Nėra eilučių',
    noResultsOverlayLabel: 'Rezultatų nerasta',

    // Density selector toolbar button text
    toolbarDensity: 'Tankis',
    toolbarDensityLabel: 'Tankis',
    toolbarDensityCompact: 'Kompaktiškas',
    toolbarDensityStandard: 'Standartinis',
    toolbarDensityComfortable: 'Patogus',

    // Columns selector toolbar button text
    toolbarColumns: 'Stulpeliai',
    toolbarColumnsLabel: 'Pasirinkti stulpelius',

    // Filters toolbar button text
    toolbarFilters: 'Filtrai',
    toolbarFiltersLabel: 'Rodyti filtrus',
    toolbarFiltersTooltipHide: 'Slėpti filtrus',
    toolbarFiltersTooltipShow: 'Rodyti filtrus',
    toolbarFiltersTooltipActive: (count) =>
        count > 1 ? `${count} aktyvūs filtrai` : `${count} aktyvus filtras`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Ieškoti...',
    toolbarQuickFilterLabel: 'Ieškoti',
    toolbarQuickFilterDeleteIconLabel: 'Išvalyti',

    // Export selector toolbar button text
    toolbarExport: 'Eksportuoti',
    toolbarExportLabel: 'Eksportuoti',
    toolbarExportCSV: 'Atsisiųsti kaip CSV',
    toolbarExportPrint: 'Spausdinti',
    toolbarExportExcel: 'Atsisiųsti kaip Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Paieškos stulpelis',
    columnsPanelTextFieldPlaceholder: 'Stulpelio pavadinimas',
    columnsPanelDragIconLabel: 'Perstumti stulpelį',
    columnsPanelShowAllButton: 'Rodyti viską',
    columnsPanelHideAllButton: 'Slėpti viską',

    // Filter panel text
    filterPanelAddFilter: 'Pridėti filtrą',
    // filterPanelRemoveAll: 'Remove all',
    filterPanelDeleteIconLabel: 'Ištrinti',
    filterPanelLogicOperator: 'Logikos operatorius',
    filterPanelOperator: 'Operatoriai',
    filterPanelOperatorAnd: 'Ir',
    filterPanelOperatorOr: 'Arba',
    filterPanelColumns: 'Stulpeliai',
    filterPanelInputLabel: 'Reikšmė',
    filterPanelInputPlaceholder: 'Filtro reikšmė',

    // Filter operators text
    filterOperatorContains: 'turi',
    filterOperatorEquals: 'lygu',
    filterOperatorStartsWith: 'prasideda nuo',
    filterOperatorEndsWith: 'baigiasi į',
    filterOperatorIs: 'yra',
    filterOperatorNot: 'nėra',
    filterOperatorAfter: 'po',
    filterOperatorOnOrAfter: 'arba po',
    filterOperatorBefore: 'prieš',
    filterOperatorOnOrBefore: 'arba prieš',
    filterOperatorIsEmpty: 'yra tuščias',
    filterOperatorIsNotEmpty: 'nėra tuščias',
    filterOperatorIsAnyOf: 'yra bet kuris iš',
    // 'filterOperator=': '=',
    // 'filterOperator!=': '!=',
    // 'filterOperator>': '>',
    // 'filterOperator>=': '>=',
    // 'filterOperator<': '<',
    // 'filterOperator<=': '<=',

    // Header filter operators text
    // headerFilterOperatorContains: 'Contains',
    // headerFilterOperatorEquals: 'Equals',
    // headerFilterOperatorStartsWith: 'Starts with',
    // headerFilterOperatorEndsWith: 'Ends with',
    // headerFilterOperatorIs: 'Is',
    // headerFilterOperatorNot: 'Is not',
    // headerFilterOperatorAfter: 'Is after',
    // headerFilterOperatorOnOrAfter: 'Is on or after',
    // headerFilterOperatorBefore: 'Is before',
    // headerFilterOperatorOnOrBefore: 'Is on or before',
    // headerFilterOperatorIsEmpty: 'Is empty',
    // headerFilterOperatorIsNotEmpty: 'Is not empty',
    // headerFilterOperatorIsAnyOf: 'Is any of',
    // 'headerFilterOperator=': 'Equals',
    // 'headerFilterOperator!=': 'Not equals',
    // 'headerFilterOperator>': 'Greater than',
    // 'headerFilterOperator>=': 'Greater than or equal to',
    // 'headerFilterOperator<': 'Less than',
    // 'headerFilterOperator<=': 'Less than or equal to',

    // Filter values text
    filterValueAny: 'bet koks',
    filterValueTrue: 'taip',
    filterValueFalse: 'ne',

    // Column menu text
    columnMenuLabel: 'Meniu',
    columnMenuShowColumns: 'Rodyti stulpelius',
    columnMenuManageColumns: 'Tvarkyti stulpelius',
    columnMenuFilter: 'Filtruoti',
    columnMenuHideColumn: 'Slėpti',
    columnMenuUnsort: 'Nerūšiuoti',
    columnMenuSortAsc: 'Rūšiuoti didėjančia tvarka',
    columnMenuSortDesc: 'Rūšiuoti mažėjančia tvarka',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count > 1 ? `${count} aktyvūs filtrai` : `${count} aktyvus filtras`,
    columnHeaderFiltersLabel: 'Rodyti filtrus',
    columnHeaderSortIconLabel: 'Rūšiuoti',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count > 1
            ? `${count.toLocaleString()} pasirinktos eilutės`
            : `${count.toLocaleString()} pasirinkta eilutė`,

    // Total row amount footer text
    footerTotalRows: 'Viso eilučių:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} iš ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Pažymėti langelį',
    checkboxSelectionSelectAllRows: 'Pažymėti visas eilutes',
    checkboxSelectionUnselectAllRows: 'Atžymėti visas eilutes',
    checkboxSelectionSelectRow: 'Pažymėti eilutę',
    checkboxSelectionUnselectRow: 'Atžymėti eilutę',

    // Boolean cell text
    booleanCellTrueLabel: 'taip',
    booleanCellFalseLabel: 'ne',

    // Actions cell more text
    actionsCellMore: 'daugiau',

    // Column pinning text
    pinToLeft: 'Prisegti kairėje',
    pinToRight: 'Prisegti dešinėje',
    unpin: 'Atsegti',

    // Tree Data
    treeDataGroupingHeaderName: 'Grupuoti',
    treeDataExpand: 'Rodyti vaikus',
    treeDataCollapse: 'Slėpti vaikus',

    // Grouping columns
    groupingColumnHeaderName: 'Grupuoti',
    groupColumn: (name) => `Grupuoti pagal ${name}`,
    unGroupColumn: (name) => `Negrupuoti pagal ${name}`,

    // Master/detail
    detailPanelToggle: 'Perjungti detalę',
    expandDetailPanel: 'Išskleisti',
    collapseDetailPanel: 'Sutraukti',

    // Row reordering text
    rowReorderingHeaderName: 'Perstumti eilutes',

    // Aggregation
    aggregationMenuItemHeader: 'Aggregacija',
    aggregationFunctionLabelSum: 'sum',
    aggregationFunctionLabelAvg: 'avg',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'dydis',
};