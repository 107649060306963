import React, { useEffect, useState, useReducer, useCallback } from 'react';
import Modal from 'react-modal';
import ImportAll from '../functions/importAll';
import Update from '../functions/update.js';
// import './css/Modal.css';
import { useTranslation } from 'react-i18next';
import ModalVidusKablukasMaster from './ModalVidusKablukasMaster'
import ModalVidusKablukas from './ModalVidusKablukas'
import ModalTimePickerVidus from './ModalTImePickerVidus';
import ModalVidusUserPicker from './ModlasVidusUserPickerKablukas';
import { useStrapiObjectClass } from '../hooks/useStrapiObject';
import { useUser } from '../hooks/useUser';
const ArchiveModal = ({ kablukai, id, kategorija, setIsOpen, modalIsOpen, r, setR }) => {
    const { t } = useTranslation()
    const { data: all, getSingle, update: update } = useStrapiObjectClass({ name: kategorija == "Master" ? 'master-kablukai' : 'kablukas' })
    const { data: master, getAll: getMaster, update: updateMasterino } = useStrapiObjectClass({ name: 'master-kablukai' })
    const { users: darbuotojai, getAllUsers: GetDarbuotojai, getUser: GetDarbuotojas, updateUser } = useUser({ extraFilter: '?filters[is_admin][$eq]=false&filters[is_buhaltere][$eq]=false' })
    const [info, setInfo] = useState({})
    const [, setAll] = useState(all || [])
    const [deploy, setDeploy] = useState({})
    const [, setMaster] = useState(master || [])
    const [darbuotojas, setDarbuotojas] = useState({})
    const [, setDarbuotojai] = useState(darbuotojai || [])
    const [timePicker, setTimePicker] = useState(false)
    const [userPicker, setUserPicker] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)



    const updateMaster = async (id, data) => {
        await updateMasterino(id, data, setMaster);
        all.forEach(element => {
            element.attributes.medziagos = data.medziagos;
            update(element.id, element.attributes)
        }); setR(r + 1)
    }
    const removeByIndexFromAll = (index) => {
        all.forEach(element => {
            element.attributes.vienetai.splice(index, 1)
            update(element.id, element.attributes)
        });
    }
    const laikaKeitis = async (id, data) => { setTimePicker(true) }
    const change = (what, value) => { setDeploy(...deploy, { attributes: { [what]: value } }) }

    useEffect(() => {
        getSingle(id, setInfo);
        getMaster()
    }, [getSingle, getMaster, kablukai, r])

    useEffect(() => {
        if (Object.keys(info).length != 0 && Object.keys(master).length != 0) {
            if (info?.attributes.kategorija) {
                setDeploy(info)
                let arr = master[0].attributes.medziagos.map(e => {
                    return e.kiekis
                });
                // getMaster()
            }
            if (info.attributes.darbuotojoId) {
                GetDarbuotojas(info.attributes.darbuotojoId)
            } else {
                setDarbuotojas({})
            }
        }

    }, [info, master])
    const openUserPicker = useCallback(() => {
        if (Object.keys(darbuotojai).length != 0) {

            setUserPicker(true)
        }
    }, [darbuotojai])

    // useEffect(() => { getSingle(id, setInfo); setR() }, [r])

    return (
        Object.keys(deploy) != 0 ?
            <Modal
                className={timePicker ? 'modal timePicker' : 'modal'}
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onRequestClose={() => { timePicker ? setTimePicker(false) : userPicker ? setUserPicker(false) : setIsOpen(false); r() }}
                contentLabel="Example Modal"

            >
                {userPicker ?
                    <div>
                        <ModalVidusUserPicker
                            tag={'kablukas'}
                            key={'userPicker'}
                            info={darbuotojai}
                            deploy={deploy}
                            id={deploy.attributes.darbuotojoId}
                            update={async (id, info, userId) => {
                                update(id, { ...info, darbuotojoId: userId, statusas: 'Priskirtas' }).then(() => setUserPicker(false));
                            }}
                            updateUser={(id, info) => { updateUser(id, info) }}
                            close={() => { setUserPicker(false) }}
                        />
                    </div>
                    : !timePicker ?
                        <div>
                            <h1 className=' modalHeaderName'> {info.attributes.Numeris != null ? 'Kablukas  ' + info.attributes.Numeris : 'Master'}</h1>
                            {

                                kategorija == "Master" ?
                                    <ModalVidusKablukasMaster
                                        key={'modal'}
                                        selectedIndex={selectedIndex}
                                        r={() => { setR(r + 1) }}
                                        kategorija={kategorija}
                                        // master={master}
                                        info={info}
                                        laikaKeitis={(id, info) => { laikaKeitis(id, info) }}
                                        set={(what, value) => { change(what, value) }}
                                        update={(value, data, index) => { updateMaster(value, data) }}
                                        remove={(index) => { removeByIndexFromAll(index) }}
                                    />
                                    :
                                    <ModalVidusKablukas
                                        key={'modal'}
                                        selectedIndex={selectedIndex}
                                        r={() => {
                                            r(); getSingle(id, setInfo);
                                            console.log(deploy)
                                        }}
                                        kategorija={kategorija}
                                        data={deploy}
                                        master={master[0] ? master[0] : []}
                                        getMaster={() => { getMaster(); }}
                                        laikaKeitis={(id, info) => { laikaKeitis(id, info) }}
                                        set={(what, value) => { change(what, value) }}
                                        setDarbuotojas={setDarbuotojas}
                                        update={update}
                                        id={id}
                                        darbuotojas={darbuotojas}
                                        updateUser={(id, info) => { updateUser(id, info).then(() => { setUserPicker(false) }) }}
                                        modalGetDarbuotojai={() => { GetDarbuotojai().then(() => { openUserPicker() }) }}
                                    />}
                            <button className='closeBtnKab' onClick={() => { setIsOpen(false) }}>✕</button>
                        </div>
                        :
                        <ModalTimePickerVidus
                            info={deploy}
                            update={(id, info) => { update(id, info); setTimePicker(false) }}
                            close={() => { setTimePicker(false) }}
                        />
                }
            </Modal>
            : ''
    )
}
export default ArchiveModal


