import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { TaskContext } from "../AppStates";

export const useEmail = () => {
    const { token, path } = useContext(TaskContext);

    const sendEmail = useCallback(async (email = []) => {
        var data = {

            from: 'jusu@ukvedziai.lt',
            ...email,

        }
        console.log(data)
        return axios
            .post(path + 'mail-to', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            })
            .then((response) => {
                {
                    console.log(response)
                    return response.data.data
                }
            })
            .catch((error) => {
                console.log('An error occurred:', error);
            });
    }, [token, path])


    // Request API. 

    return {
        sendEmail,
    }
}
