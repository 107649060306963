import React, { useEffect, useState, useReducer } from 'react';
import Modal from 'react-modal';
import Pratesimas from '../functions/pratesimas.js';
import './css/Modal.css';
import { useTranslation } from 'react-i18next';
import ModalVidus from './ModalVidusAddDarbas'
import ModalTimePickerVidus from './ModalTImePickerVidus';
import ModalVidusUserPicker from './ModlasVidusUserPicker';
import ModalMedziaguPicker from './ModlasMedziaguPicker.js';
import Alert from '@mui/material/Alert';
import { useContext } from 'react';
import { TaskContext } from '../AppStates';
import { useStrapiObjectClass } from '../hooks/useStrapiObject';
import { useUser } from '../hooks/useUser';
import Info from './MainModal/Info'
import SfInfo from './MainModal/SfInfo'
import Photos from './MainModal/Photos';
import Comments from './MainModal/Comments';
import CustomButton from './Mui/CustomButton';
import { object } from 'prop-types';
import Materials from './MainModal/Materials';
import Discount from './MainModal/Discount';
import Payment from './MainModal/Payment';
import Price from './MainModal/Price';
import CustomSignature from './MainModal/CustomSignature'



const AddArchyve = ({ id, setIsOpen, modalIsOpen, r, setR, medziagos }) => {
    const { user, path, token } = useContext(TaskContext)
    const { t } = useTranslation()
    const [info, setInfo] = useState({})
    const [deploy, setDeploy] = useState({})
    const [timePicker, setTimePicker] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [darbuotojas, setDarbuotojas] = useState({})
    const [userPicker, setUserPicker] = useState(false)
    const [medziaguPicker, setMedziaguPicker] = useState(false)
    const [selectOpen, setSelectOpen] = useState(false)
    const [alert, setAlert] = useState(false)
    const { users: darbuotojai, getAllUsers: GetDarbuotojai, getUser: GetDarbuotojas } = useUser({ extraFilters: '?filters[type][$eq]=Meistras(-ė)' })
    const { data: esamas, getSingle, update: update, create: create } = useStrapiObjectClass({ name: 'darbai', extraFilter: '&populate=*' })


    useEffect(() => { if (alert) setTimeout(() => { setAlert(false) }, 3000) }, [alert])
    const laikaKeitis = () => { }
    const update1 = () => { }

    return (

        <div>
            <Modal
                className={timePicker ? 'modal2 timePicker' : 'modal2'}
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onRequestClose={() => {
                    timePicker ? setTimePicker(false) : userPicker ? setUserPicker(false) : medziaguPicker ? setMedziaguPicker(false) : setIsOpen(false);

                    setR();
                }

                }
                contentLabel="Example Modal"
            >
                <div className='modalaz testModal column'>
                    <Info
                        info={info || {}}
                        setInfo={setInfo}
                        laikaKeitis={laikaKeitis}
                        id={id}
                        r={r}
                        update={update1}

                    />
                    <SfInfo
                        info={info || {}}
                        setInfo={setInfo}
                        id={id}
                        r={r}
                        update={update1}
                    />
                    {/* <Photos
                            deploy={info}
                            id={id}
                            r={r}
                        /> */}
                    <Comments
                        info={info || {}}
                        setInfo={setInfo}
                        id={id}
                        r={r}
                        update={update1}
                    />

                    {/* <Materials
                        medziagos={() => { setMedziaguPicker(true); }}
                        info={info}
                        setInfo={setInfo}
                        update={update1}
                        id={id}
                    />
                    <Discount
                        info={info || {}}
                        setInfo={setInfo}
                        update={update}
                        id={id}
                    /> */}
                    {/* <Price
                        info={info || {}}
                        setInfo={setInfo}
                    /> */}
                    {/* <CustomSignature
                        update={update1}
                        id={id}
                        info={info}
                        setInfo={setInfo}
                        r={r}
                    /> */}
                    {/* <Payment
                        info={info || {}}
                        setInfo={setInfo}
                        update={update1}
                        id={id}
                        darbuotojas={darbuotojas}
                        r={r}
                    /> */}
                    {
                        info.kategorija ?
                            <ModalTimePickerVidus
                                info={{ attributes: info } || {}}
                                darbuotojas={{}}
                                update={async (id, e) => {
                                    console.log(info)
                                    setInfo({ ...info, data: e.data, Laikas: e.Laikas })
                                }}
                                user={user}
                                seni={info?.seni || {}}
                                close={() => { setTimePicker(false); r() }}
                            /> : ''
                    }
                    <CustomButton
                        className='marginV10'
                        onClick={() => {
                            info.statusas = t("samata")
                            create(info, setInfo)
                        }}
                        text='issaugoti'
                    />
                </div>
            </Modal>
            {
                alert ?

                    <Alert
                        variant="outlined"
                        severity="success"
                    >
                        {t('atnaujinta')}
                    </Alert>
                    : ''
            }

        </div>

    )
}
export default AddArchyve
