import { t } from 'i18next'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import SignaturePad from 'react-signature-canvas'
import '../Components/css/Modal.css';
import CustomButton from '../Components/Mui/CustomButton';

import styles from '../routes/Css/signatureCss.css'

class Signature extends Component {
    state = {
        trimmedDataURL: this.props.parasas.data ? this.props.parasas.data.attributes.url : null,
        r: 0
    }
    sigPad = {}
    clear = () => {
        this.sigPad.clear()
        this.state.trimmedDataURL = null
    }
    trim = () => { this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png') }) }
    save = async () => {
        var canvas = this.sigPad.getCanvas()
        canvas.toBlob(async (blob) => {
            let fd = {
                file: blob,
                name: 'signature.png'
            }
            let req = await this.props.save(fd)
            this.props.r()
            this.trim()
        })
    }
    render() {
        let { trimmedDataURL } = this.state
        return trimmedDataURL != null && trimmedDataURL.length > 0 ?
            <div className="sign1">
                <div className='parasasImg marginV10'>
                    <img className={styles.sigImage}
                        src={trimmedDataURL}
                    />
                </div>
                <CustomButton
                    color='error'
                    onClick={() => {
                        this.props.delete(trimmedDataURL ? this.props.parasas.data.id : null); this.state.trimmedDataURL = {}
                    }}
                    text='istrinti'
                />
            </div>
            :
            <div className={styles.container}>
                <div className='marginV10'>
                    <SignaturePad crossorigin="anonymous" canvasProps={{ className: styles.sigPad }}
                        ref={(ref) => { this.sigPad = ref }}
                    />
                </div>
                <div className='gridColumn marginV10'>
                    <CustomButton
                        color='success'
                        onClick={async () => { await this.save(); }}
                        text='patvirtinti'
                    />
                    <CustomButton
                        color='error'
                        onClick={this.clear}
                        text='isvalyti'
                    />
                </div>
            </div>
    }
}

export default Signature

function urltoFile(base64String, contentType) {
    var filename = 'parasas'
    try {
        var file = new File([base64String], filename, { type: "text/plain" }, { file: base64String });
    } catch (e) {
        // when File constructor is not supported
        file = new Blob([base64String], { type: "text/plain" });
    }
    var url = window.URL.createObjectURL(file);
    return file
};