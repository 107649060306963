import { useEffect, useRef } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import RevoCalendar from 'revo-calendar'
import { TaskContext } from '../AppStates'
import ImportAll from '../functions/importAll'
import ArchiveModal from '../Components/ModalArchive'
import LoadingTimer from '../functions/LoadingOn'
import { useStrapiObjectClass } from '../hooks/useStrapiObject'
import { Loader } from '../Components/Loader'
const Kalendorius = () => {

    const addEvent = () => { }
    const { user } = useContext(TaskContext)
    const kal = useRef(null)
    const [info, setInfo] = useState([])
    const [eventList, setEventList] = useState([])
    const [modal, setModal] = useState(false)
    const [id, setId] = useState(null)
    const [r, setR] = useState(0)
    const { data, getAll } = useStrapiObjectClass({ name: 'darbai', extraFilter: '&filters[archyvuotas][$eq]=false' })
    const deleteEvent = (e) => {
        // console.log(e)
        setId(info[e].id)
        setModal(true)
    }
    // boot up
    useEffect(() => {
        getAll()
    }, [])
    useEffect(() => { setInfo(data) }, [data])
    // useEffect(() => { getAll() }, [r])
    useEffect(() => {
        let arr = []
        if (info.length > 0) {
            console.log(info)
            info.forEach((e, i) => {
                if (!user.is_admin && !user.is_buhalere) {
                    if (e.attributes.darbuotojoId != user.id) {
                        return
                    }
                }
                arr.push({
                    date: e.attributes.data,
                    name: e.attributes.statusas,
                    status: e.attributes.statusas,
                    extra: {
                        text: (
                            <div key={e.id} className="column calendar event alignRight">
                                <div className="kategorija alignRight">{e.attributes.kategorija}</div>
                                <div className="adress alignRight">{e.attributes.adress}</div>
                                <div className="laikas alignRight">{e.attributes.Laikas[0]}</div>
                            </div>
                        ),
                        key: e.id, // Add key prop to the extra object
                    },
                })
            });
            if (eventList.length !== arr.length || JSON.stringify(eventList) !== JSON.stringify(arr)) {
                setEventList(arr);
            }
        }
    }, [info, user, eventList])


    //ikelti i translation lt objektą ir pervadinti kazkaip, galesim kartu
    const translations = {
        lt: {
            days: ["Pirmadienis", "Antradienis", "Trečiadienis", 'Ketvirtadienis', 'Penktadienis', 'Šeštadienis', 'Sekmadienis',],
            daysShort: ["Pir", "Ant", 'Tre', "Ket", "Pen", "Šeš", "Sek",],
            daysMin: ["P", "A", "T", "K", "Pn", "Š", "S",],
            months: ["Sausis",
                "Vasaris",
                "Kovas",
                "Balandis",
                "Gegužė",
                "Birželis",
                "Liepa",
                "Rugpjūtis",
                "Rugsėjis",
                "Spalis",
                "Lapkritis",
                "Gruodis"],
            monthsShort: [
                "Sau",
                "Vas",
                "Kov",
                "Bal",
                "Geg",
                "Bir",
                "Lie",
                "Rgp",
                "Rgs",
                "Spa",
                "Lap",
                "Gru"],
            noEventForThisDay: "Nėra",
            allDay: "Visa diena",
            addEvent: "Pridėti",
            delete: "Daugiau",
            eventTime: "Data",
            previousYear: "Praėje metai",
            nextYear: "Ateinantys metai",
            toggleSidebar: "Meniu",
            toggleDetails: "Meniu2",
        },
    };

    // useEffect(() => {
    //     if (kal.current)
    //         console.log(kal)
    // }, [kal])
    if (eventList.length == 0) {
        return <Loader />
    }
    const kalendorius = (<RevoCalendar
        events={eventList}
        languages={translations}
        style={{
            borderRadius: "5px",
            border: "5px solid #f0eee7"
        }}
        highlightToday={true}
        lang="lt"
        primaryColor="#f0eee7"
        secondaryColor="#db643e"
        todayColor="#db643e"
        textColor="#f0eee7"
        indicatorColor="#f0eee7"
        animationSpeed={300}
        sidebarWidth={180}
        detailWidth={280}
        showDetailToggler={true}
        showSidebarToggler={true}
        onePanelAtATime={false}
        allowDeleteEvent={true}
        allowAddEvent={true}
        openDetailsOnDateSelection={true}
        timeFormat24={true}
        showAllDayLabel={false}
        detailDateFormat="YYYY/MM/DD"
        deleteEvent={deleteEvent}
        addEvent={addEvent}
        extraEventProps={{ key: (event) => event.extra.key }}

    />)
    return (
        <div key={'ukadsf'} className='kalendorius lentele' >
            <LoadingTimer key={'asdsds'} />
            <div key={'asdsaaaaadasdsds'} ref={kal}>

                {kalendorius}
            </div>
            {modal ? <ArchiveModal id={id} setIsOpen={setModal} modalIsOpen={modal} r={r} setR={() => setR(r + 1)} /> : ''}


        </div>
    )
}
export default Kalendorius