import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import EmailBody from "./EmailBody"
import CustomSelect from "./Mui/CustomSelect"
import PdfBuilder from './PDF/PVMSaskaitaFatktura';
import IsankstineBuilder from './PDF/isankstineSaskaita';
import { useUser } from "../hooks/useUser";
import CustomButton from "./Mui/CustomButton";
import { TikLaikas, timeLapse } from "./MainModal/Price";

const EmailuSiuntimas = ({ info, id, disabled }) => {
    const { t } = useTranslation()
    const choises = [t('open'), t('issaugoti'), t('siusti'), t('siusti_redaguoti')]
    const [tipasPdf, setTipasPdf] = useState(t('open'))
    const { getUser } = useUser()
    const [darbuotojas, setDarbuotjas] = useState({})
    //
    const [emailSend, setEmailSend] = useState(false)
    const [pdfRender, setPdfRender] = useState(null)
    const [emailText, setEmailText] = useState('')
    const [subject, setSubject] = useState('')
    const [emailEdit, setEmailEdit] = useState(false)
    const [send, setSend] = useState(false)
    //
    useEffect(() => {
        getUser(info?.darbuotojoId, setDarbuotjas)

    }, [getUser, id])

    return (
        <div className='sfIssaug'>
            {/* <CustomSelect
                options={choises}
                optionsText={choises}
                current={tipasPdf}
                set={(e) => {
                    console.log(e)
                    setTipasPdf(e)
                }}
            /> */}
            <CustomButton
                disabled={disabled}
                className='width100'
                onClick={async () => {
                    var arr = info.pradirbtasLaikas != null && info.pradirbtasLaikas?.pradėta && info.pradirbtasLaikas?.pabaigta ? [info.pradirbtasLaikas.pradėta, info.pradirbtasLaikas.pabaigta] : [new Date(), new Date()]
                    var x = await PdfBuilder
                        (
                            info, id, t, darbuotojas,
                            TikLaikas(new Date(info.pradirbtasLaikas?.pradėta), new Date(info.pradirbtasLaikas?.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis')),
                            arr,
                            tipasPdf == t('open') ? 'open' : tipasPdf == t('issaugoti') ? 'save' : tipasPdf
                        )
                    if (tipasPdf == t('siusti')) {
                        setPdfRender(x)
                        setEmailSend(true)
                        setEmailText('Dėkojame, siunčiame SF.')
                        setSubject('Ukvedziai SF')
                        setSend(true)
                    }
                    else if (tipasPdf == t('issaugoti')) {
                        setPdfRender(x)
                        setEmailSend(false)
                        setEmailText('Dėkojame, siunčiame SF.')
                        setSubject('Ukvedziai SF')
                        setEmailEdit(false)
                    }
                    else if (tipasPdf == t('siusti_redaguoti')) {
                        setPdfRender(x)
                        setEmailSend(true)
                        setEmailText('Dėkojame, siunčiame SF.')
                        setSubject('Ukvedziai SF')
                        setEmailEdit(true)
                    }
                }}
                text='atidaryti'
            />
            {/* <div onClick={async () => {
                    var arr = info.pradirbtasLaikas != null && info.pradirbtasLaikas?.pradėta && info.pradirbtasLaikas?.pabaigta ? [info.pradirbtasLaikas.pradėta, info.pradirbtasLaikas.pabaigta] : [new Date(), new Date()]
                    var x = await IsankstineBuilder(
                        info, id, t, darbuotojas,
                        TikLaikas(new Date(info.pradirbtasLaikas?.pradėta), new Date(info.pradirbtasLaikas?.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis')),
                        arr,
                        tipasPdf == t('open') ? 'open' : tipasPdf == t('issaugoti') ? 'save' : tipasPdf
                    )
                    if (tipasPdf == t('siusti')) {
                        setPdfRender(x)
                        setEmailSend(true)
                        setEmailText('Dėkojame, siunčiame SF.')
                        setSubject('Ukvedziai SF')
                        setSend(true)
                    }
                    else if (tipasPdf == t('issaugoti')) {
                        setPdfRender(x)
                        setEmailSend(false)
                        setEmailText('Dėkojame, siunčiame SF.')
                        setSubject('Ukvedziai SF')
                        setEmailEdit(false)
                    }
                    else if (tipasPdf == t('siusti_redaguoti')) {
                        setPdfRender(x)
                        setEmailSend(true)
                        setEmailText('Dėkojame, siunčiame SF.')
                        setSubject('Ukvedziai SF')
                        setEmailEdit(true)
                    }
                }}
                    className='sf'
                >{t('Isankstine_sf')}</div>
                {info.statusas == t('pabaigta') ?
                    <div
                        className='sf'
                    >{t('garantinis')}</div>

                    : ''} */}


            {emailSend ?
                <EmailBody
                    close={() => { setEmailSend(false) }}
                    info={info}
                    files={[pdfRender]}
                    subject={subject}
                    text={emailText}
                    editible={emailEdit}
                    send={send}
                    wasSent={() => { setSend(false); setEmailSend(false) }}
                    setAlert={() => { }}
                    id={id}
                />
                : ''}
        </div>
    )
}
export default EmailuSiuntimas


