import React, { useEffect } from 'react'
import CustomSignature from './MainModal/CustomSignature'
import { useContext, useState } from 'react';
import Info from './MainModal/Info'
import SfInfo from './MainModal/SfInfo'
import Modal from 'react-modal';
import { Box } from '@mui/material';
import Photos from './MainModal/Photos';
import Comments from './MainModal/Comments';
import Materials from './MainModal/Materials';
import Price from './MainModal/Price';
import Discount from './MainModal/Discount';
import Payment from './MainModal/Payment';
import Time from './MainModal/Time';
import { useStrapiObjectClass } from '../hooks/useStrapiObject';
import CustomButton from './Mui/CustomButton';
import { useTranslation } from 'react-i18next';
import { NoFlash } from '@mui/icons-material';
import { array } from 'prop-types';
import Pratesimas from '../functions/pratesimas';
import { TaskContext } from '../AppStates';



const Meistras = ({ deploy, id, r, laikaKeitis, medziagos, darbuotojas }) => {
    const { user, path, token } = useContext(TaskContext)
    const { t } = useTranslation()
    const [open, setOpen] = useState(true)
    const [info, setInfo] = useState(deploy)
    const [modalIsOpen2, setModalIsOpen2] = useState(false)
    const [modalIsOpen3, setModalIsOpen3] = useState(false)

    useEffect(() => { setInfo(deploy) }, [deploy])

    const { getSingle, update: update } = useStrapiObjectClass({ name: 'darbai', extraFilter: 'populate=*' })
    const update1 = async (id = id, data = info) => { await update(id, data); r() }
    const pratesti = async (id, statusas) => { await Pratesimas(path, token, 'seni-d/', 'darbai/' + id, setInfo, info, user, statusas); getSingle(id, setInfo) }

    const ForMaterial = [
        t('planuojama'),
        t('priskirtas'),
        t('vykdoma'),
        t('neapmoketa'),
        t('apmoketa'),
        t('priskirtas_tesiamas'),
        t('pavedimu'),
        t('pavedimu_pratestas'),
        t('nepriskirtas_pratestas'),
        t('samata')

    ]
    const ForDiscountPriceSignaturePayment = [
        t('neapmoketa'),
        t('apmoketa'),
        t('pavedimu'),
        t('pavedimu_pratestas'),
        t('samata'),
    ]
    const ForStartJob = [
        t('priskirtas'),
        t('priskirtas_tesiamas')
    ]

    return (

        <div className='testModal column'>
            <Info
                info={info}
                setInfo={setInfo}
                laikaKeitis={laikaKeitis}
                id={id}
                r={r}
                update={update1}
            />
            {info.statusas == t('nepriskirtas') || info.statusas == t('priskirtas') || info.statusas == t('priskirtas_nepatvirtintas') || info.statusas == t('priskirtas_patvirtintas') ?
                '' :
                <SfInfo
                    info={info}
                    setInfo={setInfo}
                    id={id}
                    r={r}
                    update={update1}
                />
            }
            <Photos
                info={info}
                id={id}
                r={r}
            />
            <Comments
                info={info}
                setInfo={setInfo}
                id={id}
                r={r}
                update={update1}
            />
            {CheckStatus(info.statusas, [t('samata'), t('samata_baigta')]) ?
                '' :
                <Time
                    info={info}
                    setInfo={setInfo}
                    update={update1}
                    id={id}
                    r={r}
                />
            }
            {CheckStatus(info.statusas, [t('nepriskirtas')]) ?
                '' :
                <>
                    {CheckStatus(info.statusas, ForMaterial) ?
                        <Materials
                            medziagos={medziagos}
                            info={info}
                            setInfo={setInfo}
                            update={update1}
                            id={id}
                        />
                        : ''}
                    {CheckStatus(info.statusas, ForDiscountPriceSignaturePayment) ?
                        <>
                            <Price
                                info={info}
                                setInfo={setInfo}
                            />
                            <CustomSignature
                                update={update1}
                                id={id}
                                info={info}
                                setInfo={setInfo}
                                r={r}
                            />
                           {CheckStatus(info.statusas, [t('samata'), t('samata_baigta')]) ?
                                '' :
                                <Payment
                                    info={info}x
                                    setInfo={setInfo}
                                    update={update1}
                                    id={id}
                                    darbuotojas={darbuotojas}
                                    r={r}

                                />
                            }
                        </>
                        : ''
                    }
                </>
            }
            {CheckStatus(info.statusas, [t('planuojama')]) ?
                <CustomButton
                    className='marginV10'
                    onClick={() => { laikaKeitis(id, info) }}
                    text={'planuotiLaika2'}
                />
                : ''
            }
            <div className='modalContVidus'>
                {CheckStatus(info.statusas, ForStartJob) ?
                    <CustomButton
                        color='success'
                        onClick={() => {
                            // info.pradirbtasLaikas = { pradėta: new Date() }
                            // info.statusas = t("vykdoma")
                            // update1(id, info)
                            // r()
                            setModalIsOpen2(true)
                        }}
                        text='pradetiDarba'
                    />
                    : ''
                }
                {CheckStatus(info.statusas, [t('vykdoma'), t('samata')]) ?
                    <CustomButton
                        color='error'
                        onClick={() => {
                            // info.pradirbtasLaikas = {
                            //     pradėta: info.pradirbtasLaikas.pradėta,
                            //     pabaigta: new Date()
                            // }
                            // info.statusas = t('neapmoketa')
                            // update1(id, info)
                            // r()
                            setModalIsOpen3(true)
                        }}
                        text='pabaigtiDarba'
                    />
                    : ''
                }
            </div>
            <Modal
                isOpen={modalIsOpen2}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen2(false) }}
            >
                <div
                    className="centerContainer1 column">
                    <button className="closeBtnMod" onClick={() => {
                        setModalIsOpen2(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_pradejote_darba')}?</h4>
                    </div>
                    <div  >

                        <CustomButton
                            onClick={() => {
                                info.pradirbtasLaikas = { pradėta: new Date() }
                                info.statusas = t("vykdoma")
                                update1(id, info)
                                setModalIsOpen2(false)
                                r()
                            }}
                            text='taip'
                            className='margin5'
                        />

                        <CustomButton
                            onClick={() => {

                                setModalIsOpen2(false); r()
                            }}
                            text='ne'
                            className='margin5'
                        />
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpen3}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen3(false) }}
            >
                <div
                    className="centerContainer1 column">
                    <button className="closeBtnMod" onClick={() => {
                        setModalIsOpen3(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_pabaigete_darba')}?</h4>
                    </div>
                    <div  >

                        <CustomButton
                            onClick={() => {
                                if (info.statusas != t('samata')) {
                                info.pradirbtasLaikas = {
                                    pradėta: info.pradirbtasLaikas.pradėta,
                                    pabaigta: new Date()
                                }
                                info.statusas = t('neapmoketa')}
                                else   info.statusas = t('samata_baigta')

                                update1(id, info)
                                setModalIsOpen3(false)
                                r()
                            }}
                            className='margin5'
                            text='taip' />

                        <CustomButton
                            onClick={() => {
                                setModalIsOpen3(false); r()
                            }}
                            text='ne'
                            className='margin5'
                        />
                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default Meistras

export const CheckStatus = (statusas, array) => {
    if (array.includes(statusas))
        return true
    else
        return false
}