import { Tooltip } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import KategorijosNustatymas from "../functions/kategorijosNustatymas"
import Edit from "../assets/edit.png";
import DeletePng from "../assets/delete.png";
import { t } from "i18next";


const MobileGridUser = (props) => {
    var renderData = []

    const [info, setInfo] = useState(props.rows ? props.rows : [])
    useEffect(() => { setInfo(props.rows) }, [props])
    return (
        Array.isArray(info) ? (
            <div>
                {info.map((e, i) =>
                (<div key={'darbas' + e.id + 'mobile-grid' + i} className="mobile grid row">
                    <div> <div>{t('id')} </div><div>{e.id}</div></div>
                    <div><div>{t('name')}</div> <div>{e.name}</div></div>
                    <div><div>{t('surname')}</div> <div>{e.kategorija}</div></div>
                    <div><div>{t('tel')}</div> <div>{e.telNr}</div></div>
                    <div><div>{t('email')}</div><div>{e.elPastas}</div></div>

                    <div>
                        <div>{t('redaguoti')}</div>
                        <div>     <Redaguoti e={e} /></div>

                    </div>
                    <div>
                        <div>{t('trinti')}</div>
                        <div>     <Delete e={e} /></div>

                    </div>
                </div>)
                )}
            </div>

        ) : (
            'No data'
        )
    )
}
export default MobileGridUser

const Kategorija = (props) => {
    var e = props.e
    return (<div>
        <Tooltip title={e.kategorija}>
            <img
                className={e.kategorija + ' kategorijos'}
                src={KategorijosNustatymas(e.kategorija)}
                onClick={(event) => {
                    e.modal(e.id)
                }}
            /></Tooltip>
    </div>)
}
const Redaguoti = (props) => {
    var e = props.e
    return (
        <div
            className="deleteImg"
            onClick={(event) => {
                e.modal(e.id)
            }}
        >
            <img src={Edit} />
        </div>
    );
}
const Delete = (props) => {
    var e = props.e
    return (
        <div
            className="deleteImg"
            onClick={(event) => {
                e.delete(e.id)
            }}
        >
            <img src={DeletePng} />
        </div>
    );
}