import './css/Modal.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import React, { useContext, useEffect, useState, } from 'react';
import { TaskContext } from '../AppStates';
import './css/calendar.css'
import { loadCldr } from '@syncfusion/ej2-base';
import { Calendar } from 'react-multi-date-picker';
import * as lt from 'cldr-data/main/lt/ca-buddhist.json';
import * as numbers from 'cldr-data/main/lt/numbers.json';
import * as timeZoneNames from 'cldr-data/main/lt/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';
import { L10n } from "@syncfusion/ej2-base";
import { PersonPinSharp } from '@mui/icons-material';
import ImportDates from '../functions/importDatesCalendar';
import ImportAll from '../functions/importAll';
import Update from '../functions/update';
import Create from '../functions/create';
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { color } from '@mui/system';
import { Checkbox } from '@mui/material';
import CustomButton from './Mui/CustomButton';

const ModalTimePickerMultiVidus = (props) => {

    const [datos, setDatos] = useState([])
    const [naujosDatos, setNaujosDatos] = useState([])
    const [senosDatos, setSenosDatos] = useState([])
    const [laikas, setLaikas] = useState([])
    const [info, setInfo] = useState({})
    const [multiple, setMultiple] = useState(false)
    const [current, setCurrent] = useState([])
    const [disabledSave, setDisabledSave] = useState(true)
    const [dates, setDates] = useState({})
    const [esami, setEsami] = useState([])
    const [buve, setButve] = useState([])
    const [id, setId] = useState(-1)
    const [darbuotojas, setDarbuotojas] = useState({})
    const { path, token, user, type } = useContext(TaskContext)
    const [r, setR] = useState(0)
    const { t } = useTranslation()
    const [doNotReset, setDoNotResset] = useState(false)
    loadCldr(numberingSystems, lt, numbers, timeZoneNames);
    L10n.load({
        'lt': {
            'calendar': { today: '' }
        }
    });
    const update = async (data) => { var item = await Update(path, token, 'darbo-dienos/', dates.data[0].id, data, setDates, setDates); get() }
    const create = async (data) => { await Create(path, token, 'darbo-dienos', data, setDates); console.log(data); get() }
    const get = async () => { await ImportAll(path, token, 'darbo-dienos/?filters[userId][$eq]=' + props.darbuotojoId, setDates, 'users'); }

    useEffect(() => {
        setLaikas(t('laikas').map((e) => { return e }))
        get()
    }, [])
    useEffect(() => {
        if (dates.data && dates.data[0]) {
            setId(dates.data[0].id)
            setDatos(Object.keys(dates.data[0].attributes.datos).map((e) => { return dates.data[0].attributes.datos[e].data }))
            setSenosDatos(Object.keys(dates.data[0].attributes.datos).map((e) => { return dates.data[0].attributes.datos[e].data }))
            setInfo(dates.data[0].attributes.datos)
        }
    }, [dates])
    const sudetiLaikus = (data) => {
        var obj = {}
        if (datos.length > 1)
            datos.map((e) => {
                e = new Date(e).toISOString().split('T')[0]
                obj[e] = {
                    datos: e,
                    laikas: laikas
                }
            })
        return obj
    }
    const makeDate = (e) => {
        if (e.month)
            return new Date(e.year, e.month.number - 1, e.day + 1).toISOString().split('T')[0]
        else return console.log(e)
    }
    const save = () => {
        var d = makeDate(current)
        if (laikas.length == 0) {
            return
        }
        if (d)
            if (!info[d]) {
                info[d] = {
                    data: d,
                    laikas: laikas
                }
                setSenosDatos([...senosDatos, d])
            }
        var send = {
            userId: props.darbuotojoId + '',
            Kategorija: props.info.Kategorija,
            datos: info
        }
        if (dates.data.length > 0) { update(send) } else { create(send) }
    }

    const pakeisti = () => {
        var d = makeDate(current)
        if (d) {
            info[d] = {
                data: d,
                laikas: laikas
            }
        }
        if (laikas.length == 0) {
            istrinti()
        }
        save()
    }
    const istrinti = () => {
        var d = makeDate(current)
        if (d && info[d]) {
            senosDatos.splice(senosDatos.indexOf(d), 1)
            delete info[d]
            var send = {
                userId: props.darbuotojoId + '',
                Kategorija: props.info.Kategorija,
                datos: info
            }
            if (dates.data.length > 0) { update(send) } else { create(send) }
        }
    }
    useEffect(() => {
        var d = makeDate(current)
        if (info[d] && info[d].laikas)
            setLaikas(info[d].laikas)
        if (!doNotReset) {
            if (!info[d])
                setLaikas([])
        }
        save()
    }, [current])
    useEffect(() => {
        pakeisti()
    }, [laikas])


    const weekDays = ["S", "P", "A", "T", "K", "Pn", "Š"]
    const months = ["Sausis", "Vasaris", "Kovas", "Balandis", "Gegužė", "Birželis", "Liepa", "Rugpjūtis", "Rugsėjis", "Spalis", "Lapkritis", "Gruodis"]


    return (
        <div className='TimePickerContainer'>
            <h3>{t('darbuotojo_kalendorius')}</h3>
            <div className='column'>
                <div className="calendarContainer">

                    <Calendar
                        calendar={'gregorian'}
                        onChange={setDatos}
                        minDate={new Date()}
                        value={multiple ? datos : ''}
                        mapDays={({ date }) => {
                            if (senosDatos)
                                if (senosDatos.indexOf(makeDate(date)) > -1) return {
                                    // disabled: true,
                                    className: 'worked',
                                    style: { color: "white" },
                                }
                        }}
                        onFocusedDateChange={setCurrent}
                        locale='gregorian_lt'
                        weekStartDayIndex={1}
                        weekDays={weekDays}
                        months={months}
                    />
                </div>
                <div className="iskvietimasLaikas">
                    {/* <div className='rp'></div> */}
                    {type == 0 ?

                        <div className='row remember'>
                            <Checkbox
                                key={'neperkrautiLaiku'}
                                label={t('neperkrautiLaiku')}
                                // multiline
                                value={doNotReset}
                                onChange={() => { setDoNotResset(!doNotReset) }}
                            />
                            <label className='neperkrauti'>{t('neperkrautiLaiku')}</label>
                        </div>


                        : ''}
                    {t('laikas').map(e => (
                        <CustomButton
                            onClick={(k) => {
                                if (type == 0) {
                                    if (laikas.indexOf(e) == -1)
                                        setLaikas([...laikas, e]);
                                    else {
                                        setLaikas(laikas.filter((element) => {
                                            return element !== e;
                                        }))
                                    }
                                }
                            }}
                            className='margin5'
                            text={e}
                            variant={laikas.indexOf(e) >= 0 ? 'contained' : 'outlined'}
                            key={e}
                        //  className={laikas.indexOf(e) >= 0 ? 'active laikasBtn' : 'laikasBtn'}
                        />)
                    )}
                    {info[makeDate(current)] && type == 0 ? 
                        <CustomButton onClick={() => { istrinti() }} 
                        text='istrinti'
                        className='margin5'
                        />
                    : ''
                    }
                </div>
            {type != 0 ?
                '' :
                <CustomButton
                    onClick={() => {
                        // var send = {
                        //     userId: props.darbuotojoId + '',
                        //     Kategorija: [props.info.Kategorija],
                        //     datos: info
                        // }
                        // if (dates.data.length > 0) { update(send) } else { create(send) }
                        props.close()
                    }}
                    className='margin5'
                    text='issaugoti'
                />
            }
            </div>
        </div>
    )
}
export default ModalTimePickerMultiVidus