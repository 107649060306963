import React, { useEffect, useState, useReducer, useContext } from 'react';
import Modal from 'react-modal';
import ImportAll from '../functions/importAll';
import Pratesimas from '../functions/pratesimas.js';
import Update from '../functions/update.js';
import { Input, TextField } from '@mui/material';
import './css/Modal.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import PasirinktiDarbuotoja from './PasirinktiDarbuotoja';
import StartWork from '../functions/StartWork';
import DELETE from '../functions/delete';
import ModalVidus from './ModalVidusDarbuotojasDarbai'
import ModalTimePickerVidus from './ModalTImePickerVidus';
import ModalVidusDarbuotojas from './ModalVidusDarbuotojas';
import Create from '../functions/create';
import ModalTimePickerMultiVidus from './ModalTimePickerMultiVidus';
import { TaskContext } from '../AppStates';
import { useUser } from '../hooks/useUser';
const DarbuotojaiModal = ({ id, setIsOpen, modalIsOpen, r }) => {
    const { t } = useTranslation()
    const [info, setInfo] = useState({})
    const [deploy, setDeploy] = useState({})
    const [timePicker, setTimePicker] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const { getUser, updateUser, create: CREATE } = useUser()
    const get = async () => { getUser(id, setInfo) }

    const update = async (id, data) => { await updateUser(id, data, setInfo); r() }
    const create = async (id, data) => { await CREATE(data); r() }
    const laikaKeitis = async (id, data) => { setTimePicker(true) }
    const change = (what, value) => { setDeploy(...deploy, { attributes: { [what]: value } }) }
    useEffect(() => { if (id != null) { get() } }, [getUser, id])
    useEffect(() => {
        setDeploy(info);
        console.log(info, 'info')
    }, [info])
    useEffect(() => {
        // setDeploy(info);
        console.log(deploy, 'deploy')
    }, [deploy])
    return (
        info && Object.keys(info) != 0 ?
            <Modal
                className={timePicker ? 'modal timePicker' : 'modal'}
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onRequestClose={() => {
                    timePicker ? setTimePicker(false) : setIsOpen(false);
                }}
                contentLabel="Example Modal"
            >
                {!timePicker ?
                    <div>
                        <ModalVidusDarbuotojas
                            key={'modal'}
                            selectedIndex={selectedIndex}
                            r={r}
                            id={id}
                            deploy={info}
                            laikaKeitis={(id, info) => { laikaKeitis(id, info) }}
                            set={(what, value) => { change(what, value) }}
                            update={(value, data) => { update(value, data) }}
                        />
                        <button className='closeBtn' onClick={() => { setIsOpen(false) }}>✕</button>
                    </div>
                    :
                    < ModalTimePickerMultiVidus
                        darbuotojoId={id}
                        id={info.grafikoId}
                        info={info}
                        update={(id, info) => { update(id, info); setTimePicker(false) }}
                        close={() => { setTimePicker(false) }}
                        flag={'darbuotojo'}
                    />
                }
            </Modal>
            :
            <Modal
                className={timePicker ? 'modal timePicker' : 'modal'}
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onRequestClose={() => { timePicker ? setTimePicker(false) : setIsOpen(false); }}
                contentLabel="Example Modal"
            >
                {!timePicker ?
                    <div>
                        <ModalVidusDarbuotojas
                            key={'modal'}
                            selectedIndex={selectedIndex}
                            setIsOpen={(value) => { setIsOpen(value) }}
                            r={r}
                            info={info}
                            laikaKeitis={(id, info) => { laikaKeitis(id, info) }}
                            set={(what, value) => { change(what, value) }}
                            update={(value, data) => {
                                if (id == null) {
                                    create(value, data)
                                } else {
                                    update(value, data)
                                }
                            }}
                        />
                        <button className='closeBtn' onClick={() => { setIsOpen(false) }}>⨉</button>
                    </div>
                    : ''
                }
            </Modal>
    )
}
export default DarbuotojaiModal
