import React, { useEffect, useState, useContext } from 'react'
import { TaskContext } from '../../AppStates';
import Images from '../ImagesAddComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import { useTransition } from 'react';
import { CheckStatus } from '../Meistras';
import { useTranslation } from 'react-i18next';

const Photos = ({ info, id, r }) => {
    const { t } = useTranslation()
    const { type } = useContext(TaskContext)
    const [expand, setExpand] = useState(true)
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (type == 2) {
            if (CheckStatus(info?.statusas, [t('priskirtas'), t('nepriskirtas'), t('planuojama'), t('priskirtas_tesiamas'), t('nepriskirtas_pratestas')])) setExpand(true)
            else setExpand(false)
        }
    }, [info?.statusas])

    return (

        <div className="modalCont modalContVidus">
            <div className="sectionCont">
                <div className="section">
                    {expand ? <ExpandLessIcon
                        sx={{ cursor: 'pointer' }}
                        color='primary'
                        onClick={() => {
                            expand ? setExpand(false) : setExpand(true)
                        }} /> :
                        <ExpandMoreIcon
                            sx={{ cursor: 'pointer' }}
                            color='primary'
                            onClick={() => {
                                expand ? setExpand(false) : setExpand(true)
                            }} />}
                    <h4 className="sectionName">Nuotraukos</h4>
                </div>
            </div>
            <div className={expand ? 'modalContVidus photos row alignEnd' : 'modalContVidus hidden'}>
                {info?.vartotojoNuotraukos.data ?
                    <Images
                        id={id}
                        api={"darbas"}
                        info={info?.vartotojoNuotraukos.data}
                        type={'vartotojoNuotraukos'}
                        r={() => { r() }}
                        disabled={true}
                    />
                    : ''}
                <Images
                    id={id}
                    api={"darbas"}
                    info={info?.darbuotojoNuotraukos.data}
                    type={'darbuotojoNuotraukos'}
                    r={() => { r() }}
                    disabled={type != 1 ? false : true}
                />
            </div>
        </div>
    )
}

export default Photos