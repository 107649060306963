import { Button } from '@mui/material'
import { t } from 'i18next';
import React from 'react'

const CustomButton = ({
  type: disabled, href, color, startIcon, endIcon, text, target, variant, className, ...rest
}) => {
  return (
      <Button
        variant={variant? variant:'contained'}//text, contained, outlined
        disabled={disabled} //bool
        href={href}
        color={color}
        size='small' //small, medium, large
        startIcon={startIcon}
        endIcon={endIcon}
        target={target}
        className={className}
        {...rest}
      >{t(text)}</Button>
  )
}

export default CustomButton



//       {/* <Button
//         variant={variant} //text, contained, outlined
//         disabled={disabled} //bool
//         onClick={() => { onClick }}
//         href={href}
//         color={color}
//         size={size} //small, medium, large
//         startIcon={startIcon}
//         endIcon={endIcon}
//         sx={{ margin: 1 }}
//         {...rest}
//       >
//         {t(text)}
//       </Button> */}
