

import { useEffect } from "react";
import { useContext, useState } from "react";
import TaskContextProvider, { TaskContext } from "../AppStates";
import DataGridAdmin from "../Components/DataGridAdmin";
import DataGridDarbuotojai from "../Components/DataGridDarbuotojai";
import DarbuotojaiModal from "../Components/ModalDarbuotojai";
import { useTranslation } from "react-i18next";
import plus from '../assets/plus.png'
import Modal from 'react-modal';
import LoadingTimer from "../functions/LoadingOn";
import { useUser } from "../hooks/useUser";
import { useStrapiObjectClass } from "../hooks/useStrapiObject";
import CustomButton from "../Components/Mui/CustomButton";
import AddIcon from '@mui/icons-material/Add';


const Darbuotojai = () => {
    const { t } = useTranslation()
    const { token, user, path, type } = useContext(TaskContext)
    // const [info, setInfo] = useState([])
    const [loading, setLoading] = useState(true)
    const [sortBy, setSortBy] = useState(0)
    const [tvarkyta, setTvarkya] = useState([])
    const [r, setR] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [id, setId] = useState('')

    const { users, getAllUsers, DELETE } = useUser()
    const { data: grafikas, getAll: getGrafikas, update: updateGrafikas, DELETE: deleteGrafikas } = useStrapiObjectClass({ name: 'darbo-dienos', extraFilter: '&filters[userId][$eq]=' + id })

    useEffect(() => { getAllUsers() }, [getAllUsers])
    useEffect(() => {
        redoData(users, setTvarkya)
    }, [users])
    useEffect(() => { getGrafikas(); console.log(id, users); }, [getGrafikas, id])

    const redoData = async (info, setter) => {//data for dataGrid
        var arr = []

        info.forEach(element => {
            if (!user.is_admin && !user.is_buhaltere) {
                if (element.id != user.id) {
                    return
                }
            }
            // console.log(element.Kategorija.split(',').map((Kategorija) => { return t('kategorija')[Kategorija] }))
            arr.push(
                {
                    id: element.id,
                    name: element.username,
                    telNr: element.tel,
                    elPastas: element.email,
                    kategorija: element.type,
                    subkategorija: element.Kategorija ?
                        element.Kategorija.split(',').map((Kategorija) => { return t('kategorija')[Kategorija] })
                        : []
                    ,
                    // IDNr: element.attributes.IDNr,
                    modal: (id) => { setModal(true); setId(id) },
                    delete: (id) => { setDeleteModal(true); setId(id) }
                }
            )
        });
        setter(arr)
        return arr
    }
    useEffect(() => { getAllUsers() }, [r])
    const istrintiGrafika = async () => {
        console.log(id)
        getGrafikas().then(() => {
            if (grafikas.length > 0)
                deleteGrafikas(grafikas[0].id)
        })

        console.log(id, grafikas)

    }
    return (
        <div className='container'>
            {users.length == 0 ? (


                <div className='newsBtnCont'>
                    {
                        type == 1 || type == 2 ?
                            '' :
                            <CustomButton
                                className='reverseColorBtn'
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setModal(true)
                                    setId(null)
                                }}
                                text='prideti_darbuotoja'
                            />

                    }</div>
            ) : (
                <div className="darbuotojaiPos">

                    <div className='newsBtnCont'>
                        {
                            type == 1 || type == 2 ?
                                '' :
                                <CustomButton
                                    className='reverseColorBtn'
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        setModal(true)
                                        setId(null)
                                    }}
                                    text='prideti_darbuotoja'
                                />
                        }</div>
                    <div className='darbuotojai'>
                        <DataGridDarbuotojai data={tvarkyta} />
                    </div>
                </div>
            )}
            {modal ?
                <DarbuotojaiModal id={id} setIsOpen={setModal} modalIsOpen={modal} r={() => setR(r + 1)} />
                :
                ''
            }

            <Modal
                isOpen={deleteModal}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setDeleteModal(false) }}
            >
                <div
                    className="centerContainer1 column">


                    <button className="closeBtnMod" onClick={() => {
                        setDeleteModal(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_tikrai_istrinti')}</h4>
                    </div>
                    <div  >

                        <CustomButton
                            onClick={() => {
                                console.log(id)
                                istrintiGrafika()
                                DELETE(id).then(() => { getAllUsers() });
                                setDeleteModal(false)

                            }}
                            className=" btn primary btnWide"
                            text='taip'
                        />
                    </div>
                </div>
            </Modal>

            <LoadingTimer />
        </div>
    )
}
export default Darbuotojai


const sorting = (item, i) => {
    let start = item.slice(0)
    var arr
    if (i == 0) {
        arr = item.sort((a, b) => parseInt(a.id) > parseInt(b.id) ? 1 : -1)
        return arr
    }
    else if (item != start) {
        console.log('!=', item, start)

        arr = item.sort((a, b) => a.attributes[[weNeed.tags[i]]] > b.attributes[[weNeed.tags[i]]] ? 1 : -1)
        return arr
    }
    else {
        console.log('else', item, start)
        arr = item.sort((a, b) => a.attributes[[weNeed.tags[i]]] < b.attributes[[weNeed.tags[i]]] ? 1 : -1)
        return arr
    }
}

const weNeed = {
    names: ['ID', 'Data', 'Laikas', 'Kategorija', 'Statusas', 'Veiksmas'],
    tags: ['id', 'data', 'Laikas', 'kategorija', 'statusas', 'IDNr']
}