import './css/Modal.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Checkbox, TextField } from '@mui/material';
import React, { useEffect, useState, } from 'react';
import { useRef } from 'react';
import { createTheme, makeStyles, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CustomButton from './Mui/CustomButton';



const ModalVidus = (props) => {
    const { t } = useTranslation()
    const focusThis = useRef(null)
    const [info, setInfo] = useState(props.info.attributes)
    const [medziaga, setMedziaga] = useState({})
    const [removed, setRemoved] = useState({})
    const [deleteOn, setDeleteOn] = useState(false)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        setInfo(props.info.attributes)
    }, [props.info])
    useEffect(() => { }, [info])
    const add = (info) => {
        var arr = []
        try {
            if (info.medziagos) { arr = info.medziagos.concat(medziaga) }
            else { arr = [medziaga] }
            // setInfo({ ...info, medziagos: arr, })
            info.medziagos = arr
        }
        finally { props.update(props.info.id, info); setMedziaga({}) }
    }
    const change = (name, value, index, e) => {
        var arr = info.medziagos
        arr[index][name] = value
        setInfo({ ...info, medziagos: arr, })
        focusThis.current = e.target
    }
    const remove = (data, index) => {
        data.medziagos.splice(index, 1)
        setInfo(data)
        props.update(props.info.id, info, index)
        props.remove(index)
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: '#b8b7b2',
            }
        }

    })


    const theme2 = createTheme({
        palette: {
            primary: {
                main: '#db643e',
            }
        }
    })

    const theme3 = createTheme({
        components: {
            MuiAlert: {
                styleOverrides: {
                    standardSuccess: {
                        backgroundColor: '#f0eee7',
                        color: '#db643e'
                    },
                }
            },

        },
    })
    // useEffect(() => {
    //     info.medziagos.map((e, i) => {
    //         info.medziagos[i].kaina = info.medziagos[i].kaina.replace(',', '.')
    //     })
    // }, [info])

    console.log(info)
    return (
        Object.keys(props.info) != 0 ?
            <div>
                <ThemeProvider theme={theme2}>
                    <div className='row '>
                        <Checkbox
                            key={'deleteOn'}
                            label={t('deleteOn')}
                            // multiline
                            value={deleteOn}
                            onClick={() => { setDeleteOn(!deleteOn) }}
                        />
                        <label><a className='politika'>     {t('DeleteOn')}</a></label>
                    </div>
                </ThemeProvider>
                <ThemeProvider theme={theme}>

                    <div className='kablukasMasterTexts'>
                        {
                            info.medziagos ? info.medziagos.map((e, i) => (
                                <div className='row' key={i}>
                                    <TextField
                                        key={i + '-pavadinimas'}
                                        label={t('pavadinimas')}
                                        multiline
                                        maxRows={4}
                                        value={e.pavadinimas ? e.pavadinimas : ''}
                                        onChange={value => { change('pavadinimas', value.target.value, i, value) }}
                                        className='margin5 widthProc2'
                                        size='small'
                                    />
                                    <TextField
                                        key={i + '-mato_vienetas'}
                                        label={t('mato_vienetas')}
                                        // multiline
                                        maxRows={1}
                                        value={e.mato_vienetas ? e.mato_vienetas : ''}
                                        onChange={value => { change('mato_vienetas', value.target.value, i, value) }}
                                        className='margin5'
                                        size='small'
                                    />
                                    <TextField
                                        key={i + '-Kiekis'}
                                        label={t('Kiekis')}
                                        // multiline
                                        maxRows={1}
                                        value={e.kiekis ? e.kiekis : ''}
                                        onChange={value => {
                                            change('kiekis', (value.target.value).replace(/\D/g, ""), i, value)
                                        }}
                                        className='margin5'
                                        size='small'
                                    />
                                    <TextField
                                        key={i + '-kaina'}
                                        label={t('kaina')}
                                        // multiline
                                        maxRows={1}
                                        value={e.kaina ? e.kaina.replace(',', '.') : ''}
                                        onChange={value => { change('kaina', value.target.value, i, value) }}
                                        className='margin5'
                                        size='small'
                                    />
                                    <CustomButton
                                        disabled={!deleteOn} onClick={() => {
                                            remove(info, i)
                                        }}
                                        text='Remove'
                                    />
                                </div>
                            )) : ''}
                        <div className='row'>
                            <TextField
                                key={'pavadinimas'}
                                label={t('pavadinimas')}
                                // multiline
                                maxRows={1}
                                value={medziaga.pavadinimas ? medziaga.pavadinimas : ''}
                                onChange={value => { setMedziaga({ ...medziaga, pavadinimas: value.target.value }) }}
                                className='margin5'
                                size='small'
                            />
                            <TextField
                                key={'mato_vienetas'}
                                label={t('mato_vienetas')}
                                // multiline
                                maxRows={1}
                                value={medziaga.vienetas ? medziaga.vienetas : ''}
                                onChange={value => { setMedziaga({ ...medziaga, vienetas: value.target.value }) }}
                                className='margin5'
                                size='small'
                            />
                            <TextField
                                key={'Kiekis'}
                                label={t('Kiekis')}
                                // multiline
                                maxRows={1}
                                value={medziaga.kiekis ? medziaga.kiekis : ''}
                                onChange={value => { setMedziaga({ ...medziaga, kiekis: value.target.value }) }}
                                className='margin5'
                                size='small'
                            />
                            <TextField
                                key={'kaina'}
                                label={t('kaina')}
                                // multiline
                                maxRows={1}
                                value={medziaga.kaina ? medziaga.kaina : ''}
                                onChange={value => { setMedziaga({ ...medziaga, kaina: value.target.value }) }}
                                className='margin5'
                                size='small'
                            />
                            <CustomButton
                                onClick={() => {
                                    add(info)
                                }}
                                text='prideti'
                            />
                        </div>
                    </div>
                </ThemeProvider>
                <CustomButton
                className='margin5 width100'
                    onClick={() => {
                        props.update(props.info.id, info);
                        setAlert(true)
                        setTimeout(() => { setAlert(false) }, 3000)

                    }}
                    text='issaugoti'
                />


                {
                    alert ?

                        <Alert
                            variant="outlined"
                            severity="success"
                        >
                            {t('issaugota')}
                        </Alert>
                        : ''
                }

            </div>
            : ''


    )
}

export default ModalVidus


