import { useEffect } from "react"
import { useContext } from "react"
import { TaskContext } from "../AppStates"

const LoadingTimer = () => {

    const { isLoading, setIsLoading } = useContext(TaskContext)
    const delay = 500
    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, delay)

    }, [])
}

export default LoadingTimer