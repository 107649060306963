import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { TaskContext } from "../AppStates";

export const useUser = (options = {}) => {
    const { token, path } = useContext(TaskContext);
    const [users, setUsers] = useState([]);
    const [singleUser, setSingleUser] = useState({})
    const { extraFilters = "" } = options;

    const getAllUsers = useCallback(async () => {
        axios
            .get(path + 'users' + extraFilters, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setUsers(response.data);
                return response.data.data
            })
            .catch((error) => {
                console.log('An error occurred:', error);
            });
    }, [token, path])

    const getUser = useCallback(async (id, setter) => {
        axios
            .get(path + 'users/' + id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if (setter)
                    setter(response.data);

                return response.data.data
            })
            .catch((error) => {
                console.log('An error occurred:', error);
            });
    }, [token, path]);

    const updateUser = useCallback(async (id, info, setter) => {
        try {
            var fe = await fetch(path + 'users/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(
                    info
                ),
            })
            if (setter)
                setter(await fe.json())
        }
        catch (e) { console.log(e) }
    }, [token, path]);

    const DELETE = useCallback(async (id) => {
        console.log(id)
        fetch(path + 'users/' + id, {
            method: 'DELETE',
            // mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            console.log(response.json());
        }).catch((e) => { console.log(e); })
    }, [token, path]);

    const create = useCallback(async (info = {}, setter = (s) => s) => {
        try {
            var fe = await fetch(path + 'users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(info),
            })
            var jsonn = await fe.json()
            var errorsM = ''
            if (jsonn?.error?.status === 400) {
                console.log('js', jsonn, Object.keys(jsonn.error.details).length)
                if (Object.keys(jsonn.error.details).length > 0) {
                    jsonn.error.details.errors.details.error.map(e => {
                        errorsM += (e.message + '\n')
                    })
                }
                else {
                    errorsM = jsonn.error.message
                    // alert(errorsM)
                    setter(false)
                }
            } else {

                setter(true)
                return jsonn
            }
        }
        catch (e) {
            console.log(e);
            alert(e)
        }
    }, [path, token]);

    const register = useCallback(async (info = {}, setter = null) => {
        try {
            var fe = await fetch(path + '/auth/local/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(info),
            })
            var jsonn = await fe.json()
            var errorsM = ''
            if (jsonn?.error?.status === 400) {
                console.log('js', jsonn, Object.keys(jsonn.error.details).length)
                if (Object.keys(jsonn.error.details).length > 0) {
                    jsonn.error.details.errors.details.error.map(e => {
                        errorsM += (e.message + '\n')
                    })
                }
                else errorsM = jsonn.error.message
                alert(errorsM)
                if (setter)
                    setter(false)
            } else {
                if (setter)
                    setter(true)
                return jsonn
            }
        }
        catch (e) {
            console.log(e);
            alert(e)
        }
    }, [path])

    return {
        users,
        getUser,
        getAllUsers,
        DELETE,
        updateUser,
        create,
        register
    }
}
