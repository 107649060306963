import { Tooltip } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import KategorijosNustatymas from "../functions/kategorijosNustatymas"
import Edit from "../assets/edit.png";
import DeletePng from "../assets/delete.png";
import { t } from 'i18next';



const MobileGrid = ({ info, isdestymas, buttons, single }) => {
    var renderData = []

    const [, setInfo] = useState(info || [])

    useEffect(() => {
        setInfo(info)
        // console.log(info)
        console.log('isdestymas', isdestymas, 'info', info)
    }, [])
    // useEffect(() => {
    // setInfo(info)
    // console.log(props)
    // }, [info])
    return (
        info && !single && Object.keys(info).length > 0 ? (
            <div>
                {Object.keys(info).map((e, i) =>
                (<div key={'darbas' + i + ' mobile-grid'} className="mobile grid row">
                    {isdestymas.map((key) => {
                        return <div key={t(key) + info[e][key]} className={t(key) + '-mobile-grid column-mobile-grid'}> <div className="bold">{t(key)}  </div><div>{info[e][key]}</div></div>
                    })}
                    {buttons.map((b, i) => {
                        return <div key={t(b.key)} className={t(b.key) + '-mobile-grid column-mobile-grid'}> <div className="bold">{t(b.key)}  </div>
                            <div>{b.button(info, e, b.update, t)}</div></div>
                    })}
                </div>)
                )}
            </div>

        ) : info && single == true && Object.keys(info).length > 0 && !isdestymas ?
            Object.keys(info).map((e, i) =>
            (<div key={'darbas' + i + ' mobile-grid'} className="mobile grid element">
                <div key={e} className={t(e) + '-mobile-grid column-mobile-grid'}> <div className="bold">{t(e)}  </div><div>{info[e]}</div></div>
            </div>)
            ) : info && single == true && Object.keys(info).length > 0 && isdestymas && isdestymas.length > 0 ?
                isdestymas.map((e, i) =>
                (<div key={'darbas' + i + ' mobile-grid'} className="mobile grid element">
                    <div key={e} className={t(e) + '-mobile-grid column-mobile-grid '}> <div>{info[e]}{e == 'vienetai' || e == 'kiekis' ? ' ' + info.mato_vienetas : e == 'kaina' ? '€' : ''}</div></div>
                </div>))
                : (
                    'No data'
                )
    )
}
export default MobileGrid

const Kategorija = (props) => {
    var e = e
    return (<div>
        <Tooltip title={e.kategorija}>
            <img
                className={e.kategorija + ' kategorijos'}
                src={KategorijosNustatymas(e.kategorija)}
                onClick={(event) => {
                    e.modal(e.id)
                }}
            /></Tooltip>
    </div>)
}
const Redaguoti = (props) => {
    var e = props.e
    return (
        <div
            className="deleteImg"
            onClick={(event) => {
                e.modal(e.id)
            }}
        >
            <img src={Edit} />
        </div>
    );
}
const Delete = (props) => {
    var e = props.e
    return (
        <div
            className="deleteImg"
            onClick={(event) => {
                e.delete(e.id)
            }}
        >
            <img src={DeletePng} />
        </div>
    );
}