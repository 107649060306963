import './css/Modal.css';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import React, { useContext, useEffect, useState, } from 'react';
import { TaskContext } from '../AppStates';
import './css/calendar.css'
import ImportAll from '../functions/importAll';
import { Loader } from './Loader';


const ModalVidusUserPicker = ({ info, deploy, id, tag, update, updateUser, close }) => {
    const { path, token } = useContext(TaskContext)
    const { t } = useTranslation()
    const [, setInfo] = useState(info || [])
    const [, setDeploy] = useState(deploy)
    const [, setId] = useState(id)
    const [darbo, setDarbo] = useState([])
    const [grafikai, setGrafikai] = useState([])
    const [yraGalimu, setYraGalimu] = useState(false)
    const [ats, setAts] = useState({})

    const getGrafikai = async () => {
        let kategorija = t('kategorija').indexOf(deploy.attributes.kategorija) + ''
        ImportAll(path, token, 'darbo-dienos/?filters[Kategorija][$contains]=' + kategorija, setGrafikai, 'users');
    }
    const GetDarbuotojas = async (id) => {
        let ids = id.map(e => { return '[id][$in]=' + e }).join('&')
        await ImportAll(path, token, 'users/?filter' + ids, setInfo, 'users');
    }

    const getDarbai = async () => {
        let data = deploy.attributes.data[0].split('T')[0]
        let laikas = deploy.attributes.Laikas
        await ImportAll(path, token, 'darbai/?filters[data][$containsi]=' + data + '&[Laikas][&containsi]=' + laikas, setDarbo);
    }


    useEffect(() => {
        getGrafikai()
    }, [])


    useEffect(() => {
        var data = deploy.attributes.data[0].split('T')[0]
        var tinka
        var suskaiciuota
        if (grafikai.data) {
            if (grafikai.data.length > 0) {
                tinka = grafikai.data.map(e => {
                    let datos = Object.keys(e.attributes.datos)
                    if (datos.indexOf(data) != -1 && e.attributes.datos[data].laikas.indexOf(deploy.attributes.Laikas[0]) != -1) {
                        return e.attributes.userId
                    }
                })
                if (info)
                    suskaiciuota = info.map(e => {
                        if (tinka.indexOf(e.id + '') != -1) {
                            return e
                        }
                    }).filter(item => item !== undefined)
                setInfo(suskaiciuota)
                getDarbai()
            }
        }
    }, [grafikai])
    useEffect(() => {
        var negalima = []
        let skaiciuojami = []
        if (darbo.length > 0) {
            darbo.map(e => {
                if (skaiciuojami.indexOf(e.attributes.darbuotojoId) != -1) {
                    negalima.push(e.attributes.darbuotojoId)
                }
                else if (e.attributes.darbuotojoId != null && e.attributes.darbuotojoId != -1) {
                    skaiciuojami.push(e.attributes.darbuotojoId)
                }
            })

            let galimi = []
            if (info)
                galimi = info.map(e => { if (negalima.indexOf(e.id) == -1) { return e } }).filter(item => item !== undefined)
            if (galimi.length != 0) {
                setAts(galimi)
                setYraGalimu(true)
            }
        }

    }, [darbo])
    if (!info) {
        return <Loader />
    }
    return (
        <div className='paddingasPickeriui'>
            {yraGalimu == true ?
                <div className='userPicker'>
                    <div className='pasirinkti_darbutoja_name'>
                        <h3>{t('priskirti_meistra')}</h3>
                    </div>
                    {Object.keys(ats).length != 0 ?
                        ats.map((e, i) => (
                            e.KablukasNr && tag == 'kablukas' ? ''
                                :
                                <div className='pasirinkti_darbuotoja' key={'user-outer' + i} >
                                    <div key={'user' + i} className={e.id == id ? 'selected mobileColumn' : 'nope mobileColumn'}>
                                        <TextField
                                            disabled
                                            key={i + '-Vardas'}
                                            label={t('name')}
                                            maxRows={1}
                                            value={e.Vardas ? e.Vardas + ' ' + e.Pavarde : ''}
                                        />
                                        <button className='btn primary pasirinktiNarow ' onClick={() => {
                                            let ids = e.id + ''
                                            setId(e.id)
                                            update(deploy.id, deploy.attributes, ids)
                                            if (tag == 'kablukas') {
                                                updateUser(deploy.attributes.darbuotojoId, { KablukasNr: '' })
                                                updateUser(e.id, { KablukasNr: deploy.attributes.Numeris })
                                            }
                                            close()
                                        }}
                                        >{t('pasirinkti')}</button>
                                    </div>
                                </div>
                        ))

                        : t('meistru_nera')}
                </div>
                : t('meistru_nera')}</div>
    )
}
export default ModalVidusUserPicker