import './css/Modal.css';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import React, { useContext, useEffect, useState, } from 'react';
import { TaskContext } from '../AppStates';
import './css/calendar.css'
import ImportAll from '../functions/importAll';
import CustomButton from './Mui/CustomButton';


const ModalVidusUserPicker = ({ deploy, info, id, updateUser, update, tag, close }) => {
    const { t } = useTranslation()
    console.log(info)
    return (
        <div className='userPicker'>
            {info && Object.keys(info).length != 0 ?
                info.map((e, i) => (
                    e.KablukasNr && tag == 'kablukas' ? 'Visi meistrai priskirti'
                        :
                        <div key={'user-outer' + e.id} >
                            {/* {console.log(e)} */}
                            <div key={'user' + i} className={e.id == id ? 'mobileColumn ' + 'selected' : 'mobileColumn ' + 'nope'}>
                                <TextField
                                    className='marginV10'
                                    disabled
                                    key={i + '-Vardas'}
                                    label={t('name')}
                                    maxRows={1}
                                    value={e.username ? e.username : ''}
                                />
                                <CustomButton
                                className='margin5'
                                onClick={() => {
                                    let ids = e.id + ''
                                    // setNewId(e.id)
                                    update(deploy.id, deploy.attributes, ids)
                                    // console.log(props.tag, 'e', e, deploy, { KablukasNr: props.deploy.attributes.Numeris })
                                    if (tag == 'kablukas') {
                                        updateUser(deploy.attributes.darbuotojoId, { KablukasNr: '' })
                                        updateUser(e.id, { KablukasNr: deploy.attributes.Numeris })

                                    }
                                    close()
                                }}
                                text='pasirinkti'
                                />
                            </div>
                        </div>
                ))

                : 'Nera tinkamo darbuotojo'}
        </div>
    )
}
export default ModalVidusUserPicker