import { useEffect } from "react"
import { useContext, useState } from "react"
import { TaskContext } from "../AppStates"
import { ImagesAdd } from "./ImageAdd"

import Modal from 'react-modal';
import { t } from "i18next";
import 'react-quill/dist/quill.snow.css';



const Images = ({ info, count = 6, id, api, type, r, disabled, imagesForCreate, imagesArr }) => {

    const { path, token } = useContext(TaskContext)
    const [img, setImg] = useState('')
    const [arr, setArr] = useState([])
    const update = async (images) => {
        await addImagesToPost(path, images, id, token, type, api).then(() => {
            r()
        }).catch((e) => { console.log(e); });
    }
    useEffect(() => {
        if (info && id) {
            setArr(info)
        }
    }, [info])
    // useEffect(() => { console.log('img', img) }, [img])
    useEffect(() => {
        if (arr != imagesArr && !id) {
            imagesForCreate(arr)
        }
    }, [arr])

    const handleImageAdd = (value) => {
        if (id) { update(value).then(() => { }); }
        else {
            if (arr.length > 0) {
                setArr([...arr, {
                    attributes: { url: value[0].data_url },
                    file: value[0].file,
                    name: value[0].file.name,
                }])
            }
            else {
                setArr([{
                    attributes: { url: value[0].data_url },
                    file: value[0].file,
                    name: value[0].file.name,
                }])
            }
        }

    }
    return (
        <div className='modalImg'>
            {arr && arr[0] != null && arr.length > 0 ?
                <div className='manyImg'>
                    {arr.map((e, i) => (
                        <div key={e.attributes.url + " " + i + " container"}>
                            <div
                                className="padidinti"
                                key={e.attributes.url + " " + i + " container"} onClick={() => {
                                    setImg(e.attributes.url)
                                }}><img src={e.attributes.url} />
                            </div>
                            {disabled ? '' :
                                <button
                                    className="deletePht"
                                    onClick={() => {
                                        if (id)
                                            imgDelete(path, e.id, token).then(() => {
                                                r();
                                            }).catch((e) => { console.log(e); })
                                        else {
                                            setArr(arr.splice(i, 1));
                                            imagesForCreate(arr)
                                        }

                                    }}
                                >{t('trinti')}</button>
                            }
                        </div>
                    ))}</div>

                : ''}
            {disabled ? '' :
                <div className="manyImg">

                    <ImagesAdd
                        r={() => { r() }}
                        imagesArr={imagesArr}
                        setter={(value) => { handleImageAdd(value); }} count={count} />
                </div>
            }
            <Modal
                isOpen={img ? true : false}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setImg('') }}
            >
                {img ?
                    <div className="overAll"
                        onClick={() => {
                            setImg('')
                        }}
                    >
                        <img src={img} />
                    </div>
                    : ''}
            </Modal>
        </div>
    )
}

export default Images

export const addImagesToPost = async (path, images, id, token, type, api) => {
    const formData = new FormData();
    images.forEach(img => {
        formData.append("files", img.file, img.name);
    })
    formData.append("ref", "api::" + api + "." + api);
    formData.append("refId", id);
    formData.append("field", type);
    try {
        if (images.length > 0) {
            const res = await fetch(`${path}upload`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });
            await res.json().then(data => console.log(data))
            return res
        }
    } catch (error) {
        console.error("error while adding images to post", error)
        return error.response
    }
}
export const imgDelete = async (path, id, token) => {
    try {
        const res = await fetch(`${path}upload/files/` + id, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return true
    }
    catch (e) { console.log(e); return false }
}