import React, { useEffect, useState, useReducer } from 'react';
import Modal from 'react-modal';
import Pratesimas from '../functions/pratesimas.js';
import './css/Modal.css';
import { useTranslation } from 'react-i18next';
import ModalVidus from './ModalVidusDarbuotojasDarbai'
import ModalTimePickerVidus from './ModalTImePickerVidus';
import ModalVidusUserPicker from './ModlasVidusUserPicker';
import ModalMedziaguPicker from './ModlasMedziaguPicker.js';
import Alert from '@mui/material/Alert';
import { useContext } from 'react';
import { TaskContext } from '../AppStates';
import { useStrapiObjectClass } from '../hooks/useStrapiObject';
import { useUser } from '../hooks/useUser';

const ArchiveModal = ({ id, setId, setIsOpen, modalIsOpen, r, setR, senasOn }) => {
    const { user, path, token } = useContext(TaskContext)
    const { t } = useTranslation()
    const [info, setInfo] = useState({})
    const [deploy, setDeploy] = useState({})
    const [timePicker, setTimePicker] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [darbuotojas, setDarbuotojas] = useState({})
    const [userPicker, setUserPicker] = useState(false)
    const [medziaguPicker, setMedziaguPicker] = useState(false)
    const [selectOpen, setSelectOpen] = useState(false)
    const [alert, setAlert] = useState(false)
    const { users: darbuotojai, getAllUsers: GetDarbuotojai, getUser: GetDarbuotojas } = useUser({ extraFilters: '?filters[type][$eq]=Meistras(-ė)' })
    const { data: senas, getSingle: getSenas, update: updateSenas } = useStrapiObjectClass({ name: 'seni-d', extraFilter: '&populate=*' })
    const { data: esamas, getSingle, update: update } = useStrapiObjectClass({ name: 'darbai', extraFilter: '&populate=*' })
    const { data: kablukas, getAll: getKablukai, } = useStrapiObjectClass({ name: 'kablukas', extraFilter: '&filters[darbuotojoId][$eq]=' + darbuotojas.id })
    const pratesti = async (id, statusas) => { await Pratesimas('seni-d/', 'darbai/' + id, setInfo, info, statusas); getSingle(id, setDeploy) }



    const laikaKeitis = async (id, data) => { setTimePicker(true) }
    const change = (what, value) => { setDeploy(...deploy, { attributes: { [what]: value } }) }
    useEffect(() => {
        if (senasOn) {
            getSenas(id, setInfo)
        }
        else {
            getSingle(id, setInfo);
        }
        getKablukai(); GetDarbuotojai()
    }, [getSingle, getKablukai, GetDarbuotojai, getSenas, senasOn])
    useEffect(() => {
        if (Object.keys(info).length != 0) {
            setDeploy(info);
            if (info.attributes.darbuotojoId) {
                GetDarbuotojas(info.attributes.darbuotojoId, setDarbuotojas)
            }
        }
    }, [info, r])
    useEffect(() => { if (alert) setTimeout(() => { setAlert(false) }, 3000) }, [alert])

    useEffect(() => {
        if (senasOn) {
            getSenas(id, setInfo)
        }
        else {
            getSingle(id, setInfo);
        }
    }, [r])


    return (
        Object.keys(deploy) != 0 ?
            <div>
                <Modal
                    className={timePicker ? 'modal2 timePicker' : 'modal2'}
                    ariaHideApp={false}
                    isOpen={modalIsOpen}
                    onRequestClose={() => {
                        timePicker ? setTimePicker(false) : userPicker ? setUserPicker(false) : medziaguPicker ? setMedziaguPicker(false) : setIsOpen(false);

                        setR();
                    }

                    }
                    contentLabel="Example Modal"
                >
                    {userPicker ?
                        <div> </div>
                        :
                        medziaguPicker ?
                            <div>
                                <ModalMedziaguPicker
                                    key={'medziaguPicker'}
                                    info={kablukas[0]}
                                    darbas={info}
                                    updateDarbas={async (medziagos) => {
                                        await update(id, { ...info, medziagos: medziagos });
                                        setR(r + 1)
                                    }}
                                    close={() => { setMedziaguPicker(false); r() }}
                                />
                            </div>
                            :
                            !timePicker ?
                                <div>
                                    <div className='testModal'>
                                        <h1 className=' modalHeaderName'> {'Užsakymo valdymas'}</h1>

                                        <div className="selectCont">
                                            {selectOpen ?
                                                <div className='selectDarbasCont selectas' >
                                                    {info.attributes.seni ?
                                                        info.attributes.seni.map((item, index) => (
                                                            <div key={index} className={selectedIndex == index ? 'selectedDarbas  fullFlex selectDropdown' : ' selectDropdown fullFlex'}
                                                                onClick={() => { getSenas(item, setDeploy); setSelectedIndex(index); setSelectOpen(false) }} >
                                                                <Senas
                                                                    id={item}
                                                                    set={(id, toWhat) => { getSenas(id, setDeploy) }}
                                                                    getSenas={getSenas}
                                                                />
                                                            </div>
                                                        ))
                                                        : ''
                                                    }
                                                    <div className={selectedIndex == -1 ? 'selectedDarbas selectDropdown fullFlex row2' : 'fullFlex selectDropdown row2'} onClick={() => { getSingle(id, setInfo); setSelectedIndex(-1); setSelectOpen(false) }} >
                                                        <div className='row pointer '>
                                                            <h4 className='miniM lowWeight'>
                                                                {info.attributes.data[0] ? new Date(info.attributes.data[0]).toISOString().split('T')[0].replace('-', '.').replace('-', '.') : 'Data nepriskirta'}
                                                            </h4>
                                                            <h4 className='lowWeight'>{info.attributes.Laikas ? info.attributes.Laikas : ''}</h4></div>
                                                        <h4 className={info.attributes.statusas + " statusas statusasDropw"} >{info.attributes.statusas}</h4>
                                                    </div>
                                                </div>
                                                : ''
                                            }
                                            <div className='selectDarbasCont'>

                                                {/* Idėti selectą tik data ir laikas*/}

                                                <div className={selectOpen ? 'selectedDarbas row fullFlex dropdownRodykle' : 'selectedDarbas row fullFlex'} onClick={() => { selectOpen ? setSelectOpen(false) : setSelectOpen(true) }} >
                                                    <div className='row pointer'>
                                                        <h4 className='miniM lowWeight'>
                                                            {deploy.attributes.data[0] ? new Date(deploy.attributes.data[0]).toISOString().split('T')[0].replace('-', '.').replace('-', '.') : ''}
                                                        </h4>
                                                        <h4 className='lowWeight'>{info?.attributes?.Laikas[0] ? info.attributes.Laikas : ''}</h4></div>
                                                </div>
                                            </div>
                                        </div>
                                        <h3 className='uzsakymoNr modalHeader'> {info.id != null ? makeID(deploy) : ''}</h3>
                                        <h4 className={info.attributes.statusas + " statusas"} >{info.attributes.statusas}</h4>
                                    </div>
                                    <ModalVidus

                                        selectedIndex={selectedIndex}
                                        r={() => { setR() }}
                                        deploy={deploy}
                                        id={id}
                                        close={() => { setIsOpen(false) }}
                                        medziagos={() => { setMedziaguPicker(true); }}
                                        laikaKeitis={(id, info) => { laikaKeitis(id, info) }}
                                        set={(what, value) => { change(what, value) }}
                                        update={(value, data) => {
                                            if (selectedIndex == -1) { update(value, data) }
                                            else { updateSenas(value, data) }
                                            setR(r + 1)
                                        }}
                                        pratesti={(value, statusas) => { pratesti(value, statusas); setR(r + 1) }}
                                        darbuotojas={darbuotojas}
                                        darbuotojai={darbuotojai}
                                        modalGetDarbuotojai={() => {
                                            GetDarbuotojai().then(() => {
                                                setUserPicker(true)
                                            })
                                        }}
                                        alert={() => { setAlert(true) }}
                                    />
                                    <button className='closeBtn' onClick={() => { setIsOpen(false) }}>✕</button>
                                </div>
                                :
                                <ModalTimePickerVidus
                                    info={deploy}
                                    darbuotojas={deploy?.attributes?.darbuotojoId ? darbuotojas : {}}
                                    update={async (id, info) => {
                                        await update(id, { ...info }); setTimePicker(false); setR()
                                        getSingle(id, setInfo);
                                    }}
                                    user={user}
                                    seni={info.attributes.seni}
                                    close={() => { setTimePicker(false); r() }}
                                />
                    }
                </Modal>
                {
                    alert ?

                        <Alert
                            variant="outlined"
                            severity="success"
                        >
                            {t('atnaujinta')}
                        </Alert>
                        : ''
                }

            </div>
            : ''
    )
}
export default ArchiveModal


const Senas = ({ set, id, getSenas }) => {
    const [info, setInfo] = useState({})
    useEffect(() => {
        getSenas(id, setInfo)
    }, [])

    return (
        Object.keys(info) != 0 ?
            <div className='row pointer'>
                <div className='row'><h4 className='miniM lowWeight'>{info.attributes.data[0] ? new Date(info?.attributes?.data[0]).toISOString().split('T')[0].replace('-', '.').replace('-', '.') : 'datos nera'}</h4><h4 className='lowWeight'> {info?.attributes?.Laikas[0] ? info.attributes.Laikas[0] : 'Laiko nera'}</h4></div>
                <h4 className={info.attributes.statusas + " statusas statusasDropw"}>{info.attributes.statusas}</h4>
            </div>
            : ''
    )
}


const makeID = (deploy) => {
    var text = 'Užsakymo Nr. '
    var ats = ''
    if (deploy.attributes.IDNr) {
        ats = '-' + deploy.attributes.IDNr
    }
    if (deploy.attributes.IDNr && deploy.attributes.data[0]) {
        ats = deploy.attributes.data[0].slice(0, 4) + '-' + deploy.attributes.IDNr
    }
    if (deploy.attributes.IDNr == null) {
        ats = '-' + deploy.id + '-' + parseInt(deploy.attributes.seni ? deploy.attributes.seni.length + 1 : 1)
    }
    if (deploy.attributes.IDNr == null && deploy.attributes.data[0]) {
        ats = deploy.attributes.data[0].slice(0, 4) + '-' + deploy.id + '-' + parseInt(deploy.attributes.seni ? deploy.attributes.seni.length + 1 : 1)
    }

    return text + ats
}




{/* <ModalVidusUserPicker
                                key={'userPicker'}
                                info={darbuotojai}
                                deploy={deploy}
                                id={deploy.attributes.darbuotojoId}
                                update={async (id, info, userId) => {
                                    var save = {}
                                    if (info.statusas == t('nepriskirtas_pratestas')) {
                                        save.statusas = t('priskirtas_tesiamas')
                                    } else { save.statusas = t('priskirtas') }
                                    update(id, { ...info, darbuotojoId: userId, statusas: save.statusas });
                                }}
                                close={() => { setUserPicker(false) }}
                            />
                            {console.log(info.statusas)} */}