import './css/Modal.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Checkbox, TextField } from '@mui/material';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import Select, { components } from 'react-select';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState, } from 'react';
import calendar from '../assets/calendar.png';
import { emailEmptyValidation, nameValidation, numberEmptyValidation, surnameValidation, usernameValidation, ValidateUser } from '../functions/validation'
import { useContext } from 'react';
import { TaskContext } from '../AppStates';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import ImportAll from '../functions/importAll';
import Update from '../functions/update';
import CustomButton from './Mui/CustomButton';
import { useStrapiObjectClass } from '../hooks/useStrapiObject';


const ModalVidusDarbuotojas = ({ deploy, id, update, laikaKeitis }) => {
    const { t } = useTranslation()
    const { type, path, token } = useContext(TaskContext)
    const [info, setInfo] = useState(deploy || {})
    const [category, setCategory] = useState([])
    const [alert, setAlert] = useState(false)
    const [kategorijaO, setkategorijaO] = useState([])
    const [dutyO, setdutyO] = useState('')
    const [passwordShown, setPasswordShown] = useState(false);
    const [r, setR] = useState(0);
    var duty = []


    const gather = (item, vertimas) => {
        if (item == undefined) { return '' }
        let splited = item.split(',')
        let arr = []
        splited.forEach(element => {
            if (!isNaN(element) && element) {
                arr.push({
                    value: element,
                    label: vertimas[element]
                })
            }

        });
        console.log(arr)
        return arr
    }
    const { data: kablukai, getAll: getAllKablukai, update: updateKablis } = useStrapiObjectClass({ name: 'kablukas' })
    const { data: grafikas, getAll: getGrafikas, update: updateGrafikas } = useStrapiObjectClass({ name: 'darbo-dienos', extraFilter: '&filters[userId][$eq]=' + id })

    const updateKablukas = async (id, data) => {
        await updateKablis(id, data)
        await getAllKablukai()
    }
    const [grafikoId, setGrafikoId] = useState(null)
    useEffect(() => {
        getGrafikas()
    }, [getGrafikas])
    useEffect(() => {
        console.log('grafikas', grafikas)
        if (grafikas.length > 0) { setGrafikoId(grafikas[0].id) }
    }, [grafikas])
    useEffect(() => {
        if (deploy)
            setInfo(deploy)
        getAllKablukai()
    }, [deploy])
    useEffect(() => {
        if (info) {
            setkategorijaO(gather(info.Kategorija, t('kategorija')))
            setdutyO({ label: info.type, value: info.type })
            var arr = []
            var turimi = info.Kategorija ? gather(info.Kategorija, t('kategorija')) : []
            var labels = turimi.map(e => { return e.label })
            t('kategorija').forEach((e, i) => { if (labels.indexOf(e) == -1) { arr.push({ value: i, label: e }) } })
            setCategory(arr.sort((a, b) => a.label > b.label ? 1 : -1))
        }
    }, [info])
    const [esamasKablukas, setEsamasKablukas] = useState({})
    const [laisviKablukai, setLaisviKablukai] = useState([])
    useEffect(() => {
        var laisvi = [{ label: 'Kablukas nepriskirtas', value: null }]
        kablukai.map((e) => {
            if (info.id == e.attributes.darbuotojoId) {
                setEsamasKablukas({ label: e.attributes.Numeris, value: e.id })
            }
            else if (!e.attributes.darbuotojoId && e.attributes.Numeris) {
                laisvi.push({ label: e.attributes.Numeris, value: e.id })
            }
            setLaisviKablukai(laisvi)
        })

    }, [kablukai])
    const keiciamKabluka = (value) => {
        updateKablukas(esamasKablukas.value, { darbuotojoId: '', statusas: t('nepriskirtas') })
        if (value.value) { updateKablukas(value.value, { darbuotojoId: info.id + '', statusas: t('priskirtas') }) }
        else {
            setEsamasKablukas({});
            // updateKablukas(esamasKablukas.value, { darbuotojoId: '', statusas: t('nepriskirtas') })
        }
        // setEsamasKablukas(value)
        // getAllKablukai()
    }
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    t('pareigybe').forEach((e, i) => { duty.push({ value: i, label: e }) })
    duty = duty.sort((a, b) => a.label > b.label ? 1 : -1)


    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            color: '#DB643E',
            background: '#f0eee7',
            width: '100%',
        }),


        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';


            return { ...provided, opacity, transition };
        }
    }



    const handleSubmit = async (event) => {
        try {
            if (ValidateUser(info)) {
                update(info.id, info)
                updateGrafikas(grafikoId, { Kategorija: info.Kategorija })
            }
        }
        catch (e) { console.log('er', e) }

    }
    const Input = ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />;
    return (
        Object.keys(info) != 0 || id == null ?
            <div className='editWorkerModal'>
                <div className='editWorkerName'>
                    <a>{id ? t('redaguoti_darbuotoja') : t('sukurti_darbuotoja')}</a>
                </div>
                <div className='gridColumn marginV10'>
                    <div className='modalContVidus'>
                        <TextField
                            key={'username'}
                            label={t('username')}
                            disabled={type == 2 || type == 1 ? true : false}
                            // multiline
                            error={usernameValidation(info.username) ? false : true}
                            value={info.username ? info.username : ''}
                            maxRows={1}
                            onChange={value => { setInfo({ ...info, username: value.target.value }) }}

                            color={'primary'}
                        />

                        <TextField
                            key={'Vardas'}
                            label={t('name')}
                            // multiline
                            disabled={type == 1 ? true : false}
                            error={nameValidation(info.name) ? false : true}
                            maxRows={1}
                            value={info.Vardas ? info.Vardas : ''}
                            onChange={value => { setInfo({ ...info, Vardas: value.target.value }) }}
                        />
                        <TextField
                            key={'Pavarde'}
                            label={t('surname')}
                            disabled={type == 1 ? true : false}
                            // multiline
                            error={surnameValidation(info.Pavarde) ? false : true}
                            maxRows={1}
                            value={info.Pavarde ? info.Pavarde : ''}
                            onChange={value => { setInfo({ ...info, Pavarde: value.target.value }) }}
                        />




                    </div>
                    <div className='modalContVidus'>

                        <TextField
                            key={'tel'}
                            label={t('tel')}
                            error={numberEmptyValidation(info.tel) ? false : true}
                            disabled={type == 1 ? true : false}
                            placeholder="+370..."
                            maxRows={1}
                            value={info.tel ? info.tel : ''}
                            onChange={value => { setInfo({ ...info, tel: value.target.value }) }}
                        />
                        <TextField
                            key={'email'}
                            label={t('email')}
                            disabled={type == 2 || type == 1 ? true : false}
                            error={emailEmptyValidation(info.email) ? false : true}
                            maxRows={1}
                            value={info.email ? info.email : ''}
                            onChange={value => { setInfo({ ...info, email: value.target.value }) }}
                        />
                        <div className='pswSuAkyte'>
                            <TextField
                                key={'password'}
                                label={t('password')}
                                // multiline
                                type={passwordShown ? 'text' : 'password'}
                                value={info.password ? info.password : ''}
                                maxRows={1}
                                onChange={value => { setInfo({ ...info, password: value.target.value }) }}
                                color={'primary'}
                            />
                            {passwordShown ? <VisibilityOffIcon className="passIcon2"
                                onClick={() => {
                                    togglePasswordVisiblity()
                                }} /> : <VisibilityIcon className="passIcon2"
                                    onClick={() => {
                                        togglePasswordVisiblity()
                                    }} />}
                        </div>
                    </div>
                </div>
                    <div className='gridColumn marginV10'>

                        {id ?
                            info.is_buhaltere || info.is_admin ? '' :
                                <Select
                                    disabled={type == 0 ? false : true}
                                    label={t('kablukasNr')}
                                    styles={customStyles}
                                    className="select"
                                    placeholder={t('kalbukasNr')}
                                    isSearchable={true}
                                    options={laisviKablukai}
                                    value={Object.keys(esamasKablukas).length > 0 ? esamasKablukas : ''}
                                    onChange={(value) => { keiciamKabluka(value) }}
                                />
                            : ''}

                        <Select
                            isDisabled={type == 0 ? false : true}
                            styles={customStyles}
                            className="select"
                            placeholder={t('pareigos')}
                            isMulti={false}
                            value={dutyO}
                            options={duty}
                            onChange={(value) => {
                                setdutyO(value)
                                if (selecterino(value) == t('pareigybe')[0]) {
                                    setInfo({
                                        ...info, type: selecterino(value), is_admin: true, is_buhaltere: false
                                    })
                                }
                                else if (selecterino(value) == t('pareigybe')[1]) {
                                    setInfo({
                                        ...info, type: selecterino(value), is_admin: false, is_buhaltere: true
                                    })
                                }
                                else {
                                    setInfo({
                                        ...info, type: selecterino(value), is_admin: false, is_buhaltere: false
                                    })
                                }
                                return value
                            }}

                        />
                        <Select
                            isDisabled={type == 0 ? false : true}
                            ket={'kategorijos-select'}
                            styles={customStyles}
                            className="select"
                            placeholder={t('Kategorija')}
                            isMulti={true}
                            isSearchable={true}
                            options={category}
                            value={
                                gather(info.Kategorija, t('kategorija'))
                            }
                            // multiple
                            components={{
                                Input
                            }}
                            onChange={(value) => {
                                setInfo({
                                    ...info, Kategorija: selecterinoMulti(value),
                                })
                            }}

                        />
                        {id ?
                            <button className='workerCalendar' onClick={() => { laikaKeitis(id, info) }}> <img src={calendar} alt='kalendorius' /> {t('darbuotojo_kalendorius')} </button>
                            : ''}
                    </div>
                    {console.log(info.Kategorija)}
                <CustomButton
                    text='issaugoti'
                    onClick={() => {
                        setAlert(true)
                        setTimeout(() => { setAlert(false) }, 3000)

                        handleSubmit()
                        // setIsOpen(false)
                    }}
                />
                <Collapse in={alert}>
                    <Alert
                        variant="outlined"
                        severity="success"
                    >
                        {t('issaugota')}
                    </Alert>
                </Collapse>
            </div>
            : ''
    )
}

export default ModalVidusDarbuotojas





const selecterino = (value) => {
    let arr = [];
    return value.label
}
const selecterinoMulti = (value) => {
    let arr = []
    value.map((e, i) => {
        arr.push(e.value)
    })
    return arr + ''
}

