

import axios from "axios";
import { push } from "draft-js/lib/EditorState";
import { t } from 'i18next';
const Pratesimas = async (path, token, what, updater, setter, current, id, statusas) => {
    // var imgData = await getImages(current.nuotraukos.data, path, token)   
    var number = current.seni ? current.seni.length + 1 : 1
    var fe = await fetch(path + what, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            data: {
                IDNr: id + "-" + number,
                darbuotojoId: current.darbuotojoId,
                adress: current.adress,
                email: current.email,
                data: current.data,
                kategorija: current.kategorija,
                statusas: statusas ? statusas : current.statusas,
                Laikas: current.Laikas,
                kaina: current.kaina,
                medziagos: current.medziagos,
                pavarde: current.pavarde,
                pradirbtasLaikas: current.pradirbtasLaikas,
                problema: current.problema,
                subKategorija: current.subKategorija,
                tel: current.tel,
                uzrasine: current.uzrasine,
                uzsakomosMedziagos: current.uzsakomosMedziagos,
                vardas: current.vardas,
            }
        }),
    })
    var response = await fe.json()
    if (!current.seni) {
        current.seni = [response.data.id]
    } else {
        current.seni.push(response.data.id)
    }

    const update = (id) => {
        fetch(path + updater, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data: {
                    seni: current.seni,
                    statusas: t('planuojama'),
                    medziagos: {},
                    issiustasPriminimas: false,
                    // darbuotojoId: -1,,
                    pradirbtasLaikas: {},
                    Laikas: [],
                    data: []
                },
            }),
        }).then((response) => {
            console.log(12233, response.json())
            // setter(response.data.data)
            return response
        })
            .catch((error) => {
                console.log('An error occurred:', error);
            });
    }
    // await addImagesToPost(path, response.data.id, response.data.id, token, 'Img')
    update(current.id)
}

export default Pratesimas
