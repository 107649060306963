

const Update = async (path, token, what, id, info, get, flag) => {
    try {

        var fe = await fetch(path + what + id, {
            method: 'PUT',
            // mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(
                flag == 'users' ? info : {
                    data: info
                }
            ),
        })
        var json = await fe.json()
        console.log('js', json, fe)
        return json
    }
    catch (e) { console.log(e) }
    finally { }
}
export default Update