import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { TaskContext } from "../../AppStates";
import { Editor, EditorState, RichUtils, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
// import { RichEditorExample } from '../Components/Editor'
import { stateToHTML } from 'draft-js-export-html';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { Switch, TextField } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Images from '../ImagesAddComponent';
import CustomButton from '../Mui/CustomButton';
import { Loader } from "../Loader/Loader";

const ProduktasModal = ({ deploy, setIsOpen, r, id, newNaujiena, update, setAlert, imagesForCreate, imagesArr }) => {
    const { token, path, pathImg } = useContext(TaskContext)

    const { t } = useTranslation()
    const [info, setInfo] = useState({})
    useEffect(() => {
        setInfo(deploy)
    }, [r, deploy])


    if (!info || !info.title) {

        return (
            <Loader />
        )
    }

    return (
        <div>
            <div className="flyingOne">
                <button className='closeBtn' onClick={() => { setIsOpen(false) }}>✕</button>
                <CustomButton
                    className='width100'
                    onClick={() => {
                        setAlert(true)
                        setTimeout(() => { setAlert(false) }, 3000)
                        if (id) {
                            let save = {
                                text: info.text, subtitle: info.subtitle, title: info.title,
                                buttonName: info?.buttonName || '', buttonLink: info?.buttonLink || '',
                                show: info.show || false
                            }
                            update(id, save).then(() => { r() });
                        }
                        else {
                            newNaujiena(info)
                        }
                    }}
                    text='issaugoti'
                />
            </div>
            <div className='newsName marginV10'>
                <a>{id ? t('redaguoti_naujiena') : t('prideti_naujiena')}</a>
            </div>
            <div className='modalTekstai naujienos'>
                <div className='modalTextsNews'>
                    <div className='modalContVidus marginV10'>

                        <TextField
                            color="primary"
                            variant="outlined"
                            type="text"
                            label={t('pavadinimas')}
                            size="small"
                            margin="none"
                            value={info?.title || ''}
                            onChange={(value) => { setInfo({ ...info, title: value.target.value }) }}
                        />
                        <label key={'sub'} className='left'> <h2>{t('antraste')}</h2>
                            <ReactQuill id={'subtitle'} theme="snow" value={info?.subtitle || ''}
                                onChange={(value) => { setInfo({ ...info, subtitle: value }) }}
                            /></label>
                        <label key={'texter'} className='left'> <h3>{t('aprasymas')}</h3>
                            <ReactQuill id={'texter'} theme="snow" value={info?.text || ''}
                                onChange={(value) => { setInfo({ ...info, text: value }) }}
                            /></label>
                        <div>

                            <div className='mainImg'>
                            </div>
                            <h3> {t('main_img')} </h3>
                            <Images
                                id={id}
                                count={1}
                                api={"produktas"}
                                info={info && info.showImg ? [info.showImg.data] : []}
                                type={'showImg'}
                                imagesArr={imagesArr}
                                imagesForCreate={(v) => { imagesForCreate(v); }}
                                r={() => {
                                    if (id) r()
                                }}
                            />
                        </div>
                        <h3>{t('btnFields')}</h3>
                        <div className="gridColumn">

                            <div className="modalContVidus">

                                <TextField
                                    color="primary"
                                    variant="outlined"
                                    type="text"
                                    label={t('buttonLink')}
                                    size="small"
                                    margin="none"
                                    value={info?.buttonLink || ''}
                                    onChange={(value) => { setInfo({ ...info, buttonLink: value.target.value }) }}
                                />
                            </div>
                            <div className="modalContVidus">
                                <TextField
                                    color="primary"
                                    variant="outlined"
                                    type="text"
                                    label={t('buttonName')}
                                    size="small"
                                    margin="none"
                                    value={info?.buttonName || ''}
                                    onChange={(value) => { setInfo({ ...info, buttonName: value.target.value }) }}
                                />

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )


}
export default ProduktasModal