import React, { useEffect, useState, useReducer } from 'react';
import Modal from 'react-modal';
import Pratesimas from '../functions/pratesimas.js';
import './css/Modal.css';
import { useTranslation } from 'react-i18next';
import ModalVidus from './ModalVidusDarbuotojasDarbai'
import ModalTimePickerVidus from './ModalTImePickerVidus';
import ModalVidusUserPicker from './ModlasVidusUserPicker';
import ModalMedziaguPicker from './ModlasMedziaguPicker.js';
import Alert from '@mui/material/Alert';
import { useContext } from 'react';
import { TaskContext } from '../AppStates';
import { useStrapiObjectClass } from '../hooks/useStrapiObject';
import { useUser } from '../hooks/useUser';

const ModalSeni = ({ id, setIsOpen, modalIsOpen, r, setR }) => {
    const { user, path, token } = useContext(TaskContext)
    const { t } = useTranslation()
    const [info, setInfo] = useState({})
    const [deploy, setDeploy] = useState({})
    const [timePicker, setTimePicker] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [darbuotojas, setDarbuotojas] = useState({})
    const [userPicker, setUserPicker] = useState(false)
    const [medziaguPicker, setMedziaguPicker] = useState(false)
    const [selectOpen, setSelectOpen] = useState(false)
    const [alert, setAlert] = useState(false)
    const { data: darbuotojai, getAllUsers: GetDarbuotojai, getUser: GetDarbuotojas } = useUser()
    const { data: senas, getSingle: getSenas, update: updateSenas } = useStrapiObjectClass({ name: 'seni-d/', extraFilter: '&populate=*' })
    const { data: esamas, getSingle, update: update } = useStrapiObjectClass({ name: 'darbai', extraFilter: '&populate=*' })
    const { data: kablukas, getAll: getKablukai, } = useStrapiObjectClass({ name: 'kablukas', extraFilter: '&filters[darbuotojoId][$eq]=' + user.id })
    const pratesti = async (id, statusas) => { await Pratesimas(path, token, 'seni-d/', 'darbai/' + id, setInfo, info, user, statusas); getSingle(id, setDeploy) }


    const laikaKeitis = async (id, data) => { setTimePicker(true) }
    const change = (what, value) => { setDeploy(...deploy, { attributes: { [what]: value } }) }
    useEffect(() => { getSingle(id, setInfo); getKablukai() }, [getSingle, getKablukai])
    useEffect(() => {
        if (Object.keys(info).length != 0) {
            setDeploy(info);
            if (info.attributes.darbuotojoId != undefined) {
                GetDarbuotojas(info.attributes.darbuotojoId, setDarbuotojas)
            }
        }
    }, [info, r])
    useEffect(() => {
        if (darbuotojai && Object.keys(darbuotojai).length != 0) {
            setUserPicker(true)
        }
    }, [darbuotojai])
    useEffect(() => { if (alert) setTimeout(() => { setAlert(false) }, 3000) }, [alert])

    return (
        Object.keys(deploy) != 0 ?
            <div>
                <Modal
                    className={timePicker ? 'modal2 timePicker' : 'modal2'}
                    ariaHideApp={false}
                    isOpen={modalIsOpen}
                    onRequestClose={() => {
                        timePicker ? setTimePicker(false) : userPicker ? setUserPicker(false) : medziaguPicker ? setMedziaguPicker(false) : setIsOpen(false);

                        setR();
                    }

                    }
                    contentLabel="Example Modal"
                >
                    {userPicker ?
                        <div>
                            <ModalVidusUserPicker
                                key={'userPicker'}
                                info={darbuotojai}
                                deploy={deploy}
                                id={deploy.attributes.darbuotojoId}
                                update={async (id, info, userId) => {
                                    update(id, { ...info, darbuotojoId: userId, statusas: 'Priskirtas' });
                                }}
                                close={() => { setUserPicker(false) }}
                            />
                        </div>
                        :
                        medziaguPicker ?
                            <div>
                                <ModalMedziaguPicker
                                    key={'medziaguPicker'}
                                    info={kablukas}
                                    darbas={info}
                                    update={async (medziagos) => {
                                        update(id, { ...info, medziagos: medziagos });
                                    }}
                                    close={() => { setMedziaguPicker(false) }}
                                />
                            </div>
                            :
                            !timePicker ?
                                <div>
                                    <div className='pasirinkimaiDarbe'>
                                        <h1 className='uzsakymoNr'> {info.attributes.darbuotojoId == -1 || info.attributes.darbuotojoId == null ? 'Priskirti' : 'Redaguoti'}</h1>
                                        <h3 className='uzsakymoNr'> {info.id != null ? 'Užsakymo nr. ' + info.attributes.IDNr : ''}</h3>
                                        <div className="selectCont">

                                            {selectOpen ?
                                                <div className='selectDarbasCont selectas' >
                                                    {info.attributes.seni ?
                                                        info.attributes.seni.map((item, index) => (
                                                            <div key={index} className={selectedIndex == index ? 'selectedDarbas  fullFlex selectDropdown' : ' selectDropdown fullFlex'} onClick={() => { getSenas(item); setSelectedIndex(index); setSelectOpen(false) }} >
                                                                <Senas
                                                                    id={item}
                                                                    set={(id, toWhat) => { getSenas(id, setDeploy) }}
                                                                    getSenas={getSenas}
                                                                />
                                                            </div>
                                                        ))
                                                        : ''
                                                    }
                                                    <div className={selectedIndex == -1 ? 'selectedDarbas selectDropdown fullFlex ' : 'fullFlex selectDropdown '} onClick={() => { getSingle(id, setDeploy); setSelectedIndex(-1); setSelectOpen(false) }} >
                                                        <div className='row pointer '>
                                                            <h4 className='miniM lowWeight'>
                                                                {new Date(info.attributes.data[0]).toISOString().split('T')[0].replace('-', '.').replace('-', '.')}
                                                            </h4>
                                                            <h4 className='lowWeight'>{info.attributes.Laikas}</h4></div>
                                                        <h4 className={info.attributes.statusas + " statusas statusasDropw"} >{info.attributes.statusas}</h4>
                                                    </div>
                                                </div>
                                                : ''
                                            }
                                            <div className='selectDarbasCont'>
                                                <div className={selectOpen ? 'selectedDarbas row fullFlex dropdownRodykle' : 'selectedDarbas row fullFlex'} onClick={() => { selectOpen ? setSelectOpen(false) : setSelectOpen(true) }} >
                                                    <div className='row pointer'>
                                                        <h4 className='miniM lowWeight'>
                                                            {new Date(deploy.attributes.data[0]).toISOString().split('T')[0].replace('-', '.').replace('-', '.')}
                                                        </h4>
                                                        <h4 className='lowWeight'>{info.attributes.Laikas}</h4></div>
                                                    <h4 className={deploy.attributes.statusas + " statusas statusasDropw"} >{deploy.attributes.statusas}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ModalVidus

                                        key={'modal'}
                                        selectedIndex={selectedIndex}
                                        r={() => { setR(r + 1) }}
                                        info={deploy}
                                        medziagos={() => { setMedziaguPicker(true); }}
                                        laikaKeitis={(id, info) => { laikaKeitis(id, info) }}
                                        set={(what, value) => { change(what, value) }}
                                        update={(value, data) => { update(value, data) }}
                                        pratesti={value => { pratesti(value) }}
                                        darbuotojas={darbuotojas}
                                        modalGetDarbuotojai={() => { GetDarbuotojai() }}
                                    />
                                    <button className='closeBtn' onClick={() => { setIsOpen(false) }}>✕</button>
                                </div>
                                :
                                <ModalTimePickerVidus
                                    info={deploy}
                                    update={(id, info) => {
                                        update(id, { ...info, statusas: t('nepriskirtas'), darbuotojoId: null }); setTimePicker(false)
                                    }}
                                    user={user}
                                    close={() => { setTimePicker(false) }}
                                />
                    }
                </Modal>
                {
                    alert ?

                        <Alert
                            variant="outlined"
                            severity="success"
                        >
                            {t('atnaujinta')}
                        </Alert>
                        : ''
                }

            </div>
            : ''
    )
}
export default ModalSeni


const Senas = (set, id, getSenas) => {
    const [info, setInfo] = useState({})
    useEffect(() => {
        getSenas(id, setInfo)
    }, [])

    return (
        Object.keys(info) != 0 ?
            <div className='row pointer'>
                <div className='row'><h4 className='miniM lowWeight'>{new Date(info.attributes.data[0]).toISOString().split('T')[0].replace('-', '.').replace('-', '.')}</h4><h4 className='lowWeight'> {info.attributes.Laikas[0]}</h4></div>
                <h4 className={info.attributes.statusas + " statusas statusasDropw"}>{info.attributes.statusas}</h4>
            </div>
            : ''
    )
}