import { ltLTGrid } from './gridLt.js'
export const lt = {

    translations: {
        daugiklis: 30,
        iskvietimoMokestis: 60,
        laikas: [
            "8:00  —  11:00",
            "11:00  —  14:00",
            "14:00  —  17:00",
            "17:00  —  20:00",
        ],
        panaudotosMedziagos: 'Panaudotos medžiagos',
        datboTerminas: 'Darbo Terminas',
        pradirbtasLaikas: "Darbo laikas",
        suma: 'Suma',
        pabaigtiDarba: 'Pabaigti darbą',
        pradetiDarba: 'Pradėti darbą',
        arApmoketa: 'Ar apmokėta?',
        taip: 'Taip',
        ne: 'Ne',
        // svarbu
        priskirtas: 'Priskirtas',
        priskirtas_nepatvirtintas: 'Priskirtas Nepatvirtintas',
        priskirtas_patvirtintas: 'Priskirtas Patvirtintas',
        nepriskirtas: 'Nepriskirtas',
        apmoketa: "Atliktas Apmokėtas",
        neapmoketa: "Atliktas Neapmokėtas",
        pavedimu: 'Laukiama pavedimo',
        pabaigta: "Pabaigtas",
        vykdoma: 'Vykdomas',
        atlikta: "Atliktas",
        samata: 'Pagal sąmatą',
        samata_baigta: 'Pagal sąmatą pabaigta',
        // 
        pakeistiStatusa: 'Pakeisti statusą',
        pakeistiKategorija: 'Pakeisti kategoriją',
        planuojama: "Pratęstas apmokėtas",
        arReikalingasKitas: "Ar reikalingas kitas vizitas?",
        planuotiLaika: "Reikalingas kitas vizitas",
        planuotiLaika2: "Planuoti kitą vizitą",
        issaugoti: "Išsaugoti",
        //
        kategorija: [
            "Santechnika",
            "Elektra",
            "Šildymas",
            "Kondicionavimas",
            "Vėdinimas"
        ],
        sukurti_darbuotoja: 'Naujas meistras',
        dropImg: ' Paspauskite arba nuvilkite paveikslėlį',
        trinti: 'Ištrinti',
        prideti_naujiena: 'Sukurti naujieną',
        prideti_produkta: 'Sukurti produktą',
        redaguoti_naujiena: 'Redaguoti naujieną',
        prideti_darbuotoja: 'Sukurti meistrą',
        redaguoti: 'Valdymas',
        pavadinimas: 'Pavadinimas',
        main_img: 'Įkelti paveikslėlį',
        antraste: 'Antraštė',
        aprasymas: 'Aprašymas',
        atnaujinti: 'Atnaujinti',
        atnaujinta: 'Atnaujinta',
        redaguoti_darbuotoja: 'Profilis',
        username: 'Vartotojo vardas',
        name: 'Vardas',
        surname: 'Pavardė',
        tel: 'Telefono numeris',
        email: 'El. paštas',
        darbuotojo_kalendorius: 'Darbo grafikas',
        is_buhaltere: 'Buhalteris(-ė)',
        is_admin: 'Administratorius(-ė)',
        password: 'Slaptažodis',
        login_email: 'El. paštas:',
        login_pass: 'Slaptažodis:',
        uzsakomos_medziagos: 'Užsakomos medžiagos:',
        sf: 'Sąskaita faktūra',
        kalbukasNr: 'Kabluko nr.',
        pradinis: 'Pradinis',
        kablukas: 'Kablukas',
        Nepriskirtas_kablukas: 'Meistrui nėra priskirtas kablukas',
        kalendorius: 'Kalendorius',
        archyvas: 'Archyvas',
        naujienos: 'Naujienos',
        produktai: 'Produktai',
        darbuotojai: 'Meistrai',
        atsijungti: 'Atsijungti',
        Pavadinimas: 'Pavadinimas',
        Kiekis: 'Kiekis ',
        KainaVnt: 'Kaina vnt.',
        ar_tikrai_atsijungti: 'Ar tikrai norite atsijungti',
        pareigybe: [
            "Administratorius(-ė)",
            "Buhalteris(-ė)",
            "Meistras(-ė)"
        ],
        ar_tikrai_istrinti: "Ar tikrai norite ištrinti?",
        nera: 'Nėra',
        pridetiMedziagas: 'Pridėti medžiagas',
        prideti_kabluka: 'Pridėti kabluką',
        prisiminti: 'Prisiminti',
        prisijungti: 'Prisijungti',
        pasirinkti: 'Pasirinkti',
        pridetiPanaudotaMedziaga: 'Pridėti panaudotą medžiagą',
        kaina: 'Kaina',
        pasirinktosMedziagos: 'Pasirinktos medžiagos',
        istrinti: 'Ištrinti',
        neraKabluko: 'Nėra kabluko',
        prideti: 'Pridėti',
        Remove: 'Panaikinti',
        DeleteOn: 'Įjungti ištrinimą',
        ReikalingasKiekis: 'Reikalingas kiekis',
        priskirtiDarbuotoja: 'Priskirti meistrą',
        removeUser: 'Panaikinti meistrą',
        pakeistiDarbuotoja: 'Pakeisti meistrą',
        ValstybinisNumeris: 'Valstybinis numeris',
        bendraSuma: 'Bendra suma',
        komentarai: 'Komentarai',
        nuotraukos: 'Nuotraukos',
        problema: 'Problema',
        adresas: 'Darbo objektas / vieta',
        pareigos: 'Pareigos',
        Kategorija: 'Kategorija',
        redaguoti_pagrindini_kabluka: 'Redaguoti kiekį',
        bendra: 'Bendra',
        politika: 'Taisyklės, privatumo politika',
        issaugota: 'Išsaugota',
        perkeltiISaskaitaFaktura: 'Perkelti į sąskaitą faktūrą',
        parasas: 'Parašas',
        kortele: 'Kortele',
        pervedimu: 'Pavedimu',
        Isankstine_sf: "Išankstinė sąskaita faktūra",
        garantinis: "Garantinis darbų aktas",
        neperkrautiLaiku: "Neperkrauti laikų",
        //SF
        pvmSaskaitaFatktura: 'PVM SĄSKAITA FAKTŪRA',
        isviso: 'Iš viso, €',
        nulaida: 'Nuolaida, %',
        SumaBePVM: 'Suma Be PVM, €',
        PVMsuma: "PVM Suma, €",
        VisoSuma: 'Viso Suma, €',
        Apmokėta: 'Apmokėta, €',
        Sumoketi: 'Sumokėti, €',
        sasFHeader: [
            ["Eil.", "Nr."],
            "Pavadinimas",
            ["   Mat.",
                "   vnt."],
            "Kiekis",
            ["Kaina (be", "PVM) €"],
            ["Suma (be", "PVM) €"],
            ["PVM", "tarifas, %"],
            ["PVM ", "suma, €"],
            "Suma €",
        ],
        pvm: '21',
        sumaZodziais: 'Suma žodžiais: ',
        apmokejimoTerminas: 'Apmokėjimo terminas: 3 (trys) darbo dienos',
        apmokejimoButinai: 'Atlikdami mokėjimą, būtinai nurodykite: "Užsakymo Nr.',
        apmokejimoButinaiGalas: '"',
        sasakaitaIsrase: 'Sąskaitą išrašė',
        sasakitaPrieme: 'Sąskaitą priėmė',
        pirkejoKodas: 'Pirkėjo kodas',
        pvmFooter: 'Ši PVM sąskaita faktūra yra ir Atliktų darbų priėmimo-perdavimo aktas.',
        pvmFooter2: "Pasirašydamas šią PVM sąskaitą faktūrą Klientas patvirtina, kad darbai yra atlikti kokybiškai, pretenzijų dėl atliktų darbų ir sumontuotų medžiagų neturi.",
        pvmFooterUp: '* Meistras dirbo ',
        imonesPavadinimas: 'Įmonės pavadinimas',
        imonesAdresas: 'Įmonės adresas',
        imonesKodas: 'Įmonės Kodas',
        //end of SF
        nurasymo: 'Nurašymo aktas',
        redaguotiapmokejima: 'Redaguoti apmokėjimą',
        dokumentai: 'Dokumentai',
        mokejimobudai: 'Mokėjimo būdai',
        nustotiredaguoti: 'Nustoti redaguoti',
        medziaga: 'Medžiaga',
        mato_vienetas: 'Mato vnt.',
        prideti_medziaga: 'Pridėti medžiagą',
        atnaujinti_kabluka: 'Atstatyti kiekius',
        filter: 'Ieškoti',
        kiekis: 'Kiekis',
        iskvietimo_suma: 'Iškvietimo kaina',
        i_pvm: 'PVM kodas',
        i_kodas: 'Įmonės kodas',
        i_adress: 'Įmonės adresas',
        i_pav: 'Įmonės pavadinimas',
        SF: 'Reikalinga sąskaita faktūra',
        vardas: 'Vardas',
        pavarde: 'Pavardė',
        papildoma: 'SF duomenys',
        asmensKodas: 'Asmens kodas',
        asmensAdresas: "Adresas",
        ar_tikrai_isarchyvuoti: 'Ar tikrai norite išarchyvuoti',
        ar_tikrai_archyvuoti: 'Ar tikrai norite archyvuoti',
        ar_archyvuoti: 'Ar archuvuoti pabaigtą darbą?',
        fizinis_asmuo: 'Fizinis asmuo',
        juridinis_asmuo: 'Juridinis asmuo',
        id: 'ID',
        data: 'Data',
        statusas: 'Statusas',
        archyvuoti: 'Archyvuoti',
        darbuotojo_id: 'Meistro ID',
        kortele_pratesimas: 'Kortele su pratęsimu',
        pavedimu_pratestas: 'Pavedimu su pratęsimu',
        darbo_laikas: 'Darbo kaštai',
        nuolaidos_kodas: 'Darbo kaštų nuolaida, %',
        atsaukti: 'Atšaukti užsakymą',
        rodyti_zemelapy: 'Rodyti žemėlapyje',
        pabaigti_uzsakyma: 'Pabaigti užsakymą',
        atsaukta: 'Atšaukta',
        patvirtinti_mokejima: 'Patvirtinti apmokėjimą',
        ar_siust_sf: 'Ar norite siųsti SF?',
        issiusti: 'Išsiūsti',
        priskirti_meistra: 'Priskirti meistrą',
        meistru_nera: 'Laisvų meistrų nėra',
        rodyti_zemelapy: 'Rodyti žemėlapyje',
        pabaigti_uzsakyma: 'Pabaigti užsakymą',
        ar_siust_sf: 'Ar norite siųsti SF?',
        priskirtas_tesiamas: 'Priskirtas Tęsiamas',
        priskirti_meistra: 'Priskirti meistrą',
        meistru_nera: 'Laisvų meistrų nėra',
        apmoketa_m: 'Apmokėta',
        neapmoketa_m: 'Neapmokėta',
        ar_atsaukti: 'Ar tikrai norite atšaukti užsakymą',
        nepriskirtas_pratestas: "Nepriskirtas pratęstas",
        buto_nr: 'Buto Nr.',
        namo_nr: 'Namo Nr.',
        papildoma_info: 'Papildoma informacija',
        open: 'Atidaryti',
        siusti: 'Siųsti',
        siusti_redaguoti: 'Redaguoti ir siųsti',
        patvirtinti: 'Patvirtinti',
        veiksmas: 'Veiksmas',
        uzsakymo_info: 'Užsakymo informacija',
        keisti_laika: 'Keisti laiką',
        asmuo: 'Asmuo',
        teisinis_statusas: 'Teisinis statusas',
        isvalyti: 'Išvalyti',
        pasirasyti: 'Pasirašyti',
        matas: 'Matas',
        sf_issiusta: 'Sąskaita faktūra išsiųsta',
        apmokejimo_tipas_pakeistas: 'Apmokėjimo tipas pakeistas',
        saskaitosZmogus: 'Gintaras Rabikis',
        ar_pradejote_darba: 'Ar jau atvykote pas klientą',
        ar_pabaigete_darba: 'Ar baigėte darbą',
        meistras: 'Meistras',
        buttonLink: 'URL nuoroda',
        buttonName: 'Pavadinimas',
        btnFields: 'Mygtuko sukūrimas',
        data_ijungimas: ' Data',
        paieska: 'Paieška',
        medziaguKaina: 'Medžiagų kaina',
        gridVertimas: [ltLTGrid]
    }
}

