

const grazintiIsArchyvo = async (path, token, what, id) => {
    return fetch(path + what + id, {
        method: 'PUT',
        // mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            data: { archyvuotas: false }
        })

    }).then((response) => {
        console.log('?', response.json());
        return response.json();
    }).catch((e) => { console.log(e); })
}
export default grazintiIsArchyvo