import { TextField } from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { TaskContext } from "../AppStates";
import { useEmail } from "../hooks";
import CustomButton from "./Mui/CustomButton";
import { suskaiciuotiSenus } from "./PDF/PVMSaskaitaFatktura";

const EmailBody = (props) => {
    const { token, path } = useContext(TaskContext)
    const [info, setInfo] = useState(props.info)
    const [id, setId] = useState(props.id)
    const [to, setTo] = useState(info.email)
    const [email, setEmail] = useState('jusu@ukvedziai.lt')
    const [subject, setSubject] = useState(props.subject)
    const [text, setText] = useState(props.text)
    const files = props.files
    const { sendEmail: sender } = useEmail()
    // const reader = new FileReader()
    // reader.readAsDataURL(files[0])
    // var urlCreator = window.URL || window.webkitURL;
    // const src = URL.createObjectURL(files[0]);;
    const siusti = async () => {
        try {
            var data = {
                data: {
                    to: [to, 'archyvas@ukvedziai.lt'],
                    from: email,
                    subject: subject,
                    html: text,
                    attachment: files[0].split(',')[1],
                    attachmentId: "PVM sąskaita faktūra " + "Serija [" + info.data[0].split('-')[0] + '] - ' + suskaiciuotiSenus(id, info.seni, info.IDNr) + '.pdf',
                }
            }
            console.log('emailas data', data)
            var fe = await sender(data)
            console.log(fe)
            props.setAlert()
            props.wasSent()
        }
        catch (e) { console.log(e) }

    }
    useEffect(() => {
        if (props.send) {
            siusti()
        }
    }, [])
    return (
        props.send ? '' :
            <div>
                <TextField
                    placeholder="from"
                    multiline
                    defaultValue={email}
                    onChange={value => { setEmail(value.target.value) }}
                />
                <TextField
                    placeholder="to"
                    defaultValue={to}
                    onChange={value => { setTo(value.target.value) }}

                    multiline />
                <TextField
                    defaultValue={subject}
                    onChange={value => { setSubject(value.target.value) }}

                    placeholder="subject"
                    multiline />
                <TextField
                    defaultValue={text}
                    onChange={value => { setText(value.target.value) }}
                    placeholder="text"
                    multiline />

                {/* <iframe src={src} /> */}
                <CustomButton
                    onClick={() => { siusti() }}
                    text='issiusti'
                />
            </div>
    )

}

export default EmailBody
