
export const atsaukimoEmail = (data, id) => {
    const to = data.email
    const subject = 'Iškvietimas atšauktas'
    const html = `Sveiki, 
    <br>
    <br>Jūsų iškvietimas Nr. ${data.data[0].slice(0, 4)}-${id} atšauktas.
    <br>Sėkmės namuose!

    <br>Praktiškai jūsų – 
    <br><a href="http://ukvedziai.lt" target="_blank">ŪKVEDŽIAI</a>`

    return {
        html,
        subject,
        to,
    }
}