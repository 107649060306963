import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { ButtonUnstyled, createStyles, Link, makeStyles } from '@mui/material';
import { t } from 'i18next';
import Delete from "../assets/delete.png";
import Edit from "../assets/edit.png";
import { fontWeight } from "@mui/system";
import MobileGridUser from "./MobileGridUsers";
import { useState } from "react";
import KategorijosNustatymas from "../functions/kategorijosNustatymas";
import Tooltip from "@mui/material/Tooltip";

const handleClick = (event, cellValues, props) => {
    console.log(props)
};

const handleCellClick = (param, event) => {
    event.stopPropagation();
};

const handleRowClick = (param, event) => {
    event.stopPropagation();
};

const columns = [
    { field: "id", headerName: "ID", width: 30, },
    { field: "name", headerName: "Vardas", width: 150, },
    // { field: "laikas", headerName: "Laikas", width: 200 },
    { field: "kategorija", headerName: "Pareigos", width: 150 },
    // {
    //     field: "statusas", headerName: "Statusas", width: 150,
    //     cellClassName: ({ value }) => value
    // },
    { field: "telNr", headerName: "Tel. nr.", width: 150 },
    {
        field: "subkategorija", headerName: "Kategorija", width: 200,
        renderCell: (cellValues) => {
            return (
                <div className="kategorijosImg">
                    {cellValues.row.subkategorija.map((kategorija) =>
                    (
                        <div key={kategorija} >
                            <Tooltip title={kategorija}>

                                <img
                                    className={kategorija + ' kategorijosImgVidus'}
                                    src={KategorijosNustatymas(kategorija)}
                                // onClick={(event) => {
                                //     cellValues.row.modal(cellValues.row.id)
                                // }}
                                />
                            </Tooltip>
                        </div>

                    ))}

                </div>
            )
        }
    },
    // { field: "elPastas", headerName: "El. paštas", width: 150 },

    {
        field: "Veiksmas", width: 100,
        sortable: false,
        filterable: false, hideable: false,
        showable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => {
            return (
                <div
                    className="deleteImg"
                    onClick={(event) => {
                        cellValues.row.modal(cellValues.row.id)
                    }}
                >
                    {/* {t('redaguoti')} */}
                    <img src={Edit} />
                </div>
            );
        }
    },
    {
        field: "Trinti", width: 100,
        sortable: false,
        filterable: false, hideable: false,
        showable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => {
            return (
                <div
                    className="deleteImg" onClick={(event) => {
                        cellValues.row.delete(cellValues.row.id)
                    }}
                >
                    {/* {cellValues.row.IDNr ? 'Redaguoti' : 'Priskirti'} */}
                    {/* {t('trinti')} */}
                    <img src={Delete} />
                </div>
            );
        }
    }
];
export default function DataGridDarbuotojai(props) {
    const rows = props.data
    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener('resize', () => {
        setWidth(window.innerWidth)

    });
    if (window.innerWidth < 1000) {
        return <MobileGridUser
            rows={rows}
        />
    }
    return (
        <div className="dataGrid" >
            <DataGrid
                autoHeight={true}
                rowHeight={70}
                className={'dataGrid'}
                rows={rows}
                columns={columns}
                pageSize={25}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'statusas', sort: 'asc' }],
                    },
                }}
            // checkboxSelection
            // onCellClick={handleCellClick}
            // onRowClick={handleRowClick}
            />
        </div>
    );
}


const makeDate = (date) => {
    return new Date(date).toISOString().split('T')[0]
}
const makeStatus = (status) => {
    let el = (<div className={status}>{status}</div>)
    console.log(el)
    return el
}