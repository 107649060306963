import { string } from "prop-types"
import Alert from '@mui/material/Alert';


export const usernameValidation = (TextField) => {
    if (/^(?!\s*$).+/.test(TextField)) {
        return true
    }
    return false
}

export const nameValidation = (TextField) => {
    if (/^(?!\s*$).+/.test(TextField)) {
        return true
    }
    return false
}

export const surnameValidation = (TextField) => {
    if (/^(?!\s*$).+/.test(TextField)) {
        return true
    }
    return false
}


export const emailValidation = (TextField) => {
    if (/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(TextField)) {
        return true
    }
    return false
}

export const emailEmptyValidation = (TextField) => {
    if (/^(?!\s*$).+/.test(TextField)) {
        return true
    }
    return false
}

export const numberValidation = (TextField) => {
    if (/^\+(?:[0-9]●?){6,14}[0-9]$/.test(TextField)) {
        return true
    }
    return false
}

export const numberEmptyValidation = (TextField) => {
    if (/^(?!\s*$).+/.test(TextField)) {
        return true
    }
    return false
}

export const passLenghtValidation = (TextField) => {
    if (TextField.length >= 6) {
        return true
    }
    return false
}

export const onlyNumbersValidation = (TextField) => {
    if (/^[0-9]*$/.test(TextField)) {
        return true
    }
    return false
}


export const ValidateUser = (info, tag) => {
    try {

        if (tag == "number") {
            if (!onlyNumbersValidation(info)) {
                // alert("Kiekį sudaro tik skaičiai")
                throw false
            }
            return true
        }

        if (!usernameValidation(info.username)) {
            alert("Įveskite vartotojo vardą")
            throw false
        }
        if (!nameValidation(info.Vardas)) {
            alert("Įveskite vardą")
            throw false
        }
        if (!surnameValidation(info.Pavarde)) {
            alert("Įveskite pavardę")
            throw false
        }
        if (!numberEmptyValidation(info.tel)) {
            alert("Įveskite telefono numerį")
            throw false
        }
        if (!numberValidation(info.tel)) {
            alert("Neteisingas telefono numeris")
            throw false

        }
        if (!emailEmptyValidation(info.email)) {
            alert("Įveskite el. paštą")
            throw false

        }
        if (!emailValidation(info.email)) {
            alert("Neteisingas el. paštas")
            throw false
        }
        if (info.pass && !passLenghtValidation(info.password)) {
            alert("Slaptažodį turi sudaryti mažiausiai 6 simboliai")
            throw false
        }

        // alert("atnaujinta")
        return true
    }
    catch (e) { console.log(e) }
}