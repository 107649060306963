import datetimeDifference from 'datetime-difference'
import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';
import CustomButton from '../Mui/CustomButton';
import { useTranslation } from 'react-i18next';

const Time = ({ info, setInfo, update, id, r }) => {

  const { t } = useTranslation()
  const [ticking, setTicking] = useState(null)
  const [pradirbtasLaikas, setPradirbtasLaikas] = useState('00:00:00')


  const makeTwo = (value) => {
    if (value)
      if (parseInt(value) < 10) { return '0' + value }
      else { return value }
    else { return '00' }
  }

  const laikrodukas = (start) => {
    const t = datetimeDifference(new Date(start), new Date())

    const time = {
      h: t.hours,
      min: t.minutes,
      sec: t.seconds,
      addSec: function () { return this.sec += 1 },
      addH: function () { this.h += 1; this.min = 0 },
      addMin: function () { this.min += 1; this.sec = 0 },
    }
    var tick = setInterval(() => {

      if (time.min > 59) {
        time.addH()
      }
      if (time.sec >= 59) {
        time.addMin()
      } else {
        time.addSec()
      }
      setPradirbtasLaikas(makeTwo(time.h) + ":" + makeTwo(time.min) + ":" + makeTwo(time.sec))

    }, 1000)
    setTicking(tick)
    // console.log(info)
    return (<div>{pradirbtasLaikas}</div>)
  }
  useEffect(() => {
    if (ticking) { clearInterval(ticking); }
    if (info?.pradirbtasLaikas?.pradėta && !info?.pradirbtasLaikas?.pabaigta) {
      laikrodukas(info.pradirbtasLaikas.pradėta)
    }
  }, [info?.pradirbtasLaikas])
  useEffect(() => {
    let t = ''
    if (info?.pradirbtasLaikas?.pradėta && info?.pradirbtasLaikas?.pabaigta)
      t = datetimeDifference(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta))
    let time = {
      h: t.hours,
      min: t.minutes,
      sec: t.seconds,
      addSec: function () { return this.sec += 1 },
      addMin: function () { this.min += 1; this.sec = 0 },
    }
    setPradirbtasLaikas(makeTwo(time.h) + ":" + makeTwo(time.min) + ":" + makeTwo(time.sec))

  }, [info])

  const [expand, setExpand] = useState(true)
  const [disabled, setDisabled] = useState(false)

  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

  useEffect(() => {
    if (info.statusas == t('vykdoma') || info.statusas == t('neapmoketa')) setExpand(true)
    else setExpand(false)
  }, [info.statusas])

  const handleChange = (newValue) => {
    setInfo({ ...info, pradirbtasLaikas: { pradėta: newValue, pabaigta: info.pradirbtasLaikas.pabaigta } });
  };

  const handleChange2 = (newValue) => {
    setInfo({ ...info, pradirbtasLaikas: { pabaigta: newValue, pradėta: info.pradirbtasLaikas.pradėta } });
  };
  return (
    <div className="modalCont modalContVidus">
      <div className="sectionCont">
        <div className="section">
          {expand ? <ExpandLessIcon
            sx={{ cursor: 'pointer' }}
            color='primary'
            onClick={() => {
              expand ? setExpand(false) : setExpand(true)
            }} /> :
            <ExpandMoreIcon
              sx={{ cursor: 'pointer' }}
              color='primary'
              onClick={() => {
                expand ? setExpand(false) : setExpand(true)
              }} />}
          <h4 className="sectionName">Laikas</h4>
        </div>

        <EditIcon
          sx={{ cursor: 'pointer' }}
          color='primary'
          onClick={() => {
            disabled ? setDisabled(false) : setDisabled(true)
          }} />


      </div>
      <div className={expand ? 'modalContVidus liveLaikas' : 'modalContVidus liveLaikas hidden'}>
        <div className={disabled ? 'column' : 'hidden'}>
          <div className=' gridColumn margin5'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                className='margin5'
                label="Pradžios laikas"
                value={dayjs(info?.pradirbtasLaikas?.pradėta)}
                onChange={handleChange}
                ampm={false}
                renderInput={(params) => <TextField  {...params} />}
              />
              <TimePicker
                className='margin5'
                label="Pabaigos laikas"
                value={dayjs(info?.pradirbtasLaikas?.pabaigta)}
                onChange={handleChange2}
                ampm={false}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className=' gridColumn margin5'>
            <CustomButton
              onClick={() => {
                update(id, info)
                setDisabled(true)
              }}
              text='issaugoti'
            />
            <CustomButton
              onClick={() => {
                update(id, { pradirbtasLaikas: {} })
              }}
              text='trinti'
              color='error'
            />
          </div>
        </div>
        {pradirbtasLaikas}

      </div>
    </div>

  )

}

export default Time