
import { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { TaskContext } from "./AppStates";
import Modal from 'react-modal';
import { t } from 'i18next';
import ham from "./assets/Menu1.png"
import iks from "./assets/iks.png"
import { useLocation } from 'react-router-dom';
import logout from "./assets/logout.png"
import Logo from "./assets/logo_ukv.svg";
import Log from "./assets/logo_u.png";
import axios from "axios";
import CustomButton from "./Components/Mui/CustomButton";

const Navigation = () => {
    const { user, tasks, setTasks, setToken, setUser, path, isLoading, setIsLoading, setType } = useContext(TaskContext)
    const mobileNav = useRef(null)
    const [open, setOpen] = useState(false);
    const closeMenu = () => setOpen(false);

    const location = useLocation();
    const [active, setActive] = useState('/')

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const handleShow = () => setModalIsOpen(true);
    const [info, setInfo] = useState({})

    const atsijungti = () => {
        document.cookie = "UkvedziuToken=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
        window.location = '/'
        setUser({})
    }
    useEffect(() => {
        setOpen(false)
        if (getCookie("UkvedziuToken")) {
            axios
                .get(path + 'users/me', {
                    headers: {
                        Authorization: `Bearer ${getCookie("UkvedziuToken")}`,
                    },
                })
                .then((response) => {
                    // Handle success.
                    setUser(response.data)

                    setToken(getCookie("UkvedziuToken"))
                    setTasks('loged')
                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    // Handle error.
                    console.log('An error occurred:', error.response);
                });
        } else { setIsLoading(false) }
        if (mobileNav.current)
            mobileNav.current.style.display = 'none';
    }, [])

    useEffect(() => {
        if (user.is_admin) { setType(0) }
        else if (user.is_buhaltere) { setType(1) }
        else { setType(2) }
    }, [user])




    useEffect(() => {
        setActive(window.location.pathname)
    }, [location]);
    useEffect(() => {
        if (mobileNav.current)
            mobileNav.current.style.display = open ? 'block' : 'none';

    }, [open]);

    return (
        <div className="navBar">
            {isLoading ?
                <div className="loader">
                    <img src={Log} alt="Logo" />   </div>
                : ''}
            <div className="ham"
                onClick={() => {
                    setOpen(!open)
                }}
            ><img src={ham} alt="Menu" /></div>

            <Link key={'Archyvas'} className={"navLogo"} to="/">{<img src={Logo} alt="Logo" />}</Link>



            {
                tasks == 'loged' ? (
                    user.is_admin || user.is_buhaltere ? (
                        <nav key='navigationConainer' className="navContainer" >
                            <Link key={'Darbai'} className={active == '/' ? 'active navItem' : 'navItem'} to="/">{t('Darbai')}</Link>
                            <Link key={'Archyvas'} className={active == '/archyvas' ? 'active navItem' : 'navItem'} to="/archyvas">{t('archyvas')}</Link>
                            <Link key={'Kablukas'} className={active == '/kablukas' ? 'active navItem' : 'navItem'} to="/kablukas">{t('kablukas')}</Link>
                            <Link key={'Kalendorius'} className={active == '/kalendorius' ? 'active navItem' : 'navItem'} to="/kalendorius">{t('kalendorius')}</Link>
                            <Link key={'Statistika'} className={active == '/Statistika' ? 'active navItem' : 'navItem'} to="/statistika">{t('Statistika')}</Link>
                            {
                                user.is_buhaltere ? '' :
                                    <>
                                        <Link key={'naujienos'} className={active == '/naujienos' ? 'active navItem' : 'navItem'} to="/naujienos">{t('naujienos')}</Link>
                                        <Link key={'produktai'} className={active == '/produktai' ? 'active navItem' : 'navItem'} to="/produktai">{t('produktai')}</Link>
                                    </>
                            }
                            <Link key={'darbuotojai'} className={active == '/darbuotojai' ? 'active navItem' : 'navItem'} to="/darbuotojai">{t('darbuotojai')}</Link>
                            {/* <a key={'atsijungti'} className="navItem" onClick={() => { atsijungti() }}>{t('atsijungti')}</a> */}
                            <img className="logout" src={logout} alt="lougout"
                                // onClick={() => { atsijungti() }}
                                onClick={() => { handleShow(); }}

                            />
                        </nav>
                    ) : (
                        <nav key='navigationConainer' className="navContainer" >
                            <Link key={'Darbai'} className={active == '/' ? 'active navItem' : 'navItem'} to="/">{t('Darbai')}</Link>
                            <Link key={'Kalendorius'} className={active == '/kalendorius' ? 'active navItem' : 'navItem'} to="/kalendorius">{t('kalendorius')}</Link>
                            <Link key={'Kablukas'} className={active == '/kablukas' ? 'active navItem' : 'navItem'} to="/kablukas">{t('kablukas')}</Link>
                            <Link key={'darbuotojai'} className={active == '/darbuotojai' ? 'active navItem' : 'navItem'} to="/darbuotojai">{t('darbuotojai')}</Link>
                            <img className="logout" src={logout} alt="lougout"
                                // onClick={() => { atsijungti() }}
                                onClick={() => { handleShow(); }}

                            />
                        </nav>

                    )
                ) : ('')
            }
            {
                tasks == 'loged' ? (
                    user.is_admin || user.is_buhaltere ? (
                        <nav key='navigationConainerMob' className="navContainerMob" ref={mobileNav} >
                            <div className="mobileClose"
                                onClick={() => {
                                    mobileNav.current.style.display = 'none';
                                }}>  <img src={iks} alt="Atgal" /></div>
                            <div className="mobileNavigationList">
                                <Link onClick={() => {
                                    mobileNav.current.style.display = 'none';
                                }} key={'Darbai'} className={active == '/' ? 'active navItem' : 'navItem'} to="/">{t('Darbai')} </Link>
                                <Link onClick={() => {
                                    mobileNav.current.style.display = 'none';
                                }} key={'Archyvas'} className={active == '/archyvas' ? 'active navItem' : 'navItem'} to="/archyvas">{t('archyvas')} </Link>
                                <Link
                                    onClick={() => {
                                        mobileNav.current.style.display = 'none';
                                    }}
                                    key={'Kablukas'} className={active == '/kablukas' ? 'active navItem' : 'navItem'} to="/kablukas">{t('kablukas')}</Link>
                                <Link onClick={() => {
                                    mobileNav.current.style.display = 'none';
                                }} key={'Kalendorius'} className={active == '/kalendorius' ? 'active navItem' : 'navItem'} to="/kalendorius">{t('kalendorius')}</Link>

                                {user.is_buhaltere ? '' :
                                <>
                                
                                    <Link onClick={() => {
                                        mobileNav.current.style.display = 'none';
                                    }} key={'naujienos'} className={active == '/naujienos' ? 'active navItem' : 'navItem'} to="/naujienos">{t('naujienos')}
                                    </Link>
                                    <Link onClick={() => {
                                        mobileNav.current.style.display = 'none';
                                    }} key={'produktai'} className={active == '/produktai' ? 'active navItem' : 'navItem'} to="/produktai">{t('produktai')}
                                    </Link>
                                </>
                                }
                                <Link onClick={() => {
                                    mobileNav.current.style.display = 'none';
                                }} key={'darbuotojai'} className={active == '/darbuotojai' ? 'active navItem' : 'navItem'} to="/darbuotojai">{t('darbuotojai')}</Link>
                                {/* <a key={'atsijungti'} className={active == '/' ? 'active navItem' : 'navItem'} onClick={() => { atsijungti() }}>{t('atsijungti')}</a> */}
                                <img className="logout" src={logout} alt="lougout" onClick={() => {
                                    mobileNav.current.style.display = 'none';
                                    handleShow();
                                }} />
                            </div>
                        </nav>
                    ) : (
                        <nav key='navigationConainerMob' className="navContainerMob" ref={mobileNav}>
                            <div className="mobileClose"
                                onClick={() => {
                                    mobileNav.current.style.display = 'none';
                                }}>  <img src={iks} alt="Atgal" /></div>
                            <div className="mobileNavigationList">
                                <Link onClick={() => {
                                    mobileNav.current.style.display = 'none';
                                }} key={'Darbai'} className={active == '/' ? 'active navItem' : 'navItem'} to="/">{t('Darbai')} </Link>
                                <Link onClick={() => {
                                    mobileNav.current.style.display = 'none';
                                }} key={'Kalendorius'} className={active == '/kalendorius' ? 'active navItem' : 'navItem'} to="/kalendorius">{t('kalendorius')}</Link>
                                <Link onClick={() => {
                                    mobileNav.current.style.display = 'none';
                                }} key={'darbuotojai'} className={active == '/darbuotojai' ? 'active navItem' : 'navItem'} to="/darbuotojai">{t('darbuotojai')}</Link>
                                <img className="logout" src={logout} alt="lougout" onClick={() => { handleShow(); }} />

                            </div>
                        </nav>

                    )
                ) : ('')
            }

            <Modal
                isOpen={modalIsOpen}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen(false) }}
            >
                <div
                    className="centerContainer1 column">


                    <button className="closeBtnMod" onClick={() => {
                        setModalIsOpen(false)
                    }}
                    >⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_tikrai_atsijungti')}?</h4>
                    </div>
                    <div  >

                        <CustomButton
                            onClick={() => { atsijungti(); setModalIsOpen(false) }}
                            text='taip' />
                    </div>
                </div>
            </Modal>

        </div >

    )
}

export default Navigation

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}