import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import CustomButton from '../Mui/CustomButton'
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import { TaskContext } from '../../AppStates';
import EmailuSiuntimas from '../EmeiluSiuntimas.js';
import { CheckStatus } from '../Meistras';


const SfInfo = ({ info, setInfo, update, id, r }) => {
    const { t } = useTranslation()
    const { type } = useContext(TaskContext)
    const [expand, setExpand] = useState(true)
    const [disabled, setDisabled] = useState(id ? true : false)

    useEffect(() => {
        if (type == 2) {
            if (CheckStatus(info?.statusas, [t('priskirtas'), t('nepriskirtas'), t('planuojama'), t('priskirtas_tesiamas'), t('nepriskirtas_pratestas')])) setExpand(true)
            else setExpand(false)
        }
    }, [info?.statusas])

    return (
        <>
            {

                <div className="modalCont modalContVidus">
                    <div className="sectionCont">
                        <div className="section">
                            {expand ? <ExpandLessIcon
                                sx={{ cursor: 'pointer' }}
                                color='primary'
                                onClick={() => {
                                    expand ? setExpand(false) : setExpand(true)
                                }} /> :
                                <ExpandMoreIcon
                                    sx={{ cursor: 'pointer' }}
                                    color='primary'
                                    onClick={() => {
                                        expand ? setExpand(false) : setExpand(true)
                                    }} />}
                            <h4 className="sectionName">{t('sf')}</h4>
                        </div>
                        {type == 2 || !id ?
                            '' :
                            <EditIcon
                                sx={{ cursor: 'pointer' }}
                                color='primary'
                                onClick={() => {
                                    disabled ? setDisabled(false) : setDisabled(true)
                                }} />
                        }
                    </div>
                    <div className={expand ? 'modalContVidus' : 'modalContVidus hidden'}>
                        <FormControl
                            disabled={disabled ? true : false}
                        >
                            <InputLabel color="primary">{t('teisinis_statusas')}</InputLabel>
                            <Select
                                color="primary"
                                size="small"
                                label="Teisinis statusas"
                                value={info?.uzsakovoTipas || t('asmuo')}
                                onChange={(value) => { setInfo({ ...info, uzsakovoTipas: value.target.value }) }}
                            >
                                <MenuItem key={t('fizinis_asmuo')} value={t('fizinis_asmuo')}>{t('fizinis_asmuo')}</MenuItem>
                                <MenuItem key={t('juridinis_asmuo')} value={t('juridinis_asmuo')}>{t('juridinis_asmuo')}</MenuItem>
                                <MenuItem key={t('asmuo')} value={t('asmuo')}>{t('asmuo')}</MenuItem>

                            </Select>
                            <FormHelperText />
                        </FormControl>
                        {info?.uzsakovoTipas == t('fizinis_asmuo') ?
                            <>
                                <TextField
                                    disabled={disabled ? true : false}
                                    key={'asmensAdresas'}
                                    label={t("asmensAdresas")}
                                    size="small"
                                    multiline
                                    value={info?.asmensAdresas ? info.asmensAdresas : ''}
                                    onChange={value => { setInfo({ ...info, asmensAdresas: value.target.value }) }}
                                />
                                <TextField
                                    disabled={disabled ? true : false}
                                    key={'asmensKodas'}
                                    label={t("asmensKodas")}
                                    size="small"
                                    maxRows={1}
                                    value={info?.asmensKodas ? info.asmensKodas : ''}
                                    onChange={value => { setInfo({ ...info, asmensKodas: value.target.value }) }}
                                />
                            </>
                            : ''}
                        {info?.uzsakovoTipas == t('juridinis_asmuo') ?
                            <>
                                <TextField
                                    disabled={disabled ? true : false}
                                    key={'imonesPavadinimas'}
                                    label={t("imonesPavadinimas")}
                                    size="small"
                                    maxRows={1}
                                    value={info?.imonesPavadinimas ? info.imonesPavadinimas : ''}
                                    onChange={value => { setInfo({ ...info, imonesPavadinimas: value.target.value }) }}
                                />
                                <TextField
                                    disabled={disabled ? true : false}
                                    key={'adresas'}
                                    label={t("adresas")}
                                    size="small"
                                    maxRows={1}
                                    multiline
                                    value={info?.imonesAdresas ? info.imonesAdresas : ''}
                                    onChange={value => { setInfo({ ...info, imonesAdresas: value.target.value }) }}
                                />
                                <TextField
                                    disabled={disabled ? true : false}
                                    key={'i_kodas'}
                                    label={t("i_kodas")}
                                    size="small"
                                    maxRows={1}
                                    value={info?.imonesKodas ? info.imonesKodas : ''}
                                    onChange={value => { setInfo({ ...info, imonesKodas: value.target.value }) }}
                                />
                                <TextField
                                    disabled={disabled ? true : false}
                                    key={'i_pvm'}
                                    label={t("i_pvm")}
                                    size="small"
                                    maxRows={1}
                                    value={info?.PVM ? info.PVM : ''}
                                    onChange={value => { setInfo({ ...info, PVM: value.target.value }) }}
                                />
                            </>
                            : ''}
                        {disabled ? '' :
                            <div className='column'>
                                {
                                    id ?
                                        <CustomButton
                                            className='marginV10'
                                            text='issaugoti'
                                            disabled={disabled ? true : false}
                                            onClick={() => {
                                                update(id, info)
                                                setDisabled(true)
                                            }}
                                        />
                                        : ''
                                }
                                <EmailuSiuntimas
                                    info={info}
                                    id={id}
                                    disabled={disabled ? true : false}
                                />
                            </div>
                        }
                    </div>
                </div>
            }

        </>
    )
}

export default SfInfo