import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#db643e',
        },
        secondary: {
            main: '#3D60DC',
        },
        background: {
            paper: '#f0eee7',
        },
        error: {
            main: '#BC2222',
        },
        grey: {
            main: '#73726F',
        },
        success:{
            main: '#1B792A',
        },
        action: {
            disabledBackground: '#A19F9B',
            disabled: '#73726F',
          },
    },
    typography: {
        fontFamily: "Roboto"
    }
})
