

import axios from "axios";



const ImportAll = async (path, token, what, setter, flag) => {
    axios
        .get(path + what, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            // console.log(response.data)
            if (flag === 'users') { setter(response.data); }
            else {
                if (setter)
                    setter(response.data.data)
            }
            return response.data.data
        })
        .catch((error) => {
            console.log('An error occurred:', error);
        });
}

export default ImportAll