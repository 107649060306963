import * as React from 'react';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { TaskContext } from "../AppStates";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { t } from 'i18next';
import { Checkbox, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import CustomButton from '../Components/Mui/CustomButton';

const Home = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { setTasks, setUser, setToken, token, path } = useContext(TaskContext)
    const [checked, setChecked] = useState(true)
    const [checked2, setChecked2] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const Login = () => {
        console.log(email)
        axios
            .post(path + 'auth/local', {
                identifier: email,
                password: password,
            })
            .then((response) => {

                // Handle success.
                // console.log('User profile', response.data.user);
                // console.log('User token', response.data.jwt);
                setUser(response.data.user)
                setToken(response.data.jwt)
                if (checked) {
                    document.cookie = "UkvedziuToken=" + response.data.jwt;
                }
                setTasks('loged')
            })
            .catch((error) => {
                // Handle error.
                console.log('An error occurred:', error);
                alert("El. paštas arba slaptažodis įvestas neteisingai.")

            });
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const togglePasswordVisiblity2 = () => {
        setPasswordShown2(passwordShown2 ? false : true);
    };

    useEffect(() => {
        if (getCookie("UkvedziuToken")) {
            axios
                .get(path + 'users/me', {
                    headers: {
                        Authorization: `Bearer ${getCookie("UkvedziuToken")}`,
                    },
                })
                .then((response) => {
                    // Handle success.
                    setUser(response.data)
                    setToken(getCookie("UkvedziuToken"))
                    setTasks('loged')
                })
                .catch((error) => {
                    // Handle error.
                    console.log('An error occurred:', error.response);
                });
        }
    }, [])


    return (
        <div className='homeLoginLent'>
            <div className='lentele'>
                <div className='prisijungimasLabel'>
                    <h3>Prisijungimas</h3>
                </div>
                {/* <form onSubmit={(e) => {
                    e.preventDefault();
                    Login()
                }}> */}

                    <TextField
                        color="primary"
                        variant="outlined"
                        type="text"
                        label={t('login_email')}
                        size="small"
                        margin="none"
                        onChange={(target) => {
                            setEmail(target.target.value)
                        }}
                        className='marginV5 width100'
                    />

                    <FormControl className='marginV5 width100' variant="outlined">
                        {/* <InputLabel htmlFor="outlined-adornment-password">{t('login_pass')}</InputLabel> */}
                        <OutlinedInput
                            onChange={(target) => { setPassword(target.target.value) }}
                            defaultValue={password}
                            size='small'
                            color='primary'
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            placeholder={t('login_pass')}
                        />
                    </FormControl>
                    {/* <label htmlFor="prisiminti" className='checkbox'>
                    <input className='prisiminti' type={'checkbox'} name="prisiminti"
                        checked={checked}
                        
                        onChange={() => { setChecked(!checked); }}
                    />Prisiminti
                </label> */}
                        <div className='row remember'>
                            <Checkbox
                                key={'prisiminti'}
                                label={t('prisiminti')}
                                // multiline
                                checked={checked}
                                onClick={() => { setChecked(!checked); }}
                            />
                            <label>{t('prisiminti')}</label>
                        </div>
                    <CustomButton
                    text='prisijungti'
                    className='width100 marginV10'
                    onClick={(e)=>{
                        e.preventDefault();
                        Login()
                    }}
                    />
                    {/* <input type="submit" value={t('prisijungti')} className='btn primary' />
                </form> */}

            </div>
        </div>
    )
}

export default Home

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}