import React, { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { t } from 'i18next';

export function ImagesAdd({ count, setter, imagesArr }) {
  const [images, setImages] = useState([]);
  const maxNumber = count;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // setImages(imageList);
    setter(imageList)
    console.log(imageList)
  };
  useEffect(() => {
    setImages(imagesArr)

  }, [])

  return (
    <div className='addPhoto'>
      <ImageUploading
        // single
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            {/* <button className='btn primary btnNarrow'
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              {t('dropImg')}
            </button> */}
            <div
              className="containerPht">
              <div
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
                className="prideti">
                <div className="nieko"
                  onClick={onImageUpload}
                ></div>
              </div>
              <button
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
                className="deletePht">{t('pridėti')}</button>
            </div>
            &nbsp;
          </div>

        )}
      </ImageUploading>
    </div>
  );
}