import React, { useContext, useEffect, useState } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CustomButton from '../Mui/CustomButton';
import { useTranslation } from 'react-i18next';
import PdfBuilder from '../PDF/PVMSaskaitaFatktura';
import Pratesimas from '../../functions/pratesimas';
import { TaskContext } from '../../AppStates';
import EmailBody from '../EmailBody';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { TikLaikas, timeLapse } from './Price';
import DefaultModal from '../Modal';



const Payment = ({ info, setInfo, id, update, darbuotojas, r }) => {
  const { t } = useTranslation()
  const { path, token } = useContext(TaskContext)
  const [expand, setExpand] = useState(true)
  const [value, setValue] = useState('')
  const [pdfRender, setPdfRender] = useState(null)
  const [emailText, setEmailText] = useState('')
  const [emailSend, setEmailSend] = useState(false)
  const [subject, setSubject] = useState('')
  const [send, setSend] = useState(false)
  const [alert, setAlert] = useState(false)
  const [modal, setModal] = useState(false)
  const [arSiunciam, setArSiunciam] = useState(false)
  const siustiEmail = async (text, tipas = 'siusti', subject = "Ukvedžiai - sąskaita faktūra") => {
    var arr = info.pradirbtasLaikas.pabaigta ? [info.pradirbtasLaikas.pradėta, info.pradirbtasLaikas.pabaigta] : [new Date(), new Date()]

    var x = await PdfBuilder
      (
        info, id, t, darbuotojas,
        TikLaikas(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis')),
        arr,
        tipas
      )
    if (tipas == 'siusti') {
      setPdfRender(x)
      console.log('emailas pdf', x)
      setEmailSend(true)
      setEmailText(text)
      setSubject(subject)
      setSend(true)
    }
  }

  const issiuntimasPavedimu = (tipas) => {
    var textEmailPavedimu = `Sveiki,
        <br>
        <br>ačiū, kad kviečiate.
        <br> Siunčiame sąskaitą-faktūrą (kurią apmokėti kviečiame per 3 darbo dienas) ir linkime sėkmės namuose!
        <br>
        <br>Praktiškai jūsų – 
        <br><a href="http://ukvedziai.lt" target="_blank">ŪKVEDŽIAI</a>
        `
    siustiEmail(textEmailPavedimu, tipas, 'Tikimės, padėjome!',)
  }

  const issiuntimasKortele = (tipas) => {
    var textEmailKortele = `Sveiki,
                                    <br>
                                    <br>ačiū, kad kviečiate.
                                    <br>Siunčiame sąskaitą-faktūrą ir linkime sėkmės namuose!
                                    <br>
                                    <br>Praktiškai jūsų – 
                                    <br><a href="http://ukvedziai.lt" target="_blank">ŪKVEDŽIAI</a>
                                    `
    siustiEmail(textEmailKortele, tipas, 'Tikimės, padėjome!',)
    //text, tipas = 'siusti', subject = "Ukvedžiai - sąskaita faktūra"
  }
  const pratesti = async (id, statusas) => { await Pratesimas(path, token, 'seni-d/', 'darbai/' + id, setInfo, info, id, statusas); r() }


  const handleUpdate = (tipas) => {
    switch (value) {
      case t('apmoketa'):
        issiuntimasKortele(tipas);
        update(id, { statusas: value })
        break
      case t('pavedimu'):
        issiuntimasPavedimu(tipas); update(id, { statusas: value });
        break
      case t('planuojama'):
        issiuntimasKortele(tipas); update(id, { statusas: value }); pratesti(id, t('apmoketa'));
        break
      case t('pavedimu_pratestas'):
        issiuntimasPavedimu(tipas);
        update(id, { statusas: value }); //pratesti(id, t('apmoketa'));
        break
      default:
        return;
    }
  }
  const handleChanges = (event) => {
    setValue(event.target.value)
  };
  useEffect(() => { setValue(info.statusas) }, [info])

  if (!info.parasas || !info?.parasas?.data) {
    return (
      <div>
      </div>
    )
  }
  return (
    <div className="modalCont modalContVidus">
      <div className="sectionCont">
        <div className="section">
          {expand ? <ExpandLessIcon
            sx={{ cursor: 'pointer' }}
            color='primary'
            onClick={() => {
              expand ? setExpand(false) : setExpand(true)
            }} /> :
            <ExpandMoreIcon
              sx={{ cursor: 'pointer' }}
              color='primary'
              onClick={() => {
                expand ? setExpand(false) : setExpand(true)
              }} />}
          <h4 className="sectionName">Apmokėjimas</h4>
        </div>
      </div>
      <div className={expand ? 'modalContVidus' : 'modalContVidus hidden'}>
        <FormControl>
          <RadioGroup
            value={value}
            onChange={handleChanges}
            name="controlled-radio-buttons-group"
            aria-labelledby="demo-controlled-radio-buttons-group"
          >
            <FormControlLabel className='row justifyStart' value={t('apmoketa')} control={<Radio />} label={t('kortele')} />
            <FormControlLabel className='row justifyStart' value={t('pavedimu')} control={<Radio />} label={t('pervedimu')} />
            <FormControlLabel className='row justifyStart' value={t('planuojama')} control={<Radio />} label={t('kortele_pratesimas')} />
            <FormControlLabel className='row justifyStart' value={t('pavedimu_pratestas')} control={<Radio />} label={t('pavedimu_pratestas')} />
          </RadioGroup>
        </FormControl>
        <CustomButton
          color='success'
          text='patvirtinti'
          onClick={() => {
            setModal(true)
          }}
        />
      </div>
      {
        <DefaultModal
          isOpen={modal}
          requestClose={() => { setModal(false) }}
          classnames={'modal3'}
          element={<div>
            <h4>{t('ar_siust_sf')}</h4>
            <div className='modalBtnRow'>
              <CustomButton
                text='taip'
                onClick={() => {
                  setAlert(true)
                  setTimeout(() => { setAlert(false) }, 3000)
                  setExpand(false)
                  handleUpdate('siusti');
                  setModal(false)
                  setArSiunciam(true)

                }} />
              <CustomButton
                text='ne'
                onClick={() => {
                  setAlert(true)
                  setTimeout(() => { setAlert(false) }, 3000)
                  setExpand(false);
                  handleUpdate('output');
                  setModal(false)
                  setArSiunciam(false)
                }} />
            </div>
          </div>}


        />
      }
      {emailSend ?
        <EmailBody
          close={() => { setEmailSend(false) }}
          info={info}
          files={[pdfRender]}
          subject={subject}
          text={emailText}
          id={id}
          editible={false}
          send={true}
          wasSent={() => { setSend(false); setEmailSend(false) }}
          setAlert={() => { }}
        />
        : ''}
      <Collapse in={alert}>
        <Alert
          variant="outlined"
          severity="success"
        >
          {arSiunciam ? t('sf_issiusta') : t('apmokejimo_tipas_pakeistas')}
        </Alert>
      </Collapse>
    </div>
  )
}

export default Payment
