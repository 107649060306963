import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { TaskContext } from "../AppStates";

const Main = () => {
    const {token,path}=useContext(TaskContext)
    const [data,setData]=useState([])

    
    return (
        <div className='lentele'>
          Pradzia

        </div>
    )
}

export default Main 

    // gali prireikti
    // useEffect(()=>{
    //     axios
    //     .get(path + 'naujienos', {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })
    //     .then((response) => {
    //     console.log(response.data)     
    //     setData(response.data)
    //     })
    //     .catch((error) => {
    //         // Handle error.
    //         console.log('An error occurred:', error.response);
    //     });
    // },[])