import { TextField } from '@mui/material'
import React, { useEffect, useState, useContext, TaskContext } from 'react'
import CustomTextField from '../Mui/CustomTextField'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';
import CustomButton from '../Mui/CustomButton';

const Comments = ({ info, setInfo, update, id, r }) => {
  const [expand, setExpand] = useState(true)
  const [disabled, setDisabled] = useState(id ? true : false)


  return (
    <div className="modalCont modalContVidus">
      <div className="sectionCont">
        <div className="section">
          {expand ? <ExpandLessIcon
            sx={{ cursor: 'pointer' }}
            color='primary'
            onClick={() => {
              expand ? setExpand(false) : setExpand(true)
            }} /> :
            <ExpandMoreIcon
              sx={{ cursor: 'pointer' }}
              color='primary'
              onClick={() => {
                expand ? setExpand(false) : setExpand(true)
              }} />}
          <h4 className="sectionName">Komentarai</h4>
        </div>
        {!id ?'':
        <EditIcon
          sx={{ cursor: 'pointer' }}
          color='primary'
          onClick={() => {
            disabled ? setDisabled(false) : setDisabled(true)
          }} />
        }
      </div>
      <div className={expand ? 'modalContVidus' : 'modalContVidus hidden'}>
        <TextField
          disabled={disabled ? true : false}
          color="primary"
          variant="outlined"
          multiline
          label="Problema"
          size='small'
          value={info?.uzrasine ? info.uzrasine : ''}
          onChange={value => { setInfo({ ...info, uzrasine: value.target.value }) }}
        />
        {disabled || !id ? '' :
          <CustomButton
            text='issaugoti'
            disabled={disabled ? true : false}
            onClick={() => {
              update(id, info)
              setDisabled(true)
            }}
          />
        }
      </div>
    </div>


  )
}

export default Comments