

import { useEffect } from "react";
import { useContext, useState } from "react";
import TaskContextProvider, { TaskContext } from "../AppStates";
import importAll from "../functions/importAll";
import DataGridAdmin from "../Components/DataGridAdmin";
import ArchiveModal from "../Components/ModalArchive";
import Modal from 'react-modal';
import { t } from 'i18next';
import LoadingTimer from "../functions/LoadingOn";
import { TextField } from "@mui/material";
import Archyvuoti from "../functions/archyvuoti";
import grazintiIsArchyvo from "../functions/grazintiIsArchyvo";
import ModalSeni from "../Components/ModalSeni";
import Search from "../assets/search.png";
import { useStrapiObjectClass } from "../hooks/useStrapiObject";
import CustomButton from "../Components/Mui/CustomButton";
import DefaultModal from "../Components/Modal";
import { useUser } from "../hooks/useUser";



const Archyvas = () => {
    const { token, user, path } = useContext(TaskContext)
    const [seni, setSeni] = useState([])
    const [bendras, setBendras] = useState([])
    const [loading, setLoading] = useState(true)
    const [sortBy, setSortBy] = useState(0)
    const [tvarkyta, setTvarkya] = useState([])
    const [r, setR] = useState([])
    const [filter, setFilteris] = useState('')
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [openSearch, setOpenSearch] = useState(false)
    const [id, setId] = useState('')

    const { data: darbai, getAll: getAll, DELETE } = useStrapiObjectClass({ name: 'darbai', })
    const [deleteModal, setDeleteModal] = useState(false)
    const [archyveModal, setArchyveModal] = useState(false)

    const { users: useriai, getAllUsers } = useUser()



    useEffect(() => {
        getAll();
        getAllUsers()
    }, [])
    useEffect(() => {
        if (darbai.length > 0) setBendras(darbai.concat(seni));
    }, [darbai, seni])
    useEffect(() => { redoData(bendras, setTvarkya); }, [bendras, filter])
    useEffect(() => { getAll(); getAllUsers() }, [r])
    const getVardas = (element) => {
        let id = element.attributes.darbuotojoId
        let vardas = ''
        useriai.map(darbuotojas => {
            if (darbuotojas.id == id) {
                vardas = darbuotojas.Pavarde
                return vardas
            }
        })
        return vardas
    }
    const redoData = async (bendras, setter) => {//data for dataGrid
        var arr = []
        bendras = filtruoti(bendras)
        bendras.forEach(element => {
            if (!user.is_admin && !user.is_buhaltere) {
                if (element.attributes.darbuotojoId != user.id) {
                    return
                }
            }
            if (!element.attributes.IDNr && element.attributes.archyvuotas == false || element.attributes.archyvuotas == null) {
                return
            }
            let vardas = getVardas(element) || ''

            arr.push(
                {
                    id: element.attributes.IDNr ? element.attributes.IDNr : element.id,
                    seni: element.attributes.seni ? element.attributes.seni : [],
                    data: element.attributes.data[0],
                    // data: element.attributes.data[0] ? makeDate(element.attributes.data) + ' ' + element.attributes.Laikas : '',
                    laikas: element.attributes.Laikas[0] ? element.attributes.Laikas : '',
                    adresas: element.attributes.adress,
                    tel: element.attributes.tel,
                    kategorija: element.attributes.kategorija,
                    darbuotojoId: vardas,

                    statusas: element.attributes.statusas,
                    IDNr: element.attributes.IDNr,
                    modal: (id) => {
                        if (element.attributes.IDNr) { setModal2(true); } else { setModal(true) }
                        setId(element.id);
                    },
                    delete: (id) => { setDeleteModal(true); setId(element.id) },
                    archyvui: (id) => { if (user.is_admin || user.is_buhalere && !element.attributes.IDNr) { setArchyveModal(true); setId(element.id) } }

                }
            )
        });
        setter(arr)
        return arr
    }
    const filtruoti = (arr) => {
        var ats = []
        if (filter) {
            arr.forEach(e => {
                e.id = e.id + ''
                if (e.id.toUpperCase().includes(filter.toUpperCase()) || e.attributes.data[0] && e.attributes.data[0].replaceAll('-', '.').toUpperCase().includes(filter.toUpperCase()) || e.attributes.statusas.toUpperCase().includes(filter.toUpperCase())) {
                    ats.push(e)
                }
            })
            return ats
        } else {
            return arr
        }
    }
    return (
        <div className='container'>
            <LoadingTimer />
            {bendras.length == 0 ? (
                <div></div>
            ) : (
                <div className='archyvas mainarch archyvuotiWidth'>
                    <div className="dataGridSearch">
                        <img
                            onClick={() => { openSearch ? setOpenSearch(false) : setOpenSearch(true) }}
                            src={Search} />
                        {
                            openSearch ?
                                <TextField
                                    key={'filtras'}
                                    label={t('paieska')}
                                    // multiline
                                    maxRows={1}
                                    size='small'
                                    className="marginH10"
                                    value={filter}
                                    onChange={(value) => { setFilteris(value.target.value) }}
                                />
                                : ''
                        }
                    </div>
                    <DataGridAdmin data={tvarkyta} user={user} />
                </div>
            )}
            {modal ?
                <ArchiveModal id={id} setIsOpen={setModal} modalIsOpen={modal} r={r} setR={() => setR(r + 1)} />
                :
                ''
            }
            {modal2 ?
                <ModalSeni id={id} setIsOpen={setModal2} modalIsOpen={modal2} path={path} token={token} r={r} setR={() => setR(r + 1)} />
                :
                ''
            }



            <Modal
                isOpen={archyveModal}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setArchyveModal(false) }}
            >
                <div
                    className="centerContainer1 column">


                    <button className="closeBtnMod" onClick={() => {
                        setArchyveModal(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_tikrai_isarchyvuoti')}?</h4>
                    </div>
                    <div  >

                        <CustomButton
                            onClick={async () => {
                                await grazintiIsArchyvo(path, token, 'darbai/', id);
                                await getAll()
                                setArchyveModal(false); setR(r + 1);
                            }}
                            text='taip'
                        />
                    </div>
                </div>
            </Modal>
            <DefaultModal
                isOpen={deleteModal}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setDeleteModal(false) }}
                classnames={'modal3'}
                element={
                    <div
                        className="centerContainer1 column">

                        <button className="closeBtn" onClick={() => {
                            setDeleteModal(false)
                        }}>⨉</button>

                        <div className="modalInfo" >
                            <h4>{t('ar_tikrai_istrinti')}</h4>
                        </div>
                        <div  >

                            <CustomButton
                                onClick={async () => {
                                    DELETE(id)
                                    setDeleteModal(false); setR(r + 1);
                                }}
                                text='taip'
                            />
                        </div>
                    </div>
                }
            />
            {/* <Modal
                isOpen={deleteModal}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setDeleteModal(false) }}
            >
                <div
                    className="centerContainer1 column">


                    <button className="closeBtn" onClick={() => {
                        setDeleteModal(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_tikrai_istrinti')}</h4>
                    </div>
                    <div  >

                        <CustomButton
                            onClick={async () => {
                                DELETE(id)
                                setDeleteModal(false); setR(r + 1);
                            }}
                            text='taip'
                        />
                    </div>
                </div>
            </Modal> */}


        </div>
    )
}
export default Archyvas


const sorting = (item, i) => {
    let start = item.slice(0)
    var arr
    if (i == 0) {
        arr = item.sort((a, b) => parseInt(a.id) > parseInt(b.id) ? 1 : -1)
        return arr
    }
    else if (item != start) {
        console.log('!=', item, start)

        arr = item.sort((a, b) => a.attributes[[weNeed.tags[i]]] > b.attributes[[weNeed.tags[i]]] ? 1 : -1)
        return arr
    }
    else {
        console.log('else', item, start)
        arr = item.sort((a, b) => a.attributes[[weNeed.tags[i]]] < b.attributes[[weNeed.tags[i]]] ? 1 : -1)
        return arr
    }
}

const weNeed = {
    names: ['ID', 'Data', 'Laikas', 'Kategorija', 'Statusas', 'Veiksmas'],
    tags: ['id', 'data', 'Laikas', 'kategorija', 'statusas', 'IDNr']
}

const makeDate = (date) => {
    // console.log(date)
    return new Date(date).toISOString().split('T')[0].replace('-', '.').replace('-', '.')
}