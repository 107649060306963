import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
// import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ButtonUnstyled, Link } from '@mui/material';
import KategorijosNustatymas from "../functions/kategorijosNustatymas";
import { useContext } from "react";
import { TaskContext } from "../AppStates";
import Delete from "../assets/delete.png";
import Edit from "../assets/edit.png";
import MobileGridKablukas from "./MobileGridKablukas";
import { useState } from "react";
import CustomButton from "./Mui/CustomButton";

// const useStyles = makeStyles((theme) =>
//     createStyles({
//         root: {}
//     })
// );

const handleClick = (event, cellValues, props) => {
    console.log(props)
    // console.log(cellValues.row);
};

const handleCellClick = (param, event) => {
    event.stopPropagation();
};

const handleRowClick = (param, event) => {
    event.stopPropagation();
};
const columns = [
    { field: "id", headerName: "ID", width: 30 },
    { field: "Numeris", headerName: "Numeris", width: 130 },
    { field: "darbuotojoId", headerName: "Darbuotojas", width: 130 },
    // { field: "kategorija", headerName: "Kategorija", width: 200 },
    {
        field: "statusas", headerName: "Statusas", width: 130,
        cellClassName: ({ value }) => value
    },
    {
        field: "Veiksmas", width: 100,
        sortable: false,
        filterable: false, hideable: false,
        showable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => {
            return (
                <div
                    className="deleteImg"
                    onClick={(event) => {
                        cellValues.row.modal(cellValues.row.id, cellValues.row.kategorija)
                    }}
                >
                    {/* {cellValues.row.IDNr ? 'Redaguoti' : 'Priskirti'} */}
                    <img src={Edit} />
                </div>
            );
        }
    },
    {
        field: "Trinti", width: 100,
        sortable: false,
        filterable: false, hideable: false,
        showable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => {
            return (
                <div
                    className="deleteImg"
                    onClick={(event) => {
                        cellValues.row.delete(cellValues.row.id)
                    }}
                >
                    {/* {cellValues.row.IDNr ? 'Redaguoti' : 'Priskirti'} */}
                    <img src={Delete} />
                </div>
            );
        }
    }
];
const columnsMaster = [
    // { field: "kategorija", headerName: "Kategorija", width: 200 },
    {
        field: "Veiksmas", width: 200,
        renderCell: (cellValues) => {
            return (
                <CustomButton
                    onClick={(event) => {
                        cellValues.row.modal(cellValues.row.id, cellValues.row.kategorija)
                    }}
                    text='Master'
               />
               
            );
        }
        // },
        // {
        //     field: "Trinti", width: 200,
        //     renderCell: (cellValues) => {
        //         return (
        //             <button
        //                 className="primary btn"
        //                 onClick={(event) => {
        //                     cellValues.row.delete(cellValues.row.id)
        //                 }}
        //             >
        //                 {/* {cellValues.row.IDNr ? 'Redaguoti' : 'Priskirti'} */}
        //                 Istrinti
        //             </button>
        //         );
        //     }
    }
];
export default function DataGridAdmin({ data, dataMaster }) {
    const { user } = useContext(TaskContext)
    const rowsMaster = dataMaster
    const rows = data
    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener('resize', () => {
        setWidth(window.innerWidth)

    });
    if (window.innerWidth < 1000) {
        return <MobileGridKablukas
            rows={rows}
        />
    }

    return (<div>


        <div className="dataGrid kablukasGrid">
            <DataGrid
                autoHeight={true}
                rowHeight={70}
                className={'dataGrid'}
                rows={rows}
                columns={columns}
                pageSize={25}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'statusas', sort: 'asc' }],
                    },
                }}

            />
        </div>
    </div>
    );
}


const makeDate = (date) => {
    return new Date(date).toISOString().split('T')[0]
}
const makeStatus = (status) => {
    let el = (<div className={status}>{status}</div>)
    console.log(el)
    return el
}