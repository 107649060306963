import { Autocomplete, TextField } from "@mui/material"
import { useContext } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { TaskContext } from "../AppStates"
import Update from "../functions/update"
import MobileGrid from "./MobileFlexTable"
import { Loader } from "./Loader"
import CustomButton from "./Mui/CustomButton"
const MedziaguPicker = ({ info, darbas, updateDarbas, close }) => {
    const { t } = useTranslation()
    const [kablukas, setKablukas] = useState({})
    const [filter, setFilter] = useState('')
    const [transfer, setTransfer] = useState({})
    const [panaudotos, setPanaudotos] = useState({})
    const [r, setR] = useState(0)
    const { path, token } = useContext(TaskContext)
    useEffect(() => {
        setKablukas(info)
        if (darbas.attributes.medziagos) {
            setPanaudotos(darbas.attributes.medziagos)
        }
    }, [info, darbas])
    const update = async (id, data) => { await Update(path, token, 'kablukas/', id, data, setKablukas); }

    const isnotin = (values) => {
        if (values.pavadinimas.toUpperCase().includes(filter.toUpperCase())) {
            return values
        }

    }
    console.log(info, darbas)
    if (!kablukas?.attributes || Object.values(kablukas).length == 0) {
        return (t("Nepriskirtas_kablukas"))
    }
    return (
        <div className="medziagu">
            {kablukas ?
                <div className="column">
                    <h4>{t('kablukas')} - {kablukas.attributes?.Numeris}</h4>
                    <div className="row">
                        <TextField
                            className='filter'
                            key={'filter'}
                            label={t('filter')}
                            onChange={(value) => { setFilter(value.target.value) }}
                            maxRows={1}
                            value={filter}
                            size='small'
                        />
                        <button className="pliusMinus" onClick={() => { setFilter('') }}>⨉</button>
                    </div>
                    <div className="medziaguGrid">



                        <div className="kablukoMedziagos ">
                            <div className=" medziaguRow mobileHidden">

                                <p className="th ">{t('medziaga')}</p>
                                {/* <p className="th ">{t('mato_vienetas')}</p> */}
                                <p className="th ">{t('kaina')}</p>
                                <p className="th ">{t('Kiekis')}</p>
                            </div>
                            {kablukas.attributes.medziagos.filter(isnotin).map((e, i) => (
                                <div key={e.pavadinimas + '-row'} className=" medziaguRow">
                                    <div className="pavadinimai mobileHidden">
                                        <p className="td2">{e.pavadinimas}</p>
                                        {/* <p className="td">{kablukas.attributes.vienetai[i]} {e.mato_vienetas}</p> */}
                                        <p className="td">{e.kaina} €</p>
                                        <p className="td">{kablukas.attributes.vienetai[i]} {e.mato_vienetas}</p>
                                    </div>

                                    <div className=' kablukoMedziagosMobile mobileVisible'>{kablukas.attributes?.medziagos ?
                                        <MobileGrid isdestymas={['pavadinimas', 'vienetai', 'kaina']} single={true} info={{ ...e, vienetai: kablukas.attributes.vienetai[i] }}
                                        /> : t('nera')}</div>
                                    <div className="row">
                                        <button
                                            className="pliusMinus"
                                            disabled={transfer[e.pavadinimas] && transfer[e.pavadinimas].kiekis >= 1 ? false : true}
                                            onClick={() => {
                                                if (transfer[e.pavadinimas]) {
                                                    setTransfer({
                                                        ...transfer,
                                                        [e.pavadinimas]: {
                                                            pavadinimas: e.pavadinimas,
                                                            mato_vienetas: e.mato_vienetas,
                                                            kaina: e.kaina,
                                                            kiekis: transfer[e.pavadinimas].kiekis - 1
                                                        }
                                                    })
                                                }
                                                else {
                                                    setTransfer({
                                                        ...transfer,
                                                        [e.pavadinimas]: {
                                                            pavadinimas: e.pavadinimas,
                                                            mato_vienetas: e.mato_vienetas,
                                                            kaina: e.kaina,
                                                            kiekis: 1
                                                        }
                                                    })
                                                }
                                                setPanaudotos({
                                                    ...panaudotos,
                                                    [e.pavadinimas]: {
                                                        pavadinimas: e.pavadinimas,
                                                        kaina: e.kaina,
                                                        mato_vienetas: e.mato_vienetas,
                                                        kiekis: panaudotos[e.pavadinimas] ? panaudotos[e.pavadinimas].kiekis - 1 : transfer[e.pavadinimas].kiekis,
                                                    }
                                                }
                                                )
                                                kablukas.attributes.vienetai[i] = panaudotos[e.pavadinimas] ? kablukas.attributes.vienetai[i] + 1 : kablukas.attributes.vienetai[i] + 1
                                                if (transfer[e.pavadinimas].kiekis == 1 && panaudotos[e.pavadinimas]) {
                                                    let obj = panaudotos
                                                    delete obj[e.pavadinimas]
                                                    setPanaudotos(obj)
                                                    setR(r + 1)
                                                }
                                            }}
                                        >−</button>

                                        <TextField
                                            disabled
                                            value={transfer[e.pavadinimas] ? transfer[e.pavadinimas].kiekis : 0}
                                        />

                                        <button
                                            className="pliusMinus"
                                            disabled={kablukas.attributes.vienetai[i] <= 0 ? true : false}
                                            onClick={() => {
                                                if (transfer[e.pavadinimas]) {
                                                    setTransfer({
                                                        ...transfer,
                                                        [e.pavadinimas]: {
                                                            pavadinimas: e.pavadinimas,
                                                            mato_vienetas: e.mato_vienetas,
                                                            kaina: e.kaina,
                                                            kiekis: transfer[e.pavadinimas].kiekis + 1
                                                        }
                                                    })
                                                }
                                                else {
                                                    setTransfer({
                                                        ...transfer,
                                                        [e.pavadinimas]: {
                                                            pavadinimas: e.pavadinimas,
                                                            mato_vienetas: e.mato_vienetas,
                                                            kaina: e.kaina,
                                                            kiekis: 1
                                                        }
                                                    })
                                                }
                                                setPanaudotos({
                                                    ...panaudotos,
                                                    [e.pavadinimas]: {
                                                        pavadinimas: e.pavadinimas,
                                                        kaina: e.kaina,
                                                        mato_vienetas: e.mato_vienetas,
                                                        kiekis: panaudotos[e.pavadinimas] ? panaudotos[e.pavadinimas].kiekis + 1 : 1,
                                                    }
                                                }
                                                )
                                                kablukas.attributes.vienetai[i] = panaudotos[e.pavadinimas] ? kablukas.attributes.vienetai[i] - 1 : kablukas.attributes.vienetai[i] - 1
                                            }}
                                        >+</button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <Single
                            e={{}}
                            kablukas={kablukas}
                            setTransfer={setTransfer}
                            transfer={transfer}
                            panaudotos={panaudotos}
                            setPanaudotos={setPanaudotos}
                            t={t}
                        />



                        <h4>{t('pasirinktosMedziagos')}</h4>
                        <div className="panaudotos">
                            {Object.keys(panaudotos).length > 0 ?
                                Object.keys(panaudotos).map((e, i) => {
                                    return (


                                        <div key={e + '-row-panaudotos'} className=" medziaguRow">
                                            <div className="pavadinimai mobileHidden">
                                                <p className="td4">{panaudotos[e].pavadinimas}</p>
                                                <p className="td3">{panaudotos[e].kiekis} {panaudotos[e].mato_vienetas}</p>
                                                <p className="td3">{panaudotos[e].kaina}</p>
                                                {/* <p className="td"></p> */}
                                            </div>
                                            <div className=' kablukoMedziagosMobile mobileVisible'>{panaudotos[e] ?
                                                <MobileGrid isdestymas={['pavadinimas', 'kiekis', 'kaina']} single={true} info={panaudotos[e]}
                                                /> : t('nera')}</div>
                                            <button
                                                className="xDelete"
                                                onClick={() => {
                                                    kablukas.attributes.medziagos.map((k, i) => {
                                                        if (k.pavadinimas == e) {
                                                            kablukas.attributes.vienetai[i] = parseInt(panaudotos[e].kiekis) + parseInt(kablukas.attributes.vienetai[i])
                                                        }
                                                    })
                                                    setTransfer({
                                                        ...transfer,
                                                        [panaudotos[e].pavadinimas]: {
                                                            kiekis: 0
                                                        }
                                                    })
                                                    console.log(transfer, [panaudotos[e].pavadinimas])
                                                    let obj = panaudotos
                                                    delete obj[e]
                                                    setPanaudotos(obj)
                                                    setR(r + 1)
                                                }}
                                            >⨉</button>
                                        </div>

                                    )
                                })
                                : ''
                            }
                        </div>
                    </div>
                    <CustomButton
                        className='width100'
                        onClick={async () => {
                            await update(kablukas.id, kablukas.attributes)
                            await updateDarbas(panaudotos)
                            setR(r + 1)
                            close()
                        }}
                        text='issaugoti'
                    />

                </div >

                : <div className="neraKabluko">{t('neraKabluko')}</div>}
        </div >
    )
}

export default MedziaguPicker



export const Single = ({ kablukas, setTransfer, transfer, panaudotos, setPanaudotos }) => {
    const [name, setName] = useState('')
    const [kaina, setKaina] = useState('')
    const [kiekis, setKiekis] = useState('')
    const [matas, setMatas] = useState('')
    const { t } = useTranslation()
    const [addMaterial, setAddMaterial] = useState(false)

    return (
        <div className="">
            {!addMaterial ?

                <CustomButton
                    className='marginV10'
                    text='prideti_medziaga'
                    onClick={() => {
                        setAddMaterial(true)
                    }}
                />
                : ''
            }
            {addMaterial ?
                <div className="width100">
                    <h4 className="noMarginBot">{t('prideti_medziaga')}</h4>
                    <div className="column pridetiMedz">
                        <div className="gridColumn2">
                            <div className="modalContVidus2">
                                <TextField className="width100Mob" size="small" label={t('medziaga')} value={name} onChange={(e) => { setName(e.target.value) }} />
                                <TextField className="width100Mob" size="small" label={t('matas')} value={matas} onChange={(e) => { setMatas(e.target.value) }} />
                            </div>
                            <div className="modalContVidus2">
                                <TextField className="width100Mob" size="small" label={t('kaina')} value={kaina} onChange={(e) => {
                                    let text = e.target.value
                                    if (text.includes(',')) { text = text.replace(',', '.') }
                                    setKaina(text)
                                }} />
                                <TextField className="width100Mob" size="small" label={t('kiekis')} value={kiekis} onChange={(e) => { setKiekis(e.target.value) }} />
                            </div>
                        </div>
                        <CustomButton
                            className='pridetiBtn width100Mob marginV5'
                            disabled={kiekis == 0 || isNaN(kiekis) || isNaN(kaina) || name.length < 0}
                            onClick={() => {
                                setPanaudotos({
                                    ...panaudotos,
                                    [name]: {
                                        pavadinimas: name,
                                        mato_vienetas: matas,
                                        apmoketa: false,
                                        kaina: kaina,
                                        kiekis: kiekis,
                                    }
                                }
                                )
                                setAddMaterial(false)
                            }}
                            text='prideti'
                        />
                    </div>
                </div>
                : ''}

        </div>
    )
}