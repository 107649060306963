import React, { useEffect, useState, useContext } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CustomButton from '../Mui/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import { TaskContext } from '../../AppStates';
import { NoFlash } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

const Materials = ({ info, setInfo, medziagos, update, id }) => {
  const [expand, setExpand] = useState(true)

  return (
    <div className="modalCont modalContVidus">
      <div className="sectionCont">
        <div className="section">
          {expand ? <ExpandLessIcon
            sx={{ cursor: 'pointer' }}
            color='primary'
            onClick={() => {
              expand ? setExpand(false) : setExpand(true)
            }} /> :
            <ExpandMoreIcon
              sx={{ cursor: 'pointer' }}
              color='primary'
              onClick={() => {
                expand ? setExpand(false) : setExpand(true)
              }} />}
          <h4 className="sectionName">Medžiagos</h4>
        </div>
      </div>
      <div className={expand ? 'modalContVidus' : 'modalContVidus hidden'}>
        <CustomButton
          text='prideti'
          startIcon={<AddIcon />}
          onClick={() => { medziagos() }}
        />
        <List
          data={info.medziagos}
          info={info}
          update={update}
          id={id}
        />
      </div>
    </div>

  )
}

export default Materials

const List = ({ data, update, id }) => {
  var list = data || []
  const { type } = useContext(TaskContext)
  var bendras = 0
  return (
    <ol>
      {Object.keys(list).length > 0 ?
        Object.keys(list).map((e, i) => {
          return (
            <li key={e + '-row-panaudotos'}>
              <div className='row spaceBetween'>
                <div>
                  <p>{list[e].pavadinimas}</p>
                  <div className='row'>
                    <p className='space'> {list[e].kiekis} {list[e].mato_vienetas} </p>
                    <p className='space'> {(list[e].kiekis * list[e].kaina).toFixed(2)}€</p>
                  </div>
                </div>
                <div>
                  {type == 0 ?
                    <div className='column'>
                      <IconButton
                        onClick={() => {
                          update(id, {
                            'medziagos': {
                              ...list, [list[e]['pavadinimas']]: {
                                ...list[e],
                                apmoketa: list[e].apmoketa ? !list[e].apmoketa : true
                              }
                            }
                          }); list[e].apmoketa = list[e].apmoketa ? !list[e].apmoketa : true
                        }}
                        color={list[e].apmoketa ? 'success' : 'grey'}
                      >
                        <DoneIcon />
                      </IconButton>
                      <p className='smallBtnTxt'>{list[e].apmoketa ? t('apmoketa_m') : t('neapmoketa_m')}</p>
                    </div>
                    : ''}
                  <div className='hidden'>{bendras += list[e].kiekis * list[e].kiekis}</div>
                </div>
              </div>
            </li>

          )
        })
        : ''}
    </ol >
  )
}