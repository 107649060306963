import React, { useState, createContext, } from "react";

export const TaskContext = createContext();
const TaskContextProvider = (props) => {
    const [tasks, setTasks] = useState('login');
    const [user, setUser] = useState('');
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(true)
    const [type, setType] = useState('')
    // const [path, setPath] = useState('http://localhost:1337/api/')//local
    // const [pathImg, setPathImg] = useState('http://localhost:1337')//local for img  
    const [path, setPath] = useState('https://ukvedziai-server.herokuapp.com/api/')//global
    const [pathImg, setPathImg] = useState('https://ukvedziai-server.herokuapp.com')//global for img
    return (
        <TaskContext.Provider value={{
            tasks, setTasks,
            user, setUser,
            token, setToken,
            path, setPath,
            pathImg, setPathImg,
            isLoading, setIsLoading,
            type, setType
        }}>
            {props.children}
        </TaskContext.Provider>
    );
};
export default TaskContextProvider

