import React, { useContext, useEffect, useState } from 'react'
import CustomButton from '../Mui/CustomButton.js'
import CustomTextField from '../../Components/Mui/CustomTextField.js'
import { t } from 'i18next';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEmail } from '../../hooks/useEmail.js';
import { atsaukimoEmail } from '../emails';
import Modal from 'react-modal';
import { TaskContext } from '../../AppStates.js';
import UserPickerSelect from '../UserPickerSelect.js';
import { useUser } from '../../hooks/useUser.js';
import { CheckStatus } from '../Meistras.js';
import CustomSelect from '../Mui/CustomSelect.js';

const Info = ({ info, setInfo, id, laikaKeitis, r, update }) => {
    const { type } = useContext(TaskContext)
    const [expand, setExpand] = useState(true)
    const [disabled, setDisabled] = useState(id ? true : false)
    const [open, setOpen] = useState(false)
    const [alert, setAlert] = useState(false)
    const { sendEmail: emailSender } = useEmail()
    const { users: darbuotojai, getAllUsers: GetDarbuotojai, getUser: GetDarbuotojas } = useUser({ extraFilters: '?filters[type][$eq]=Meistras(-ė)' })

    useEffect(() => { GetDarbuotojai() }, [GetDarbuotojai])

    useEffect(() => {
        if (type == 2) {
            if (CheckStatus(info?.statusas, [t('priskirtas'), t('nepriskirtas'), t('planuojama'), t('priskirtas_tesiamas'), t('nepriskirtas_pratestas')])) setExpand(true)
            else setExpand(false)
        }
    }, [info?.statusas])
    const [statusValue, setStatusValue] = useState('')
    useEffect(() => { setStatusValue(info?.statusas) }, [])
    const allStatuses = [
        t('planuojama'),
        t('priskirtas'),
        t('vykdoma'),
        t('neapmoketa'),
        t('apmoketa'),
        t('priskirtas_tesiamas'),
        t('pavedimu'),
        t('pavedimu_pratestas'),
        t('nepriskirtas_pratestas'),
        t('pabaigta'),
        t('atsaukta'),
        t('samata'),
        t('samata_baigta'),

    ]
    const [categoryValue, setCategoryValue] = useState('')
    useEffect(() => { setCategoryValue(info?.kategorija) }, [])
    const allCategory = t('kategorija')


    return (
        <div className="modalCont modalContVidus">
            <div className="sectionCont">
                <div className="section">
                    {expand ? <ExpandLessIcon
                        sx={{ cursor: 'pointer' }}
                        color='primary'
                        onClick={() => {
                            expand ? setExpand(false) : setExpand(true)
                        }} /> :
                        <ExpandMoreIcon
                            sx={{ cursor: 'pointer' }}
                            color='primary'
                            onClick={() => {
                                expand ? setExpand(false) : setExpand(true)
                            }} />}
                    <h4 className="sectionName">{t('uzsakymo_info')}</h4>
                </div>
                {type == 2 || !id ?
                    '' :
                    <EditIcon
                        sx={{ cursor: 'pointer' }}
                        color='primary'
                        onClick={() => {
                            disabled ? setDisabled(false) : setDisabled(true)
                        }} />
                }
            </div>
            <div className={expand ? ' gridColumn' : ' gridColumn hidden'}>
                <div className='modalContVidus'>

                    <UserPickerSelect
                        disabled={disabled ? true : false}
                        key={'userPicker'}
                        info={darbuotojai}
                        deploy={info || {}}
                        id={id}
                        darbuotojas={info?.darbuotojoId}
                        close={() => { }}
                        r={r}
                    />
                    <CustomSelect
                        disabled={disabled}
                        options={allStatuses}
                        optionsText={allStatuses}
                        label={t('pakeistiStatusa')}
                        current={statusValue || t('samata')}
                        set={(e) => {
                            info.statusas = e
                            setStatusValue(e)
                            // updeitas(id, deploy, e)
                        }}
                    />
                    <CustomSelect
                        disabled={disabled}
                        options={allCategory}
                        optionsText={allCategory}
                        label={t('pakeistiKategorija')}
                        current={categoryValue || ''}
                        set={(e) => {
                            setInfo({ ...info, kategorija: e })
                            setCategoryValue(e)
                            // updeitas(id, deploy, e)
                        }}
                    />

                    <TextField
                        disabled={disabled ? true : false}
                        color="primary"
                        variant="outlined"
                        type="text"
                        label={t('vardas')}
                        value={info?.vardas || ''}
                        onChange={value => { setInfo({ ...info, vardas: value.target.value }) }}
                        size="small"
                        margin="none"
                    />
                    <TextField
                        disabled={disabled ? true : false}
                        color="primary"
                        variant="outlined"
                        type="text"
                        label={t('pavarde')}
                        value={info?.pavarde || ''}
                        onChange={value => { setInfo({ ...info, pavarde: value.target.value }) }}
                        size="small"
                        margin="none"
                    />
                    <TextField
                        disabled={disabled ? true : false}
                        color="primary"
                        variant="outlined"
                        type="email"
                        label={t('email')}
                        size="small"
                        margin="none"
                        value={info?.email || ''}
                        onChange={value => {
                            setInfo({ ...info, email: value.target.value })
                        }}
                    />
                    <TextField
                        disabled={disabled ? true : false}
                        color="primary"
                        variant="outlined"
                        type="tel"
                        label={t('tel')}
                        size="small"
                        margin="none"
                        value={info?.tel || ''}
                        onChange={value => { setInfo({ ...info, tel: value.target.value }) }}
                    />
                    <TextField
                        disabled={disabled ? true : false}
                        color="primary"
                        variant="outlined"
                        multiline
                        label={t('problema')}
                        margin="none"
                        size="small"
                        value={info?.problema ? info.subKategorija + ' | ' + info.problema : info.subKategorija}
                        onChange={event => {
                          const value = event.target.value;
                          setInfo(prevInfo => {
                            const subKategorijaLength = prevInfo?.subKategorija?.length + 3; // Length of subKategorija + separator length
                            if (value.length >= subKategorijaLength) {
                              return {
                                ...prevInfo,
                                problema: value.substring(subKategorijaLength)
                              };
                            } else {
                              return {
                                ...prevInfo,
                                subKategorija: value,
                                problema: ''
                              };
                            }
                          });
                        }}
                    />
                </div>
                <div className='modalContVidus'>
                    <TextField
                        disabled={disabled ? true : false}
                        color="primary"
                        variant="outlined"
                        type="text"
                        label={t('adresas')}
                        size="small"
                        margin="none"
                        multiline
                        value={info?.adress || ''}
                        onChange={value => { setInfo({ ...info, adress: value.target.value }) }}
                    />
                    <TextField
                        disabled={disabled ? true : false}
                        color="primary"
                        variant="outlined"
                        type="text"
                        label={t('namo_nr')}
                        size="small"
                        margin="none"
                        value={info?.namoNr || ''}
                        onChange={(value) => { setInfo({ ...info, namoNr: value.target.value }) }}
                    />
                    <TextField
                        disabled={disabled ? true : false}
                        color="primary"
                        variant="outlined"
                        type="text"
                        label={t('buto_nr')}
                        size="small"
                        margin="none"
                        value={info?.butoNr || ''}
                        onChange={(value) => { setInfo({ ...info, butoNr: value.target.value }) }}
                    />
                    {
                        info?.adress ?

                            <CustomButton
                                color="secondary"
                                text={t('rodyti_zemelapy')}// Arklių+g.+18c,+01306+Vilnius
                                href={'http://maps.google.com/?q=' + `${info.adress.replaceAll(' ', '+')}+${info.namoNr}`}
                                target={"_blank"}
                            >

                            </CustomButton>
                            : ''
                    }
                    <TextField
                        disabled={disabled ? true : false}
                        color="primary"
                        variant="outlined"
                        type="text"
                        label={t('papildoma_info')}
                        size="small"
                        margin="none"
                        multiline
                        value={info?.papildomaAdresui || ''}
                        onChange={(value) => { setInfo({ ...info, papildomaAdresui: value.target.value }) }}
                    />
                </div>
                {disabled || !id ? '' :
                    <>
                        <CustomButton
                            disabled={disabled ? true : false}
                            color="primary"
                            text='keisti_laika'
                            onClick={() => { laikaKeitis(id) }}
                        >

                        </CustomButton>
                        <CustomButton
                            disabled={disabled ? true : false}
                            color="error"
                            text='atsaukti'
                            onClick={() => {
                                setOpen(true)
                            }}
                        >
                        </CustomButton>
                    </>
                }
            </div>
            {
                disabled || !id ? '' :
                    <CustomButton
                        text='issaugoti'
                        disabled={disabled ? true : false}
                        onClick={() => {
                            update(id, info)
                            setDisabled(true)
                        }}
                    />
            }
            <Modal
                isOpen={open}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setOpen(false) }}
            >
                <div
                    className="centerContainer1 column">
                    <button className="closeBtnMod" onClick={() => {
                        setOpen(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_atsaukti')}?</h4>
                    </div>
                    <div className='gridColumn'>
                        <CustomButton
                            onClick={() => {
                                info.statusas = t('atsaukta')
                                info.archyvuotas = true
                                update(id, info)
                                emailSender(atsaukimoEmail(info, id))
                                setAlert(true)
                                setTimeout(() => { setAlert(false) }, 3000)
                                setOpen(false); r()

                            }}
                            text='taip'
                        />
                        <CustomButton
                            onClick={() => {
                                setOpen(false); r()
                            }}
                            text='ne'
                        />
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default Info