
import { Link } from "react-router-dom";
import App from "./App"
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { TaskContext } from "./AppStates";
import Home from "./routes/Home";
import Main from "./routes/Main";
import Naujienos from "./routes/Naujienos";
import Archyvas from "./routes/Archyvas";
import Darbuotojai from "./routes/Darbuotojai";
import Kablukas from "./routes/Kablukas";
import Kalendorius from "./routes/Kalendorius";
import Darbai from "./routes/Darbai";
import Admin from "./Components/Admin";
import Buhaltere from "./Components/Buhaltere";
import Meistras from "./Components/Meistras";
import Statistika from "./routes/Statistika";
import Produktai from "./routes/Produktai";


const Navigation = () => {
  const { tasks } = useContext(TaskContext)
  return (
    tasks == "login" ? (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/Admin' element={<Admin />} />
        <Route path='/Buhaltere' element={<Buhaltere />} />
        <Route path='/Meistras' element={<Meistras />} />

      </Routes>
    ) : tasks == "loged" ? (
      <Routes>

        <Route path="/" element={<Darbai />} />
        <Route path="/archyvas" element={<Archyvas />} />
        <Route path="/statistika" element={<Statistika />} />
        <Route path="/kablukas" element={<Kablukas />} />
        <Route path="/naujienos" element={<Naujienos />} />
        <Route path="/produktai" element={<Produktai />} />
        <Route path="/main" element={<Main />} />
        <Route path="/kalendorius" element={<Kalendorius />} />
        <Route path="/darbuotojai" element={<Darbuotojai />} />
        <Route path='/Admin' element={<Admin />} />
        <Route path='/Buhaltere' element={<Buhaltere />} />
        <Route path='/Meistras' element={<Meistras />} />



      </Routes>
    ) : (<div></div>)

  )
}
export default Navigation
