

const Create = async (path, token, what, info = {}, get, flag) => {
    try {
        var fe = await fetch(path + what, {
            method: 'POST',
            // mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(
                flag == 'users' ? info : {
                    data: info
                }
            ),
        })
        var jsonn = await fe.json()
        var errorsM = ''
        if (jsonn?.error?.status == 400) {
            console.log('js', jsonn, Object.keys(jsonn.error.details).length)
            if (Object.keys(jsonn.error.details).length > 0) {
                jsonn.error.details.errors.details.error.map(e => {
                    errorsM += (e.message + '\n')
                })
            }
            else errorsM = jsonn.error.message
            alert(errorsM)
            throw false
        }
        return jsonn
    }
    catch (e) {
        console.log(e);
        alert(e)
        return false
    }
    // finally { get() }
}
export default Create