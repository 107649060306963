import { useTranslation } from "react-i18next"
import santechnika from '../assets/santechnika.png'
import elektra from '../assets/elektra.png'
import sildymas from '../assets/sildymas.png'
import kondicionavimas from '../assets/kondicionavimas.png'
import vedinimas from '../assets/vedinimas.png'

const KategorijosNustatymas = (kategorija) => {
    const { t } = useTranslation()
    const paveiksliukai = [
        // "Santechnika",
        // "Elektra",
        // "Šildymas",
        // "Kondicionavimas",
        // "Vėdinimas"
        santechnika,
        elektra,
        sildymas,
        kondicionavimas,
        vedinimas,

    ]
    var cat = t('kategorija')
    var tinka = ''
    cat.map((e, i) => {
        if (e == kategorija) {
            tinka = paveiksliukai[i]
        }
    })
    return tinka

}
export default KategorijosNustatymas