import './css/Modal.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import React, { useEffect, useState, } from 'react';
import PdfBuilder from './PDF/PVMSaskaitaFatktura';
import IsankstineBuilder from './PDF/isankstineSaskaita';
import { useContext } from 'react';
import { TaskContext } from '../AppStates';
import { ValidateUser, emailEmptyValidation, emailValidation, numberEmptyValidation } from '../functions/validation'
import Images, { addImagesToPost, imgDelete } from './ImagesAddComponent';
import Singnature from '../functions/signature';
import datetimeDifference from "datetime-difference";
import EmailBody from './EmailBody.js';
import Modal from 'react-modal';
import MobileGrid from './MobileFlexTable';
import { atsaukimoEmail } from './emails';
import { useEmail } from "../hooks"
import Admin from './Admin';
import Buhaltere from './Buhaltere';
import Meistras from './Meistras';
import UserPickerSelect from './UserPickerSelect';
import { Suma, TikLaikas } from './MainModal/Price';


const ModalVidus = ({ close, selectedIndex, r, id, deploy, darbuotojai, medziagos, laikaKeitis, set, update, pratesti, darbuotojas, modalGetDarbuotojai }) => {
    const { user, type, token, path } = useContext(TaskContext)
    const { t } = useTranslation()
    const [info, setInfo] = useState(deploy.attributes)
    const [nuotraukos, setNuotruakos] = useState(false)
    const [darbuotojoNuotraukos, setDarbuotojoNuotraukos] = useState(false)
    const [alert, setAlert] = useState(false)
    const [redaguotiApmokejima, setRedaguotiApmokejima] = useState(false)
    const [yraParasas, setYraParasas] = useState(false)
    const [papildoma, setPapildoma] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalIsOpen2, setModalIsOpen2] = useState(false)
    const [tipasPdf, setTipasPdf] = useState('open')
    const [modalIsOpen3, setModalIsOpen3] = useState(false)

    const { sendEmail: emailSender } = useEmail()

    useEffect(() => {
        setInfo(deploy.attributes)
        setYraParasas(deploy.attributes.parasas.data ? true : false)
    }, [deploy])
    const changeUser = () => { modalGetDarbuotojai() }
    const handleSubmit = async (event) => {
        try {
            if (ValidateUser(info))
                update(deploy.id, info)

            close()
        }
        catch (e) { console.log('er', e) }

    }
    const [ticking, setTicking] = useState(null)
    const [pradirbtasLaikas, setPradirbtasLaikas] = useState('')
    const [emailSend, setEmailSend] = useState(false)
    const [pdfRender, setPdfRender] = useState(null)
    const [emailText, setEmailText] = useState('')
    const [subject, setSubject] = useState('')
    const [emailEdit, setEmailEdit] = useState(false)
    const [send, setSend] = useState(false)

    const makeTwo = (value) => {
        if (parseInt(value) < 10) { return '0' + value }
        else { return value }
    }
    const laikrodukas = (start) => {
        const t = datetimeDifference(new Date(start), new Date())

        const time = {
            h: t.hours,
            min: t.minutes,
            sec: t.seconds,
            addSec: function () { return this.sec += 1 },
            addMin: function () { this.min += 1; this.sec = 0 },
        }
        var tick = setInterval(() => {
            if (time.sec >= 59) {
                time.addMin()
            } else {
                time.addSec()
            }
            setPradirbtasLaikas(makeTwo(time.h) + ":" + makeTwo(time.min) + ":" + makeTwo(time.sec))

        }, 1000)
        setTicking(tick)
        // console.log(info)
        return (<div>{pradirbtasLaikas}</div>)
    }
    useEffect(() => {
        if (ticking) { clearInterval(ticking); }
        if (info?.pradirbtasLaikas?.pradėta && !info?.pradirbtasLaikas?.pabaigta) {
            laikrodukas(info.pradirbtasLaikas.pradėta)
        }
    }, [info?.pradirbtasLaikas])
    // useEffect(() => {
    //     setTimeout(() => { setAlert(false) }, 3000); r()
    // }, [alert])
    const siustiEmail = async (text, tipas = 'siusti', subject = "Ukvedžiai - sąskaita faktūra") => {
        var arr = info.pradirbtasLaikas.pabaigta ? [info.pradirbtasLaikas.pradėta, info.pradirbtasLaikas.pabaigta] : [new Date(), new Date()]
        console.log(tipas)
        var x = await PdfBuilder
            (
                info, deploy.id, t, darbuotojas,
                TikLaikas(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis')),
                arr,
                tipas
            )
        if (tipas == 'siusti') {
            setPdfRender(x)
            setEmailSend(true)
            setEmailText(text)
            setSubject(subject)
            setSend(true)
        }
    }
    const issiuntimasPavedimu = () => {
        var textEmailPavedimu = `Sveiki,
        <br>
        <br>ačiū, kad kviečiate.
        <br> Siunčiame sąskaitą-faktūrą (kurią apmokėti kviečiame per 3 darbo dienas) ir linkime sėkmės namuose!
        <br>
        <br>Praktiškai jūsų – 
        <br>ŪKVEDŽIAI
        `
        siustiEmail(textEmailPavedimu, 'siusti', 'Tikimės, padėjome!',)
    }

    const issiuntimasKortele = () => {
        var textEmailKortele = `Sveiki,
                                    <br>
                                    <br>ačiū, kad kviečiate.
                                    <br>Siunčiame sąskaitą-faktūrą ir linkime sėkmės namuose!
                                    <br>
                                    <br>Praktiškai jūsų – 
                                    <br>ŪKVEDŽIAI
                                    `
        siustiEmail(textEmailKortele, 'siusti', 'Tikimės, padėjome!',)
        //text, tipas = 'siusti', subject = "Ukvedžiai - sąskaita faktūra"
    }
    return (
        Object.keys(deploy) != 0 ?
            <div className='modalaz'>



                {type == 0 ?
                    <Admin
                        deploy={info}
                        id={deploy.id}
                        r={r}
                        laikaKeitis={laikaKeitis}
                        medziagos={medziagos}
                        darbuotojas={darbuotojas}
                    />
                    : ''
                }
                {type == 1 ?
                    <Buhaltere
                        deploy={info}
                        id={deploy.id}
                        r={r}
                        laikaKeitis={laikaKeitis}
                        medziagos={medziagos}
                        darbuotojas={darbuotojas}
                    />
                    : ''
                }
                {type == 2 ?
                    <Meistras
                        deploy={info}
                        id={deploy.id}
                        r={r}
                        laikaKeitis={laikaKeitis}
                        medziagos={medziagos}
                        darbuotojas={darbuotojas}
                    />
                    : ''
                }
            </div>
            : ""
    )
    return (
        Object.keys(deploy) != 0 ?
            <div className='modalaz'>



                {type == 0 ?
                    <Admin
                        deploy={info}
                        id={deploy.id}
                        r={r}
                        laikaKeitis={laikaKeitis}
                        medziagos={medziagos}
                    />
                    : ''
                }
                {type == 1 ?
                    <Buhaltere
                        deploy={info}
                        id={deploy.id}
                        r={r}
                        laikaKeitis={laikaKeitis}
                        medziagos={medziagos}
                    />
                    : ''
                }
                {type == 2 ?
                    <Meistras
                        deploy={info}
                        id={deploy.id}
                        r={r}
                        laikaKeitis={laikaKeitis}
                        medziagos={medziagos}
                    />
                    : ''
                }


                {/* <div>
                    <h3 className='miniM pavad'>{new Date(info.data[0]).toISOString().split('T')[0].replace('-', '.').replace('-', '.')}</h3>
                </div> */}
                {darbuotojas && darbuotojas.Vardas ?

                    <div className='darb'>
                        <TextField
                            className='disabled kablukas'
                            disabled={true}
                            key={'darbuotojas'}
                            label={t('pareigybe')[2]}
                            // multiline
                            maxRows={1}
                            value={darbuotojas.Vardas ? darbuotojas.Vardas + ' ' + darbuotojas.Pavarde : ''}
                        />
                        {type == 0 ?
                            <div className='addDarb'>
                                {info.data[0] ?
                                    <button className='btn primary choose' onClick={() => { modalGetDarbuotojai() }}>{t('pakeistiDarbuotoja')}</button>
                                    : <button
                                        className={redaguotiApmokejima ? 'primary btn disabled' : 'primary btn'}
                                        onClick={() => {
                                            laikaKeitis(deploy.id)

                                            // laikaKeitis(deploy.id, info) 
                                        }}
                                    >{t('planuotiLaika2')}</button>
                                }
                                <button className='btn primary choose' onClick={() => { update(deploy.id, { ...info, statusas: info?.seni && info?.seni[0] != null ? t('nepriskirtas_pratestas') : t('nepriskirtas'), darbuotojoId: -1 }); r() }}>{t('removeUser')}</button>
                            </div>
                            : ''}
                    </div>
                    : <div className='pasirinktiDarbArch'>
                        {type == 0 ?
                            info.data[0] ?
                                <button className='btn primary ' onClick={changeUser}>{t('priskirtiDarbuotoja')}</button> :
                                <button
                                    className='primary btn '
                                    onClick={() => { laikaKeitis(id, info) }}
                                >{t('planuotiLaika2')}</button>
                            : ''}
                    </div>}
                <div className=''>
                    <div className='info'>
                        <div className='infoAsm'>
                            <TextField
                                disabled={type == 0 ? false : true}

                                key={'vardas'}
                                label={t("vardas")}
                                // multiline
                                maxRows={1}
                                value={info.vardas ? info.vardas : ''}
                                onChange={value => { setInfo({ ...info, vardas: value.target.value }) }}
                            />
                            <TextField
                                disabled={type == 0 ? false : true}

                                key={'pavarde'}
                                label={t("pavarde")}
                                // multiline
                                maxRows={1}
                                value={info.pavarde ? info.pavarde : ''}
                                onChange={value => { setInfo({ ...info, pavarde: value.target.value }) }}
                            />
                            <TextField
                                disabled={type == 0 ? false : true}
                                key={'email'}
                                label={t('email')}
                                // multiline
                                error={emailValidation(info.email) ? false : true}
                                value={info.email ? info.email : ''}
                                maxRows={1}
                                onChange={value => {
                                    setInfo({ ...info, email: value.target.value })
                                }}
                            />
                            <TextField
                                disabled={type == 0 ? false : true}

                                key={'tel'}
                                error={numberEmptyValidation(info.tel) ? false : true}
                                label={t("tel")}
                                // multiline
                                maxRows={1}
                                value={info.tel ? info.tel : ''}
                                onChange={value => { setInfo({ ...info, tel: value.target.value }) }}
                            />
                            <TextField
                                disabled={type == 0 ? false : true}

                                key={'adress'}
                                label={t("adresas")}
                                multiline
                                maxRows={2}
                                value={info.adress ? info.adress : ''}
                                onChange={value => { setInfo({ ...info, adress: value.target.value }) }}
                            />
                            <a className='rodyti_zemelapy' href={'http://maps.google.com/?q=' + info.adress.replaceAll(' ', '+')} target={"_blank"}>{t('rodyti_zemelapy')}</a>

                        </div>
                        <div className='infoProb'>
                            {/* <p className='subktegorija'>{info.subKategorija}</p> */}
                            <TextField
                                disabled={type == 0 ? false : true}
                                // sx={{

                                //     width: 350
                                // }}
                                // InputProps={{ sx: { height: 215} }}

                                key={'Problema'}
                                label={t("problema")}
                                multiline
                                maxRows={9}
                                value={info.problema ? info.subKategorija + ' | ' + info.problema : ''}
                                onChange={value => { setInfo({ ...info, problema: value.target.value }) }}
                            />
                            <TextField
                                disabled={type == 0 ? false : true}

                                key={'namo_nr'}
                                label={t("namo_nr")}
                                multiline
                                maxRows={1}
                                value={info.namoNr ? info.namoNr : ''}
                                onChange={(value) => { setInfo({ ...info, namoNr: value.target.value }) }}
                            />
                            <TextField
                                disabled={type == 0 ? false : true}

                                key={'buto_nr'}
                                label={t("buto_nr")}
                                multiline
                                maxRows={1}
                                value={info.butoNr ? info.butoNr : ''}
                                onChange={(value) => { setInfo({ ...info, butoNr: value.target.value }) }}
                            />
                            <TextField
                                disabled={type == 0 ? false : true}

                                key={'papildoma_info'}
                                label={t("papildoma_info")}
                                multiline
                                maxRows={3}
                                value={info.papildomaAdresui ? info.papildomaAdresui : ''}
                                onChange={(value) => { setInfo({ ...info, papildomaAdresui: value.target.value }) }}
                            />
                        </div>
                    </div>
                    {
                        info.uzsakovoTipas == null ?
                            ''
                            :




                            <div className='infoCont'>
                                <h3 className={papildoma ? 'opener open' : 'opener'} onClick={() => { setPapildoma(!papildoma) }}>{t('papildoma')}</h3>

                                <div className={papildoma ? 'papildoma' : 'papildoma hidden'}>
                                    <h3>{info.uzsakovoTipas}</h3>

                                    {info.uzsakovoTipas == t('fizinis_asmuo') ?

                                        <div className='infoVid'>

                                            <TextField
                                                disabled={type == 0 ? false : true}

                                                key={'asmensAdresas'}
                                                label={t("asmensAdresas")}
                                                // multiline
                                                maxRows={1}
                                                value={info.asmensAdresas ? info.asmensAdresas : ''}
                                                onChange={value => { setInfo({ ...info, asmensAdresas: value.target.value }) }}
                                            />
                                            <TextField
                                                disabled={type == 0 ? false : true}

                                                key={'asmensKodas'}
                                                label={t("asmensKodas")}
                                                // multiline
                                                maxRows={1}
                                                value={info.asmensKodas ? info.asmensKodas : ''}
                                                onChange={value => { setInfo({ ...info, asmensKodas: value.target.value }) }}
                                            />
                                        </div>
                                        :
                                        ''
                                    }
                                    {info.uzsakovoTipas == t('juridinis_asmuo') ?
                                        <div className='infoVid'>

                                            <TextField
                                                disabled={type == 0 ? false : true}
                                                key={'imonesPavadinimas'}
                                                label={t("imonesPavadinimas")}
                                                // multiline
                                                maxRows={1}
                                                value={info.imonesPavadinimas ? info.imonesPavadinimas : ''}
                                                onChange={value => { setInfo({ ...info, imonesPavadinimas: value.target.value }) }}
                                            />
                                            <TextField
                                                disabled={type == 0 ? false : true}
                                                key={'adresas'}
                                                label={t("adresas")}
                                                // multiline
                                                maxRows={1}
                                                value={info.imonesAdresas ? info.imonesAdresas : ''}
                                                onChange={value => { setInfo({ ...info, imonesAdresas: value.target.value }) }}
                                            />
                                            <TextField
                                                disabled={type == 0 ? false : true}

                                                key={'i_kodas'}
                                                label={t("i_kodas")}
                                                // multiline
                                                maxRows={1}
                                                value={info.imonesKodas ? info.imonesKodas : ''}
                                                onChange={value => { setInfo({ ...info, imonesKodas: value.target.value }) }}
                                            />
                                            <TextField
                                                disabled={type == 0 ? false : true}

                                                key={'i_pvm'}
                                                label={t("i_pvm")}
                                                // multiline
                                                maxRows={1}
                                                value={info.PVM ? info.PVM : ''}
                                                onChange={value => { setInfo({ ...info, PVM: value.target.value }) }}
                                            />
                                        </div>
                                        :
                                        ''
                                    }
                                </div>


                            </div>}
                    {/* //Images */}
                    <div className='imagez'>
                        <div key={'darboNuotraukos'} ><h3 className={nuotraukos ? 'opener open' : 'opener'} onClick={() => { setNuotruakos(!nuotraukos) }}>{t('nuotraukos')} </h3>
                            <div className={nuotraukos ? ' nuotraukos ' : ' nuotraukos hidden '}>
                                <Images
                                    id={deploy.id}
                                    api={"darbas"}
                                    info={!info.vartotojoNuotraukos ? [] : info.vartotojoNuotraukos.data}
                                    type={'vartotojoNuotraukos'}
                                    r={() => { r() }}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div key={'darboNuotraukosMano'} ><h3 className={darbuotojoNuotraukos ? 'opener open' : 'opener'} onClick={() => { setDarbuotojoNuotraukos(!darbuotojoNuotraukos) }}>Papildomos nuotraukos </h3>
                            <div className={darbuotojoNuotraukos ? ' nuotraukos ' : ' nuotraukos hidden '}>
                                <Images
                                    id={deploy.id}
                                    api={"darbas"}
                                    info={info.darbuotojoNuotraukos.data}
                                    type={'darbuotojoNuotraukos'}
                                    r={() => { r() }}
                                    disabled={type != 1 ? false : true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {//priskirtas
                    info.statusas == t('nepriskirtas') || info.statusas == t('nepriskirtas_pratestas') || info.statusas == t('priskirtas') || info.statusas == t('priskirtas_tesiamas') || info.statusas == t('priskirtas_patvirtintas') && type != 1 ?
                        <div className='pagrindiniaiMyg'>

                            <div >
                                <button
                                    className={info.statusas == t('nepriskirtas') || info.statusas == t('nepriskirtas_pratestas') ? 'primary btn disabled' : 'primary btn '}
                                    onClick={() => {
                                        //pakeitimai objektui
                                        info.pradirbtasLaikas = { pradėta: new Date() }
                                        info.statusas = t("vykdoma")
                                        update(deploy.id, info)
                                    }}>{t('pradetiDarba')}</button>
                            </div>
                            <div >
                                <button
                                    className='primary btn '
                                    onClick={() => { laikaKeitis(id, info) }}
                                >{t('redaguoti')}</button>
                            </div>
                            <div >
                                <button
                                    className='primary btn disabled'
                                >{t('redaguotiapmokejima')}</button>
                            </div>
                        </div>
                        : ''
                }
                {
                    info.statusas == t('vykdoma') && type != 1 ?
                        <div>
                            <div className='pagrindiniaiMyg smallPadd'>

                                <div >
                                    <button
                                        className='primary btn'
                                        onClick={() => {
                                            info.pradirbtasLaikas = {
                                                pradėta: info.pradirbtasLaikas.pradėta,
                                                pabaigta: new Date()
                                            }
                                            info.statusas = t('neapmoketa')
                                            update(deploy.id, info)

                                        }}>{t('pabaigtiDarba')}</button>
                                </div>
                                <div >
                                    <button
                                        className='primary btn disabled'

                                    >{t('redaguotiapmokejima')}</button>
                                </div>
                                <div >
                                    <button
                                        className='primary btn disabled'
                                    >{t('planuotiLaika')}</button>
                                </div>
                            </div>
                            <div className='darbo laikas'>
                                <h3>{t('darbo_laikas')}:</h3>

                                <div className='liveLaikas'>
                                    {
                                        // setInterval(() => {

                                        pradirbtasLaikas
                                        // }, 1000)
                                    }

                                </div>

                            </div>
                        </div>
                        : ''
                }
                {
                    info.statusas == t('neapmoketa') && type != 1 ?
                        <div>
                            <div className='pagrindiniaiMyg'>

                                <div >
                                    <button
                                        className='primary btn disabled'>{t('pradetiDarba')}</button>
                                </div>
                                <div >
                                    <button
                                        className={setRedaguotiApmokejima ? 'primary btn disabled' : 'primary btn '}
                                    // onClick={setRedaguotiApmokejima(false)}
                                    >{t('redaguotiapmokejima')}</button>
                                </div>
                                <div >
                                    <button
                                        className='primary btn disabled'
                                    >{t('planuotiLaika')}</button>
                                </div>
                            </div>
                            <div className='summary'>
                                <div className='summary2'>
                                    <h3>{t('pradirbtasLaikas')}: </h3>
                                    <h2>{MakeLaikas(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta))}</h2>
                                </div>
                                <div className='summary3'>
                                    <h3>{t('iskvietimo_suma')}: </h3>
                                    <h2>{Suma(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.panaudotosMediziagos, t('daugiklis'), t('iskvietimoMokestis'))} €</h2>
                                </div>
                            </div>
                        </div>
                        : ''
                }
                {//apmoketa
                    info.statusas == t('apmoketa') && type != 1 ?
                        <div>
                            <div className='pagrindiniaiMyg'>

                                <div >
                                    <button
                                        className='primary btn disabled'
                                        onClick={() => {
                                            //pakeitimai objektui
                                            info.pradirbtasLaikas = { pradėta: new Date() }
                                            info.statusas = t("vykdoma")
                                            update(deploy.id, info)
                                        }}>{t('pradetiDarba')}</button>
                                </div>
                                <div >
                                    <button
                                        className={redaguotiApmokejima ? 'primary btn disabled' : 'primary btn'}
                                        onClick={() => { setRedaguotiApmokejima(true) }}


                                    >{t('redaguotiapmokejima')}</button>
                                </div>
                                <div >
                                    <button
                                        className={redaguotiApmokejima ? 'primary btn disabled' : 'primary btn'}
                                        onClick={() => {
                                            pratesti(deploy.id, t('apmoketa'));
                                            // laikaKeitis(deploy.id, info) 
                                        }}
                                    >{t('planuotiLaika')}</button>
                                </div>
                            </div>
                            <div className='summary'>
                                <div className='summary2'>
                                    <h3>{t('pradirbtasLaikas')}: </h3>
                                    <h2>{MakeLaikas(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta))}</h2>
                                </div>
                                <div className='summary3'>
                                    <h3>{t('suma')}: </h3>
                                    <h2>{Suma(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis'))} €</h2>
                                </div>
                            </div>

                        </div>
                        : ''

                }
                {//PRATESTA
                    info.statusas == t('planuojama') && type != 1 ?
                        <div>
                            <div className='pagrindiniaiMyg'>

                                <div >
                                    <button
                                        className='primary btn disabled'
                                        onClick={() => {
                                            //pakeitimai objektui
                                            info.pradirbtasLaikas = { pradėta: new Date() }
                                            info.statusas = t("vykdoma")
                                            update(deploy.id, info)
                                        }}>{t('pradetiDarba')}</button>
                                </div>
                                <div >
                                    <button
                                        className={'primary btn disabled'}
                                        onClick={() => { setRedaguotiApmokejima(true) }}


                                    >{t('redaguotiapmokejima')}</button>
                                </div>
                                <div >
                                    <button
                                        className={redaguotiApmokejima ? 'primary btn disabled' : 'primary btn'}
                                        onClick={() => {
                                            laikaKeitis(deploy.id)

                                            // laikaKeitis(deploy.id, info) 
                                        }}
                                    >{t('planuotiLaika2')}</button>
                                </div>
                            </div>

                        </div>
                        : ''

                }


                {//apmoketa pavedimu

                    info.statusas == t('pavedimu') && type != 1 ?
                        <div>
                            <div className='pagrindiniaiMyg'>

                                <div >
                                    <button
                                        className='primary btn disabled'
                                        onClick={() => {
                                            //pakeitimai objektui
                                            info.pradirbtasLaikas = { pradėta: new Date() }
                                            info.statusas = t("vykdoma")
                                            update(deploy.id, info)
                                        }}>{t('pradetiDarba')}</button>
                                </div>
                                <div >
                                    <button
                                        className={'primary btn'}
                                        onClick={() => { redaguotiApmokejima ? setRedaguotiApmokejima(false) : setRedaguotiApmokejima(true) }}


                                    >
                                        {
                                            redaguotiApmokejima ?
                                                t('nustotiredaguoti') :
                                                t('redaguotiapmokejima')
                                        }
                                    </button>
                                </div>
                                <div >
                                    <button
                                        className={'primary btn disabled'}
                                        onClick={() => {
                                            laikaKeitis(id, info)
                                        }}
                                    >{t('planuotiLaika')}</button>
                                </div>
                            </div>
                            <div className='summary'>
                                <div className='summary2'>
                                    <h3>{t('pradirbtasLaikas')}: </h3>
                                    <h2>{MakeLaikas(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta))}</h2>
                                </div>
                                <div className='summary3'>
                                    <h3>{t('suma')}: </h3>
                                    <h2>{Suma(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis'))} €</h2>
                                </div>
                            </div>
                            <div className='arapmoketa'>
                                <h3>{t('pavedimu')}</h3>
                            </div>
                        </div> : ''

                }

                {//apmoketa pavedimu su pratesimu

                    info.statusas == t('pavedimu_pratestas') && type != 1 ?
                        <div>
                            <div className='pagrindiniaiMyg'>

                                <div >
                                    <button
                                        className='primary btn disabled'
                                        onClick={() => {
                                            //pakeitimai objektui
                                            info.pradirbtasLaikas = { pradėta: new Date() }
                                            info.statusas = t("vykdoma")
                                            update(deploy.id, info)
                                        }}>{t('pradetiDarba')}</button>
                                </div>
                                <div >
                                    <button
                                        className={'primary btn'}
                                        onClick={() => { redaguotiApmokejima ? setRedaguotiApmokejima(false) : setRedaguotiApmokejima(true) }}


                                    >
                                        {
                                            redaguotiApmokejima ?
                                                t('nustotiredaguoti') :
                                                t('redaguotiapmokejima')
                                        }
                                    </button>
                                </div>
                                <div >
                                    <button
                                        className={'primary btn disabled'}
                                        onClick={() => {
                                            laikaKeitis(id, info)
                                        }}
                                    >{t('planuotiLaika')}</button>
                                </div>
                            </div>
                            <div className='summary'>
                                <div className='summary2'>
                                    <h3>{t('pradirbtasLaikas')}: </h3>
                                    <h2>{MakeLaikas(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta))}</h2>
                                </div>
                                <div className='summary3'>
                                    <h3>{t('suma')}: </h3>
                                    <h2>{Suma(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis'))} €</h2>
                                </div>
                            </div>
                            <div className='arapmoketa'>
                                <h3>{t('pavedimu')}</h3>
                            </div>

                        </div> : ''

                }
                {/* <div>
                    {
                        // setInterval(() => {

                        pradirbtasLaikas
                        // }, 1000)
                    }

                </div> */}

                {
                    redaguotiApmokejima ?

                        <div className='redaguotiApmokejimaRadiobtnCont'>

                            <label className='redaguotiApmokejimaRadiobtn btnPay2'>
                                {t('kortele')}
                                <input type="radio" name='pasirinkimas' onClick={() => {
                                    info.statusas = t("apmoketa")
                                    setTimeout(() => { update(deploy.id, info) }, 500)
                                    setRedaguotiApmokejima(false)

                                    issiuntimasKortele()

                                }} />
                            </label>
                            <label className='redaguotiApmokejimaRadiobtn btnPay2'>
                                {t('pervedimu')}
                                <input type="radio" name='pasirinkimas'
                                    onClick={() => {
                                        info.statusas = t('pavedimu')
                                        update(deploy.id, info)
                                        setRedaguotiApmokejima(false)
                                        // setModalIsOpen2(true)
                                        issiuntimasPavedimu()
                                    }} />
                            </label>

                            <label className='redaguotiApmokejimaRadiobtn btnPay2'>
                                {t('pavedimu_pratestas')}
                                <input type="radio" name='pasirinkimas'
                                    onClick={() => {
                                        info.statusas = t('pavedimu_pratestas')
                                        update(deploy.id, info)
                                        setRedaguotiApmokejima(false)
                                        // setModalIsOpen2(true)
                                        issiuntimasPavedimu()

                                    }}
                                />
                            </label>
                            <label className='redaguotiApmokejimaRadiobtn btnPay2'>
                                {t('kortele_pratesimas')}
                                <input type="radio" name='pasirinkimas'
                                    onClick={() => {
                                        setRedaguotiApmokejima(false)
                                        info.kaina = Suma(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis'))
                                        pratesti(deploy.id, t('apmoketa'))
                                        issiuntimasKortele()


                                    }}
                                />
                            </label>
                            <label className='redaguotiApmokejimaRadiobtn btnPay2'>
                                {t('neapmoketas')}
                                <input type="radio" name='pasirinkimas'
                                    onClick={() => {
                                        info.statusas = t('neapmoketa')
                                        update(deploy.id, info)
                                        setRedaguotiApmokejima(false)
                                    }}
                                />
                            </label>
                        </div>
                        :
                        ''
                }
                <div>
                    <div className='komentarai'>
                        <TextField
                            style={{ width: 480 }}
                            label={t('komentarai')}
                            multiline
                            // maxRows={4}
                            value={info.uzrasine ? info.uzrasine : ''}
                            onChange={value => { setInfo({ ...info, uzrasine: value.target.value }) }}

                        />
                    </div>
                    <div className='panaudotosMedz'>
                        <h3>{t('panaudotosMedziagos')}</h3>
                        {info.statusas == t('pavedimu_pratestas') || info.statusas == t('pavedimu') ?
                            ''
                            : <div className="sf " onClick={() => { medziagos() }}> + {t('pridetiMedziagas')}</div>}

                        <div className='mobileHidden'>{info.medziagos ? <List data={info.medziagos} info={info} update={(v) => { update(deploy.id, v) }} /> : t('nera')}</div>
                        <div className='mobileVisible'>{info.medziagos ? <MobileGrid info={info.medziagos}
                            isdestymas={
                                [
                                    'pavadinimas',
                                    'mato_vienetas',
                                    'kiekis',
                                    'kaina',
                                ]}
                            buttons={
                                [
                                    {
                                        key: 'veiksmas',
                                        button: ApmoketaBtn,
                                        update: (v) => { update(deploy.id, v) }
                                    }
                                ]
                            } /> : t('nera')}</div>

                    </div>
                    {info.statusas == t('neapmoketa') && type != 1 ?
                        <div>
                            <div className='arapmoketa'>
                                <h3>{t('p')}</h3>
                            </div>
                            <div className='sign'>
                                <Singnature
                                    t={(value) => { t(value) }}
                                    parasas={deploy.attributes.parasas} //ar yra parasas
                                    delete={async (value) => { let x = await imgDelete(path, value, token); r(); setYraParasas(false); return x }}
                                    r={() => { r() }}
                                    save={async (value) => {
                                        let x = await addImagesToPost(path, [value], deploy.id, token, 'parasas', 'darbas').then(data => { return data })
                                        r()
                                        setYraParasas(true)
                                        return x
                                    }} />
                            </div>
                            <div>


                                <div className='arapmoketa'>
                                    <h3>{t('mokejimobudai')}</h3>
                                </div>
                                <div className='darbBtn'>
                                    <button
                                        className={yraParasas ? 'primary btn btnPay' : 'primary btn btnPay disabled'}
                                        onClick={() => {
                                            // startWork(deploy.id)
                                            info.kaina = Suma(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis'))
                                            info.statusas = t("apmoketa")
                                            setTimeout(() => { update(deploy.id, info) }, 500)
                                            issiuntimasKortele()
                                        }}>{t('kortele')}</button>
                                    <button
                                        className={yraParasas ? 'primary btn btnPay' : 'primary btn btnPay disabled'}
                                        onClick={() => {
                                            // startWork(deploy.id)
                                            info.kaina = Suma(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis'))
                                            info.statusas = t('pavedimu')
                                            update(deploy.id, info)
                                            // setModalIsOpen2(true)
                                            issiuntimasPavedimu()

                                        }}>{t('pervedimu')}</button>
                                    <button
                                        className={yraParasas ? 'primary btn btnPay' : 'primary btn btnPay disabled'}
                                        onClick={() => {
                                            // startWork(deploy.id)
                                            info.kaina = Suma(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis'))
                                            info.statusas = t('apmoketa');
                                            pratesti(deploy.id, t('apmoketa'))
                                            issiuntimasKortele()

                                        }}>{t('kortele_pratesimas')}</button>
                                    <button
                                        className={yraParasas ? 'primary btn btnPay' : 'primary btn btnPay disabled'}
                                        onClick={() => {
                                            // startWork(deploy.id)
                                            info.kaina = Suma(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis'))
                                            info.statusas = t('pavedimu_pratestas')
                                            update(deploy.id, info)
                                            issiuntimasPavedimu()

                                            // setModalIsOpen2(true)

                                        }}>{t('pavedimu_pratestas')}</button>
                                </div>
                            </div>
                        </div>
                        : ''
                    }
                    {
                        type == 1 ?
                            <div className='arapmoketa'>

                                <h3>{t('pavedimu')}</h3>

                                {info.statusas == t('pavedimu') || info.statusas == t('pavedimu_pratestas') ?
                                    <button
                                        className='btn primary '
                                        onClick={() => {
                                            if (info.statusas == t('pavedimu')) {
                                                info.statusas = t('apmoketa')
                                                update(deploy.id, info)
                                            }
                                            else {

                                                info.statusas = t('apmoketa');
                                                pratesti(id, t('apmoketa'))

                                            }


                                        }}>{t('patvirtinti_mokejima')}</button>

                                    : ''}
                            </div>
                            : ''
                    }
                </div>

                <div className='sfIssaug'>
                    <select className='SFselect' defaultValue={tipasPdf} onChange={(e) => {
                        setTipasPdf(e.currentTarget.value)
                    }}>
                        <option defaultValue="open">{t('open')}</option>
                        <option defaultValue="save">{t('issaugoti')}</option>
                        <option defaultValue="output">{t('siusti')}</option>
                        <option defaultValue="output">{t('siusti_redaguoti')}</option>
                    </select>
                    <div className='sfRow'>
                        <div
                            className='sf'
                            onClick={async () => {
                                var arr = info.pradirbtasLaikas != null && info.pradirbtasLaikas?.pradėta && info.pradirbtasLaikas?.pabaigta ? [info.pradirbtasLaikas.pradėta, info.pradirbtasLaikas.pabaigta] : [new Date(), new Date()]
                                var x = await PdfBuilder
                                    (
                                        info, deploy.id, t, darbuotojas,
                                        TikLaikas(new Date(info.pradirbtasLaikas?.pradėta), new Date(info.pradirbtasLaikas?.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis')),
                                        arr,
                                        tipasPdf == t('open') ? 'open' : tipasPdf == t('issaugoti') ? 'save' : tipasPdf
                                    )
                                if (tipasPdf == t('siusti')) {
                                    setPdfRender(x)
                                    setEmailSend(true)
                                    setEmailText('Dėkojame, siunčiame SF.')
                                    setSubject('Ukvedziai SF')
                                    setSend(true)
                                }
                                else if (tipasPdf == t('issaugoti')) {
                                    setPdfRender(x)
                                    setEmailSend(false)
                                    setEmailText('Dėkojame, siunčiame SF.')
                                    setSubject('Ukvedziai SF')
                                    setEmailEdit(false)
                                }
                                else if (tipasPdf == t('siusti_redaguoti')) {
                                    setPdfRender(x)
                                    setEmailSend(true)
                                    setEmailText('Dėkojame, siunčiame SF.')
                                    setSubject('Ukvedziai SF')
                                    setEmailEdit(true)
                                }
                            }}>{t('sf')}</div>
                        <div onClick={async () => {
                            var arr = info.pradirbtasLaikas != null && info.pradirbtasLaikas?.pradėta && info.pradirbtasLaikas?.pabaigta ? [info.pradirbtasLaikas.pradėta, info.pradirbtasLaikas.pabaigta] : [new Date(), new Date()]
                            var x = await IsankstineBuilder(
                                info, deploy.id, t, darbuotojas,
                                TikLaikas(new Date(info.pradirbtasLaikas?.pradėta), new Date(info.pradirbtasLaikas?.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis')),
                                arr,
                                tipasPdf == t('open') ? 'open' : tipasPdf == t('issaugoti') ? 'save' : tipasPdf
                            )
                            if (tipasPdf == t('siusti')) {
                                setPdfRender(x)
                                setEmailSend(true)
                                setEmailText('Dėkojame, siunčiame SF.')
                                setSubject('Ukvedziai SF')
                                setSend(true)
                            }
                            else if (tipasPdf == t('issaugoti')) {
                                setPdfRender(x)
                                setEmailSend(false)
                                setEmailText('Dėkojame, siunčiame SF.')
                                setSubject('Ukvedziai SF')
                                setEmailEdit(false)
                            }
                            else if (tipasPdf == t('siusti_redaguoti')) {
                                setPdfRender(x)
                                setEmailSend(true)
                                setEmailText('Dėkojame, siunčiame SF.')
                                setSubject('Ukvedziai SF')
                                setEmailEdit(true)
                            }
                        }}
                            className='sf'
                        >{t('Isankstine_sf')}</div>
                        {info.statusas == t('pabaigta') ?
                            <div
                                className='sf'
                            >{t('garantinis')}</div>

                            : ''}

                    </div>
                    {emailSend ?
                        <EmailBody
                            close={() => { setEmailSend(false) }}
                            info={info}
                            files={[pdfRender]}
                            subject={subject}
                            text={emailText}
                            editible={emailEdit}
                            send={send}
                            wasSent={() => { setSend(false); setEmailSend(false) }}
                            setAlert={() => { alert() }}
                        />
                        : ''}
                    {info.statusas == t('priskirtas') || info.statusas == t('nepriskirtas') || info.statusas == t('planuojama') || info.statusas == t('priskirtas_tesiamas') || info.statusas == t('priskirtas_patvirtintas') || info.statusas == t('priskirtas_nepatvirtintas') && type == 0 ?
                        <button
                            className='btn primary atsauktBtn'
                            onClick={() => {
                                setModalIsOpen3(true)
                                emailSender(atsaukimoEmail(info, deploy.id))
                            }}
                        >{t('atsaukti')}</button>
                        : ''
                    }
                    {info.statusas == t('priskirtas_nepatvirtintas') && type == 0 ?
                        <button
                            className='btn primary atsauktBtn'
                            onClick={() => {

                                info.statusas = t('priskirtas_patvirtintas');
                                update(deploy.id, info)

                            }}
                        >{t('patvirtinti')}</button>
                        : ''
                    }
                    {
                        type == 1 && info.statusas == t('apmoketa') ?
                            <button
                                className='btn primary atsauktBtn'
                                onClick={() => {
                                    // info.statusas = t('pabaigta')
                                    // info.archyvuotas = 'true'
                                    setModalIsOpen(true)
                                    // update(deploy.id, info)
                                    // setAlert(true)
                                    // setTimeout(() => { setAlert(false) }, 3000)

                                }}>{t('pabaigti_uzsakyma')}</button> : ''
                    }
                    <button
                        className='btn primary pasirinktiNarow'
                        onClick={() => {
                            handleSubmit();
                            setAlert(true)
                            setTimeout(() => { setAlert(false) }, 3000)

                        }}>{t('issaugoti')}</button>
                </div>


                <Modal
                    isOpen={modalIsOpen}
                    ariaHideApp={false}
                    className="modal3"
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => { setModalIsOpen(false) }}
                >
                    <div
                        className="centerContainer1 column">


                        <button className="closeBtn" onClick={() => {
                            setModalIsOpen(false)
                        }}>⨉</button>

                        <div className="modalInfo" >
                            <h4>{t('ar_tikrai_archyvuoti')}?</h4>
                        </div>
                        <div  >

                            <button
                                onClick={() => {
                                    info.statusas = t('pabaigta')
                                    info.archyvuotas = true
                                    update(deploy.id, info)
                                    setAlert(true); setTimeout(() => { setAlert(false) }, 3000); setModalIsOpen(false); r()
                                }}
                                className=" btn primary btnWide">{t('taip')}</button>

                            <button
                                onClick={() => {
                                    info.statusas = t('pabaigta')
                                    update(deploy.id, info)
                                    setAlert(true); setTimeout(() => { setAlert(false) }, 3000); setModalIsOpen(false); r()
                                }}
                                className=" btn primary btnWide">{t('ne')}</button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={modalIsOpen2}
                    ariaHideApp={false}
                    className="modal3"
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => { setModalIsOpen2(false) }}
                >
                    <div
                        className="centerContainer1 column">
                        <button className="closeBtn" onClick={() => {
                            setModalIsOpen2(false)
                        }}>⨉</button>

                        <div className="modalInfo" >
                            <h4>{t('ar_siust_sf')}?</h4>
                        </div>
                        <div>

                            <button
                                onClick={() => {
                                    //REIKIA LAIMONUI PARASYT KAZKA
                                    setModalIsOpen2(false); r()

                                }}
                                className=" btn primary btnWide">{t('taip')}</button>

                            <button
                                onClick={() => {
                                    setModalIsOpen2(false); r()
                                }}
                                className=" btn primary btnWide">{t('ne')}</button>
                        </div>
                    </div>
                </Modal>

                {/* ar tikrai atsaukt */}
                <Modal
                    isOpen={modalIsOpen3}
                    ariaHideApp={false}
                    className="modal3"
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => { setModalIsOpen3(false) }}
                >
                    <div
                        className="centerContainer1 column">
                        <button className="closeBtn" onClick={() => {
                            setModalIsOpen3(false)
                        }}>⨉</button>

                        <div className="modalInfo" >
                            <h4>{t('ar_atsaukti')}?</h4>
                        </div>
                        <div>

                            <button
                                onClick={() => {
                                    info.statusas = t('atsaukta')
                                    info.archyvuotas = true
                                    update(deploy.id, info)
                                    setAlert(true)
                                    setTimeout(() => { setAlert(false) }, 3000)
                                    setModalIsOpen3(false); r()

                                }}
                                className=" btn primary btnWide">{t('taip')}</button>

                            <button
                                onClick={() => {
                                    setModalIsOpen3(false); r()
                                }}
                                className=" btn primary btnWide">{t('ne')}</button>
                        </div>
                    </div>
                </Modal>
                {type == 0 ?
                    <div className='arapmoketa'>
                        <h3>{t('nuolaidos_kodas')}</h3>

                        <TextField
                            disabled={type == 0 ? false : true}
                            key={'nuolaidos_kodas'}
                            label={t("nuolaidos_kodas")}
                            // multiline
                            maxRows={1}
                            value={info.nuolaida ? info.nuolaida : ''}
                            onChange={value => { setInfo({ ...info, nuolaida: value.target.value }) }}
                        />
                    </div>
                    : ''}

            </div >
            : ''
    )
}

export default ModalVidus

const List = ({ data, info, update }) => {
    var list = data || []
    const { type } = useContext(TaskContext)
    var bendras = 0
    return (
        <div className='modalaz'>
            <ul className='panaudotosMedz'>
                <li className="row panaudotosMedziagos2">
                    <p>{t('pavadinimas')}</p>
                    <p>{t('mato_vienetas')}</p>
                    <p>{t('kaina')}</p>
                    <p>{t('Kiekis')}</p>
                    <p>{t('bendra')} </p>
                    <p>{t('veiksmas')} </p>
                </li>
            </ul>
            <ul className='panaudotosMedz'>
                {Object.keys(list).length > 0 ?
                    Object.keys(list).map((e, i) => {
                        return (
                            <li key={e + '-row-panaudotos'} className="row panaudotosMedziagos">
                                <p>{list[e].pavadinimas}</p>
                                <p>{list[e].mato_vienetas}</p>
                                <p className='panMedzLent'> {list[e].kaina}€</p>
                                <p className='panMedzLent'> {list[e].kiekis}vnt.</p>
                                <p className='panMedzLent'> {(list[e].kiekis * list[e].kaina).toFixed(2)}€</p>
                                {type == 0 ?
                                    <p className='panMedzLent'>
                                        <button className='btn primary pridetiMok' onClick={() => {
                                            update({
                                                'medziagos': {
                                                    ...list, [list[e]['pavadinimas']]: {
                                                        ...list[e],
                                                        apmoketa: list[e].apmoketa ? !list[e].apmoketa : true
                                                    }
                                                }
                                            }); list[e].apmoketa = list[e].apmoketa ? !list[e].apmoketa : true
                                        }}> {list[e].apmoketa ? t('apmoketa_m') : t('neapmoketa_m')}</button> </p>
                                    : ''}
                                <div className='hidden'>{bendras += list[e].kiekis * list[e].kiekis}</div>
                            </li>

                        )
                    })
                    : ''}
            </ul>
            {info.statusas == t('apmoketa') || info.statusas == t('neapmoketa') || info.statusas == t('pavedimu') ?
                <div className='lentelesSumm'>
                    <div className='pradirbtasLaikasSumm'>
                        <h4>{t('pradirbtasLaikas')}: {MakeLaikas(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta))}</h4>
                        <h4>{t('suma')} {Suma(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.panaudotosMediziagos, t('daugiklis'), t('iskvietimoMokestis'))}€</h4>

                    </div>
                    <div className='bendraSumaSumm'>
                        <h4>{t('bendraSuma')}: {Suma(new Date(info.pradirbtasLaikas.pradėta), new Date(info.pradirbtasLaikas.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis'))}€</h4>
                    </div>
                </div>
                :
                <h4>{t('suma')}: {(medziaguSkaiciuokle(data).toFixed(2))}€</h4>

            }
        </div>
    )
}


const ApmoketaBtn = (data, e, update, t) => {
    var list = data
    var e = e
    return (
        <p className='panMedzLent'>
            <button className='btn primary pridetiMok' onClick={() => {
                update({
                    'medziagos': {
                        ...list, [list[e]['pavadinimas']]: {
                            ...list[e],
                            apmoketa: list[e].apmoketa ? !list[e].apmoketa : true
                        }
                    }
                }); list[e].apmoketa = list[e].apmoketa ? !list[e].apmoketa : true
            }}> {list[e].apmoketa ? t('apmoketa_m') : t('neapmoketa_m')}</button>
        </p>
    )
}

const MakeLaikas = (start, end) => {
    var spentDays = end.getDay() - start.getDay()
    var spentHours = end.getHours() - start.getHours()
    var spentMinutes = end.getMinutes() - start.getMinutes()
    if (spentMinutes < 0) {
        spentHours = spentHours - 1
        spentMinutes = spentMinutes + 60
    }
    else if (spentMinutes < 10) { spentMinutes = "0" + spentMinutes }
    if (spentMinutes)
        return (<span>
            {spentDays ? spentDays + ':' : ''}{spentHours}:{spentMinutes}
        </span>)
}

const medziaguSkaiciuokle = (medziagos) => {
    var suma = 0
    if (medziagos && Object.keys(medziagos).length > -1) {
        Object.keys(medziagos).map(e => {
            suma += parseFloat(medziagos[e].kaina * medziagos[e].kiekis)
        })
        return suma
    } else {
        return 0
    }
}
