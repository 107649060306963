import { jsPDF } from "jspdf";
import Logo from "../../assets/logo_u.png";
import { font } from "../font";
import n2words from 'n2words'
const OutputType = {
    Save: "save", //save pdf as a file
    DataUriString: "datauristring", //returns the data uri string
    DataUri: "datauri", //opens the data uri in current window
    DataUrlNewWindow: "dataurlnewwindow", //opens the data uri in new window
    Blob: "blob", //return blob format of the doc,
    ArrayBuffer: "arraybuffer", //return ArrayBuffer format
};

export { OutputType, jsPDF };

/**
 *
 * @param { {
 *  outputType: OutputType | string,
 *  returnJsPDFDocObject?: boolean,
 *  fileName: string,
 *  orientationLandscape?: boolean,
 *  compress?: boolean,
 *  logo?: {
 *      src?: string,
 *      type?: string,
 *      width?: number,
 *      height?: number,
 *      margin?: {
 *        top?: number,
 *        left?: number
 *      }
 *   },
 *  stamp?: {
 *      inAllPages?: boolean,
 *      src?: string,
 *      type?: string,
 *      width?: number,
 *      height?: number,
 *      margin?: {
 *        top?: number,
 *        left?: number
 *      }
 *   },
 *   business?: {
 *       name?: string,
 *       address?: string,
 *       phone?: string,
 *       email?: string,
 *       email_1?: string,
 *       website?: string,
 *   },
 *   contact?: {
 *       label?: string,
 *       name?: string,
 *       address?: string,
 *       phone?: string,
 *       email?: string,
 *       imonesPavadinimas?: string,
 * imonesAdresas?:string,
 *       imonesKodas?: string,
 *       otherInfo?: string,
 *       pirkejoKodas?:string,
 *      pvm?:string,
 *   },
 *   invoice?: {
 *       label?: string,
 *       num?: number,
 *       invDate?: string,
 *       invGenDate?: string,
 *       headerBorder?: boolean,
 *       tableBodyBorder?: boolean,
 *       header?: 
 *        {
 *          title: string, 
 *          style?: { width?: number }
 *        }[],
 *       table?: any,
 *       invDescLabel?: string,
 *       invDesc?: string,
 *       additionalRows?: [{
 *           col1?: string,
 *           col2?: string,
 *           col3?: string,
 *           style?: {
 *               fontSize?: number
 *           }
 *       }],
 *   },
 *   parasai?:{
 *      israse?:string,
 *      prieme?:string,
 *      parasas?: {
 *      src?: string,
 *      type?: string,
 *      width?: number,
 *      height?: number,
 *      margin?: {
 *        top?: number,
 *        left?: number
 *      }
 *  },
 *   footer?: {
 *       text?: string,
 *       text2?: string,
 *   },
 *   pageEnable?: boolean,
 *   pageLabel?: string, } } props
 */
const suma = (elemnts, l) => {
    var suma = parseFloat(l)
    if (elemnts) {
        Object.keys(elemnts).map(e => {
            suma += elemnts[e].kaina * elemnts[e].kiekis

        })
    }
    return suma.toFixed(2)
}
const sumaBePVM = (elemnts, pvm, n, l) => {

    var suma = parseFloat(l - l * (pvm / 100))
    if (elemnts) {
        Object.keys(elemnts).map(e => {
            suma += (elemnts[e].kaina * elemnts[e].kiekis) - (elemnts[e].kaina * elemnts[e].kiekis * (pvm / 100))
        })
        if (n)
            suma = suma * (1 - (n / 100))
    }
    return suma.toFixed(2)
}
const sumaPVM = (elemnts, pvm, n, l) => {
    var suma = parseFloat(l * (pvm / 100))
    if (elemnts) {
        Object.keys(elemnts).map(e => {
            suma += (elemnts[e].kaina * elemnts[e].kiekis * (pvm / 100))
        })
        if (n)
            suma = suma * (1 - (n / 100))
    }
    return suma.toFixed(2)
}
const VisoSuma = (elemnts, pvm, n, l) => {
    var suma = parseInt(l)
    if (elemnts) {
        Object.keys(elemnts).map(e => {
            suma += elemnts[e].kaina * elemnts[e].kiekis
        })
        if (n)
            suma = suma * (1 - (n / 100))
    }
    return suma.toFixed(2)
}
const Apmokėta = (elemnts, pvm, n) => {
    var suma = 0
    if (elemnts) {
        Object.keys(elemnts).map(e => {
            if (elemnts[e].apmoketa)
                suma += elemnts[e].kaina * elemnts[e].kiekis
        })
        if (n)
            suma = suma * (1 - (n / 100))
    }
    return suma.toFixed(2)
}
const Sumoketi = (elemnts, pvm, n, l) => {
    var suma = parseFloat(l)
    if (elemnts) {
        Object.keys(elemnts).map(e => {
            if (!elemnts[e].apmoketa) {
                suma += elemnts[e].kaina * elemnts[e].kiekis
            }
        }
        )
        if (n)
            suma = suma * (1 - (n / 100))
    }
    return suma.toFixed(2) + ''
}
const skaiciaiIzodzius = (value, l) => {
    var ats = ''
    var sveikieji = parseInt(value)
    var liekana = parseInt(Math.round((value - sveikieji) * 100))
    ats += n2words(sveikieji, { lang: 'lt' }) + ' ' + eurai(sveikieji) + ' '
    ats += liekana ? n2words(liekana, { lang: 'lt' }) + ' ' + centai(liekana) : ''
    return ats
}
const eurai = (value) => {
    const lastDigit2Str = String(value).slice(-1); // 👉️ '7'
    const lastDigit2Num = Number(lastDigit2Str);
    if (value % 10 == 0 || 10 < value < 20) {
        return 'eurų'
    }
    if (lastDigit2Num == 1) {
        return 'euras'
    }

    return 'eurai'
}
const centai = (value) => {
    const lastDigit2Str = String(value).slice(-1); // 👉️ '7'
    const lastDigit2Num = Number(lastDigit2Str);
    if (value % 10 == 0 || 10 < value < 20) {
        return 'centų'
    }
    if (lastDigit2Num == 1) {
        return 'centas'
    }
    return 'centai'
}
const getWidthHeaderTranslation = (e) => {
    var longest = e.length
    if (Array.isArray(e)) {
        e.map(k => {
            if (k.length > longest) { longest = k.length }
        })
    }
    return longest
}
const MakeLaikas = (start, end) => {
    var start = start ? new Date(start) : new Date()
    var end = end ? new Date(end) : new Date()
    var spentDays = end.getDay() - start.getDay()
    var spentHours = end.getHours() - start.getHours()
    var spentMinutes = end.getMinutes() - start.getMinutes()
    if (spentMinutes < 0) {
        spentHours = spentHours - 1
        spentMinutes = spentMinutes + 60
    }
    else if (spentMinutes < 10) { spentMinutes = "0" + spentMinutes }
    if (spentMinutes) {
        var ats = spentDays > 0 ? spentDays + ':' : ''
        ats += spentHours + ':' + spentMinutes
        return ats + ''
    }
}
function suskaiciuotiSenus(id, e, v) {
    console.log(e, v)
    if (v) {
        v = v.split('-')
        v[1] = parseInt(v[1]) < 10 ? '0' + v[1] : v[1]
        v = v.join('-')
        console.log(v)
        return v
    }
    if (e == null) {
        return id + '-0' + 1
    }
    if (Array.isArray(e)) {
        let le = parseInt(e.length + 1)
        if (le < 10) { le = '0' + le }
        return id + '-' + le
    }
}
function apmokoejimoAdresas(info, t) {
    var ats = ''
    if (info.uzsakovoTipas == t('juridinis_asmuo')) {
        ats += t('i_adress') + ': ' + info.imonesAdresas
    }
    else if (info.uzsakovoTipas == t('fizinis_asmuo')) {
        ats += t('asmensAdresas') + ': ' + info.asmensAdresas
    }
    return ats
}
function kodasImonesAsmes(info, t) {
    var ats = ''
    if (info.imonesKodas) {
        ats += t('imonesKodas') + ": " + info.imonesKodas
    }
    else if (info.asmensKoda) {
        ats += t('asmensKodas') + ": " + info.asmensKodas
    }
    return ats
}
function adresas(adresas, namas, nr) {
    let ats = ''
    if (adresas)
        ats += adresas
    if (namas)
        ats += ', ' + namas
    if (nr)
        ats += '-' + nr

    return ats
}
function jsPDFInvoiceTemplate(props, id, t, darbuotojas, laikoKaina, laikas, tipasPdf) {
    let header = []
    laikoKaina = 0
    // if (props.medziagos)
    header = t('sasFHeader').map((e) => {
        var width = getWidthHeaderTranslation(e)
        return {
            title: e,
            style: {
                width: parseInt(getWidthHeaderTranslation(e) * 2.8),
            }
        }
    })
    let table = []
    if (props.medziagos) {
        table = Object.keys(props.medziagos).map((l, i) => {
            let e = props.medziagos[l]
            let pvm = parseFloat(t('pvm'))
            e.kaina = parseFloat(e.kaina)
            e.kiekis = parseFloat(e.kiekis)
            return [
                i + 1,
                e.pavadinimas,
                e.mato_vienetas ? e.mato_vienetas : '',
                e.kiekis.toFixed(2),//kiekis
                (e.kaina - (e.kaina * (pvm / 100))).toFixed(2),//be pvm
                ((e.kaina - (e.kaina * (pvm / 100))) * e.kiekis).toFixed(2),//suma be pvm
                pvm,//pvm
                ((e.kaina - (e.kaina - (e.kaina * (pvm / 100)))) * e.kiekis).toFixed(2),//pvm suma
                (e.kaina * e.kiekis).toFixed(2)//suma 
            ]
        })
    }
    const info = props
    props = {
        ...props,
        // orientationLandscape: true,
        outputType: tipasPdf ? tipasPdf : 'output',
        fileName: "PVM sąskaita faktūra \n" + "Serija" + info.data[0].split('.')[0] + ' - ' + id,
        logo: Logo,
        top: {
            pavadinimas: t('Isankstine_sf').toUpperCase(),
            serija: 'Serija [' + info.data[0].split('.')[0].split('-')[0] + '] Nr.' + suskaiciuotiSenus(id, info.seni, info.IDNr),
            data: info.data
        },
        business: {
            aboveName: 'Paslaugos teikėjas',
            name: 'UAB „Ūkvedžiai“',
            address: 'Adresas: Panerių g. 64, LT-03160 Vilnius',
            phone: 'Įmonės kodas: 305588699',
            email: 'PVM mokėtojo kodas: LT100013306910',
            email_1: '',
            atsSas: 'Atsiskaitomoji sąskaita',
            nr: 'Nr.: LT85 7180 3000 4246 7668',
            bankas: 'AB Šiaulių bankas, banko kodas  74800',
        },

        contact: {
            label: 'Klientas',
            name: info.vardas ? info.vardas + ' ' + info.pavarde : '',
            address: t('adresas') + ": " + adresas(info.adress, info.namoNr, info.butoNr),
            phone: t('tel') + ": " + info.tel,
            email: t('email') + ": " + info.email,
            pirkejoKodas: t('pirkejoKodas') + ": " + info.data[0].slice(2, 4) + '-' + suskaiciuotiSenus(id, info.seni, info.IDNr),
            imonesAdresas: apmokoejimoAdresas(info, t),
            imonesPavadinimas: info.imonesPavadinimas ? t('imonesPavadinimas') + ": " + info.imonesPavadinimas : '',
            imonesKodas: kodasImonesAsmes(info, t),
            pvm: info.PVM ? t('i_pvm') + ": " + info.PVM : '',
        },
        invoice: {
            label: 'lentele',
            header: header,
            table: table,
            additionalRows:
                [
                    { col2: t('isviso'), col3: suma(props.medziagos, laikoKaina) + '' },
                    { col2: props.nuolaida ? t('nuolaida') : t('nuolaida'), col3: props.nuolaida ? props.nuolaida + '%' : '0%' },
                    { col2: t('SumaBePVM'), col3: sumaBePVM(props.medziagos, parseFloat(t('pvm')), props.nuolaida, laikoKaina) },
                    { col2: t('PVMsuma'), col3: sumaPVM(props.medziagos, parseFloat(t('pvm')), props.nuolaida, laikoKaina) },
                    { col2: t('VisoSuma'), col3: VisoSuma(props.medziagos, parseFloat(t('pvm')), props.nuolaida, laikoKaina) },
                    { col2: t('Apmokėta'), col3: Apmokėta(props.medziagos, parseFloat(t('pvm')), props.nuolaida) },
                    { col2: t('Sumoketi'), col3: Sumoketi(props.medziagos, parseFloat(t('pvm')), props.nuolaida, laikoKaina) },
                    { col3: t('sumaZodziais') + ' ' + skaiciaiIzodzius(Sumoketi(props.medziagos, parseFloat(t('pvm')), props.nuolaida, laikoKaina)) },
                    { col3: t('apmokejimoTerminas') },

                ]
        },
        parasai: {
            // israse: t('sasakaitaIsrase') + ' ' + darbuotojas.Vardas + ' ' + darbuotojas.Pavarde,
            // prieme: info.vardas ? t('sasakitaPrieme') + ' ' + info.vardas + ' ' + info.pavarde : '',
            // parasas: {
            //     src: info.parasas.data ? info.parasas.data.attributes.url : '',
            //     type: '',
            //     width: '',
            //     height: '',
            //     margin: {
            //         top: '',
            //         left: ''
            //     }
            // },
        },
        footer: {
            text: t('pvmFooter'),
            text2: t('pvmFooter2'),
        },
        pageEnable: true,
        pageLabel: info.data[0].slice(2, 4) + '-' + id + '   ',
    }
    const param = {
        outputType: props.outputType || "",
        returnJsPDFDocObject: props.returnJsPDFDocObject || false,
        fileName: props.fileName || "",
        orientationLandscape: props.orientationLandscape || false,
        compress: props.compress || false,
        pageEnable: props.pageEnable || false,
        pageLabel: props.pageLabel || "Page",
        logo: {
            src: props.logo || "",
            type: props.logo?.type || "",
            width: props.logo?.width || "",
            height: props.logo?.height || "",
            margin: {
                top: props.logo?.margin?.top || 0,
                left: props.logo?.margin?.left || 0,
            },
        },
        top: {
            pavadinimas: props.top?.pavadinimas || "",
            serija: props.top?.serija || "",
            data: props.top?.data || "",
        },
        parasai: {
            israse: props.parasai?.israse || "",
            prieme: props.parasai?.prieme || "",
            parasas: {
                src: props.parasai.parasas?.src || "",
                type: props.parasai.parasas?.type || "",
                width: props.parasai.parasas?.width || "",
                height: props.parasai.parasas?.height || "",
                margin: {
                    top: props.parasai.parasas?.margin?.top || 0,
                    left: props.parasai.parasas?.margin?.left || 0,
                },

            }
        },
        stamp: {
            inAllPages: props.stamp?.inAllPages || false,
            src: props.stamp?.src || "",
            width: props.stamp?.width || "",
            height: props.stamp?.height || "",
            margin: {
                top: props.stamp?.margin?.top || 0,
                left: props.stamp?.margin?.left || 0,
            },
        },
        business: {
            name: props.business?.name || "",
            aboveName: props.business?.aboveName || "",
            address: props.business?.address || "",
            phone: props.business?.phone || "",
            email: props.business?.email || "",
            email_1: props.business?.email_1 || "",
            website: props.business?.website || "",
            atsSas: props.business?.atsSas || '',
            nr: props.business?.nr || '',
            bankas: props.business?.bankas || '',
        },
        contact: {
            label: props.contact?.label || "",
            name: props.contact?.name || "",
            address: props.contact?.address || "",
            phone: props.contact?.phone || "",
            email: props.contact?.email || "",
            imonesAdresas: props.contact?.imonesAdresas || "",
            imonesPavadinimas: props.contact?.imonesPavadinimas || "",
            imonesKodas: props.contact?.imonesKodas || "",
            pirkejoKodas: props.contact?.pirkejoKodas || "",
            pvm: props.contact?.pvm || "",
        },
        invoice: {
            label: props.invoice?.label || "",
            num: props.invoice?.num || "",
            invDate: props.invoice?.invDate || "",
            invGenDate: props.invoice?.invGenDate || "",
            headerBorder: props.invoice?.headerBorder || false,
            tableBodyBorder: props.invoice?.tableBodyBorder || false,
            header: props.invoice?.header || [],
            table: props.invoice?.table || [],
            invDescLabel: props.invoice?.invDescLabel || "",
            invDesc: props.invoice?.invDesc || "",
            additionalRows: props.invoice?.additionalRows?.map(x => {
                // if (x.col1 || x.col2 || x.col3) {
                return {
                    col1: x?.col1 || "",
                    col2: x?.col2 || "",
                    col3: x?.col3 || "",
                    style: {
                        fontSize: x?.style?.fontSize || 12,
                    }
                }
                // }
            })
        },
        footer: {
            text: props.footer?.text || "",
            text2: props.footer?.text2 || "",
        },
    };

    const splitTextAndGetHeight = (text, size) => {
        var lines = doc.splitTextToSize(text, size);
        return {
            text: lines,
            height: doc.getTextDimensions(lines).h,
        };
    };
    if (param.invoice.table && param.invoice.table.length) {
        if (param.invoice.table[0].length != param.invoice.header.length)
            throw Error("Length of header and table column must be equal.");
    }

    const options = {
        orientation: param.orientationLandscape ? "landscape" : "",
        compress: param.compress
    };

    var doc = new jsPDF(options);
    doc.addFileToVFS("WorkSans-normal.ttf", font);
    doc.addFont("WorkSans-normal.ttf", "WorkSans", "normal");
    doc.setFont("WorkSans");
    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    //starting at 15mm
    var currentHeight = 15;
    var rr = 0
    var minBeforeTable = 0
    //var startPointRectPanel1 = currentHeight + 6;

    const pdfConfig = {
        width: 1200,
        headerTextSize: 15,
        labelTextSize: 12,
        fieldTextSize: 8,
        lineHeight: 6,
        subLineHeight: 4,
    };



    if (param.logo.src) {
        var imageHeader = '';
        if (typeof window === "undefined") {
            imageHeader = param.logo.src;
        } else {
            imageHeader = new Image();
            imageHeader.src = param.logo.src;
        }
        //doc.text(htmlDoc.sessionDateText, docWidth - (doc.getTextWidth(htmlDoc.sessionDateText) + 10), currentHeight);
        if (param.logo.type)
            doc.addImage(
                imageHeader,
                param.logo.type,
                10 + param.logo.margin.left,
                currentHeight - 5 + param.logo.margin.top,
                param.logo.width,
                param.logo.height
            );
        else
            doc.addImage(
                imageHeader,
                10 + param.logo.margin.left,
                currentHeight - 5 + param.logo.margin.top,
                param.logo.width,
                param.logo.height
            );

    }
    //top info
    doc.setTextColor(colorBlack);
    doc.setFontSize(pdfConfig.headerTextSize - 4);
    doc.text(docWidth / 2, currentHeight, param.top.pavadinimas, "center");
    currentHeight += pdfConfig.lineHeight;
    doc.text(docWidth / 2, currentHeight, param.top.serija, "center");
    currentHeight += pdfConfig.lineHeight;
    doc.text(docWidth / 2, currentHeight, param.top.data, "center");
    currentHeight += pdfConfig.lineHeight;

    currentHeight += 10;
    //line breaker after logo & business info
    if (param.invoice.header.length) {
        currentHeight += pdfConfig.subLineHeight;
        doc.line(10, currentHeight, docWidth - 10, currentHeight);
    }
    currentHeight += 10;
    rr = currentHeight
    // info apie ukvedziai
    doc.setFontSize(pdfConfig.fieldTextSize);
    doc.setTextColor(colorGray);
    doc.text(docWidth - 10, currentHeight, param.business.aboveName, "right");
    currentHeight += pdfConfig.lineHeight;
    doc.setTextColor(colorBlack);
    doc.setFontSize(pdfConfig.headerTextSize);
    doc.text(docWidth - 10, currentHeight, param.business.name, "right");
    doc.setFontSize(pdfConfig.fieldTextSize);
    doc.setTextColor(colorGray);
    currentHeight += pdfConfig.subLineHeight;
    doc.text(docWidth - 10, currentHeight, param.business.address, "right");
    currentHeight += pdfConfig.subLineHeight;
    doc.text(docWidth - 10, currentHeight, param.business.phone, "right");
    doc.setFontSize(pdfConfig.fieldTextSize);
    // doc.setTextColor(colorGray);
    currentHeight += pdfConfig.subLineHeight;
    doc.text(docWidth - 10, currentHeight, param.business.email, "right");

    currentHeight += pdfConfig.subLineHeight;
    doc.text(docWidth - 10, currentHeight, param.business.email_1, "right");

    currentHeight += pdfConfig.subLineHeight;
    doc.text(docWidth - 10, currentHeight, param.business.atsSas, "right");
    currentHeight += pdfConfig.subLineHeight;
    doc.text(docWidth - 10, currentHeight, param.business.nr, "right");
    currentHeight += pdfConfig.subLineHeight;
    doc.text(docWidth - 10, currentHeight, param.business.bankas, "right");

    minBeforeTable = currentHeight + pdfConfig.lineHeight
    //Contact part
    currentHeight = rr
    doc.setTextColor(colorGray);
    doc.setFontSize(pdfConfig.fieldTextSize);
    // currentHeight += pdfConfig.lineHeight;
    if (param.contact.label) {
        doc.text(10, currentHeight, param.contact.label);
        currentHeight += pdfConfig.lineHeight;
    }

    doc.setTextColor(colorBlack);
    doc.setFontSize(pdfConfig.headerTextSize);
    if (param.contact.name) doc.text(10, currentHeight, param.contact.name);

    if (param.invoice.label && param.invoice.num) {
        doc.text(
            docWidth - 10,
            currentHeight,
            param.invoice.label + param.invoice.num,
            "right"
        );
    }

    if (param.contact.name || (param.invoice.label && param.invoice.num))
        currentHeight += pdfConfig.subLineHeight;

    doc.setTextColor(colorGray);
    doc.setFontSize(pdfConfig.fieldTextSize);

    if (param.contact.address || param.invoice.invDate) {
        doc.text(10, currentHeight, param.contact.address);
        doc.text(docWidth - 10, currentHeight, param.invoice.invDate, "right");
        currentHeight += pdfConfig.subLineHeight;
    }

    if (param.contact.phone || param.invoice.invGenDate) {
        doc.text(10, currentHeight, param.contact.phone);
        doc.text(docWidth - 10, currentHeight, param.invoice.invGenDate, "right");
        currentHeight += pdfConfig.subLineHeight;
    }

    if (param.contact.email) {
        doc.text(10, currentHeight, param.contact.email);
        currentHeight += pdfConfig.subLineHeight;
    }
    const add = (value) => {
        if (value) {
            doc.text(10, currentHeight, value);
            currentHeight += pdfConfig.subLineHeight;
        }
    }
    add(param.contact.pirkejoKodas)
    add(param.contact.imonesPavadinimas)
    add(param.contact.imonesAdresas)
    add(param.contact.imonesKodas)
    add(param.contact.pvm)

    if (param.contact.otherInfo)
        doc.text(10, currentHeight, param.contact.otherInfo);
    else currentHeight -= pdfConfig.subLineHeight;
    //end contact part

    //TABLE PART
    //var tdWidth = 31.66;
    //10 margin left - 10 margin right
    if (currentHeight < minBeforeTable) { currentHeight = minBeforeTable }
    var tdWidth = (doc.getPageWidth() - 20) / param.invoice.header.length;

    //#region TD WIDTH
    if (param.invoice.header.length > 2) { //add style for 2 or more columns
        const customColumnNo = param.invoice.header.map(x => x?.style?.width || 0).filter(x => x > 0);
        let customWidthOfAllColumns = customColumnNo.reduce((a, b) => a + b, 0);
        tdWidth = (doc.getPageWidth() - 20 - customWidthOfAllColumns) / (param.invoice.header.length - customColumnNo.length);
    }
    //#endregion

    //#region TABLE HEADER BORDER
    var addTableHeaderBorder = () => {
        currentHeight += 2;
        const lineHeight = 7;
        let startWidth = 0;
        for (let i = 0; i < param.invoice.header.length; i++) {
            const currentTdWidth = param.invoice.header[i]?.style?.width || tdWidth;
            if (i === 0) doc.rect(10, currentHeight, currentTdWidth, lineHeight);
            else {
                const previousTdWidth = param.invoice.header[i - 1]?.style?.width || tdWidth;
                const widthToUse = currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
                startWidth += widthToUse;
                doc.rect(startWidth + 10, currentHeight, currentTdWidth, lineHeight);
            }
        }
        currentHeight -= 2;
    };
    //#endregion

    //#region TABLE BODY BORDER
    var addTableBodyBorder = (lineHeight) => {
        let startWidth = 0;
        for (let i = 0; i < param.invoice.header.length; i++) {
            const currentTdWidth = param.invoice.header[i]?.style?.width || tdWidth;
            if (i === 0) doc.rect(10, currentHeight, currentTdWidth, lineHeight);
            else {
                const previousTdWidth = param.invoice.header[i - 1]?.style?.width || tdWidth;
                const widthToUse = currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
                startWidth += widthToUse;
                doc.rect(startWidth + 10, currentHeight, currentTdWidth, lineHeight);

            }
        }
    };
    //#endregion

    //#region TABLE HEADER
    var addTableHeader = () => {
        if (param.invoice.headerBorder) addTableHeaderBorder();

        currentHeight += pdfConfig.subLineHeight;
        doc.setTextColor(colorBlack);
        doc.setFontSize(pdfConfig.fieldTextSize);
        //border color
        doc.setDrawColor(colorGray);
        currentHeight += 2;

        let startWidth = 0;
        //////////////////////////////////////////////
        // headerio skaiciavimai///////////
        ///////////////////////////////
        param.invoice.header.forEach(function (row, index) {
            if (index == 0) doc.text(row.title, 11, currentHeight);
            else {
                var currentTdWidth = row?.style?.width || tdWidth;
                const previousTdWidth = param.invoice.header[index - 1]?.style?.width || tdWidth;
                const widthToUse = currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
                startWidth += widthToUse;
                if (Array.isArray(row.title)) {
                    let addh = 0
                    row.title.map(e => {
                        doc.text(e, startWidth + 11, currentHeight + addh);
                        addh += pdfConfig.subLineHeight
                    })
                } else {
                    doc.text(row.title, startWidth + 11, currentHeight);

                }

            }
        });

        currentHeight += pdfConfig.subLineHeight + 2;
        doc.setTextColor(colorGray);
    };
    //#endregion

    addTableHeader();

    //#region TABLE BODY
    var tableBodyLength = param.invoice.table.length;
    param.invoice.table.forEach(function (row, index) {
        doc.line(10, currentHeight, docWidth - 10, currentHeight);

        //get nax height for the current row
        var getRowsHeight = function () {
            let rowsHeight = [];
            row.forEach(function (rr, index) {
                const widthToUse = param.invoice.header[index]?.style?.width || tdWidth;

                let item = splitTextAndGetHeight(rr.toString(), widthToUse - 1); //minus 1, to fix the padding issue between borders
                rowsHeight.push(item.height);
            });

            return rowsHeight;
        };

        var maxHeight = Math.max(...getRowsHeight());

        //body borders
        if (param.invoice.tableBodyBorder) addTableBodyBorder(maxHeight + 1);

        let startWidth = 0;
        row.forEach(function (rr, index) {
            const widthToUse = param.invoice.header[index]?.style?.width || tdWidth;
            let item = splitTextAndGetHeight(rr.toString(), widthToUse - 1); //minus 1, to fix the padding issue between borders

            if (index == 0) doc.text(item.text, 11, currentHeight + 4);
            else {
                const currentTdWidth = rr?.style?.width || tdWidth;
                const previousTdWidth = param.invoice.header[index - 1]?.style?.width || tdWidth;
                const widthToUse = currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
                startWidth += widthToUse;
                doc.text(item.text, 11 + startWidth, currentHeight + 4);
            }
        });

        currentHeight += maxHeight - 4;

        //td border height
        currentHeight += 7;

        //pre-increase currentHeight to check the height based on next row
        if (index + 1 < tableBodyLength) currentHeight += maxHeight;

        if (
            param.orientationLandscape &&
            (currentHeight > 185 ||
                (currentHeight > 178 && doc.getNumberOfPages() > 1))
        ) {
            doc.addPage();
            currentHeight = 10;
            if (index + 1 < tableBodyLength) addTableHeader();
        }

        if (
            !param.orientationLandscape &&
            (currentHeight > 265 ||
                (currentHeight > 255 && doc.getNumberOfPages() > 1))
        ) {
            doc.addPage();
            currentHeight = 10;
            if (index + 1 < tableBodyLength) addTableHeader();
            //else
            //currentHeight += pdfConfig.subLineHeight + 2 + pdfConfig.subLineHeight - 1; //same as in addtableHeader
        }

        //reset the height that was increased to check the next row
        if (index + 1 < tableBodyLength && currentHeight > 30)
            // check if new page
            currentHeight -= maxHeight;
    });
    doc.line(10, currentHeight, docWidth - 10, currentHeight); //if we want to show the last table line 
    //#endregion

    var invDescSize = splitTextAndGetHeight(
        param.invoice.invDesc,
        docWidth / 2
    ).height;

    //#region PAGE BREAKER
    var checkAndAddPageLandscape = function () {
        if (!param.orientationLandscape && currentHeight + invDescSize > 270) {
            doc.addPage();
            currentHeight = 10;
        }
    }

    var checkAndAddPageNotLandscape = function (heightLimit = 173) {
        if (param.orientationLandscape && currentHeight + invDescSize > heightLimit) {
            doc.addPage();
            currentHeight = 10;
        }
    }
    var checkAndAddPage = function () {
        checkAndAddPageNotLandscape();
        checkAndAddPageLandscape();
    }
    //#endregion

    //#region Stamp
    var addStamp = () => {
        let _addStampBase = () => {
            var stampImage = '';
            if (typeof window === "undefined") {
                stampImage = param.stamp.src;
            } else {
                stampImage = new Image();
                stampImage.src = param.stamp.src;
            }

            if (param.stamp.type)
                doc.addImage(
                    stampImage,
                    param.stamp.type,
                    10 + param.stamp.margin.left,
                    docHeight - 22 + param.stamp.margin.top,
                    param.stamp.width,
                    param.stamp.height
                );
            else
                doc.addImage(
                    stampImage,
                    10 + param.stamp.margin.left,
                    docHeight - 22 + param.stamp.margin.top,
                    param.stamp.width,
                    param.stamp.height
                );
        };

        if (param.stamp.src) {
            if (param.stamp.inAllPages)
                _addStampBase();
            else if (!param.stamp.inAllPages && doc.getCurrentPageInfo().pageNumber == doc.getNumberOfPages())
                _addStampBase();
        }
    }
    //#endregion

    checkAndAddPage();

    doc.setTextColor(colorBlack);
    doc.setFontSize(pdfConfig.labelTextSize);
    currentHeight += pdfConfig.lineHeight;

    //#region additionalRows
    if (param.invoice.additionalRows?.length > 0) {
        //#region Line breaker before invoce total
        // doc.line(docWidth / 2, currentHeight, docWidth - 10, currentHeight);

        // currentHeight += pdfConfig.lineHeight;
        //#endregion

        for (let i = 0; i < param.invoice.additionalRows.length; i++) {
            currentHeight += pdfConfig.lineHeight;
            doc.setFontSize(param.invoice.additionalRows[i].style.fontSize);

            doc.text(docWidth / 1.5, currentHeight, param.invoice.additionalRows[i].col1, "right");
            doc.text(docWidth - 25, currentHeight, param.invoice.additionalRows[i].col2, "right");
            doc.text(docWidth - 10, currentHeight, param.invoice.additionalRows[i].col3, "right");
            checkAndAddPage();
        }
    }
    //#endregion

    checkAndAddPage();

    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
    //   currentHeight += pdfConfig.subLineHeight;
    doc.setFontSize(pdfConfig.labelTextSize);

    //#region Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
        for (let i = 1; i <= doc.getNumberOfPages(); i++) {
            doc.setFontSize(pdfConfig.fieldTextSize - 2);
            doc.setTextColor(colorGray);

            if (param.pageEnable) {
                doc.text(docWidth / 2, docHeight - (10 + pdfConfig.lineHeight), param.footer.text, "center");
                doc.text(docWidth / 2, docHeight - 10, param.footer.text2, "center");
                doc.setPage(i);
                doc.text(
                    param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
                    docWidth - 20,
                    doc.internal.pageSize.height - 6
                );
            }

            checkAndAddPageNotLandscape(183);
            checkAndAddPageLandscape();
            addStamp();
        }
    }
    //#endregion

    //#region INVOICE DESCRIPTION
    var addInvoiceDesc = () => {
        doc.setFontSize(pdfConfig.labelTextSize);
        doc.setTextColor(colorBlack);

        doc.text(param.invoice.invDescLabel, 10, currentHeight);
        currentHeight += pdfConfig.subLineHeight;
        doc.setTextColor(colorGray);
        doc.setFontSize(pdfConfig.fieldTextSize - 1);

        var lines = doc.splitTextToSize(param.invoice.invDesc, docWidth / 2);
        //text in left half
        doc.text(lines, 10, currentHeight);
        currentHeight +=
            doc.getTextDimensions(lines).h > 5
                ? doc.getTextDimensions(lines).h + 6
                : pdfConfig.lineHeight;

        return currentHeight;
    };
    addInvoiceDesc();
    //#endregion

    addStamp();
    // Mano prikurta
    function addParasai() {
        doc.setFontSize(pdfConfig.labelTextSize);
        doc.setTextColor(colorBlack);

        doc.text(param.parasai.israse, 10, currentHeight);
        currentHeight += pdfConfig.lineHeight * 2;

        var lines = doc.splitTextToSize(param.parasai.prieme, docWidth / 2);
        //text in left half
        doc.text(lines, 10, currentHeight);
        currentHeight +=
            doc.getTextDimensions(lines).h > 5
                ? doc.getTextDimensions(lines).h + 6
                : pdfConfig.lineHeight;
        if (param.parasai.parasas.src)
            doc.addImage(
                param.parasai.parasas.src,
                10 + param.parasai.parasas.margin.left,
                currentHeight - 5 + param.parasai.parasas.margin.top,
                param.parasai.parasas.width,
                param.parasai.parasas.height
            );
        return currentHeight;
    };
    addParasai();

    //#region Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {
        doc.setFontSize(pdfConfig.fieldTextSize - 2);
        doc.setTextColor(colorGray);
        doc.text(docWidth / 2, docHeight - 10, param.footer.text, "center");
        doc.text(
            param.pageLabel + "1 / 1",
            docWidth - 20,
            doc.internal.pageSize.height - 6
        );
    }
    //#endregion

    let returnObj = {
        pagesNumber: doc.getNumberOfPages(),
    };

    if (param.returnJsPDFDocObject) {
        returnObj = {
            ...returnObj,
            jsPDFDocObject: doc,
        };
    }

    if (param.outputType === "save") doc.save(param.fileName);
    else if (param.outputType === "blob") {
        const blobOutput = doc.output("blob");
        returnObj = {
            ...returnObj,
            blob: blobOutput,
        };
    } else if (param.outputType === "datauristring") {
        returnObj = {
            ...returnObj,
            dataUriString: doc.output("datauristring", {
                filename: param.fileName,
            }),
        };
    } else if (param.outputType === "arraybuffer") {
        returnObj = {
            ...returnObj,
            arrayBuffer: doc.output("arraybuffer"),
        };
    } else
        doc.output(param.outputType, {
            filename: param.fileName,
        });

    var string = tipasPdf != 'open' ? doc.output('datauristring') : doc.output('datauristring');
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
    if (tipasPdf === "open") {
        var x = window.open();
        x.document.open();
        x.document.write(embed);
        x.document.close();
    }
    return string;
    return returnObj;
}

export default jsPDFInvoiceTemplate;