import React, { useEffect, useState, useContext } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { t } from 'i18next';
import { TaskContext } from '../../AppStates';


const Price = ({ info, setInfo }) => {
  const [expand, setExpand] = useState(true)

  return (
    <div className="modalCont modalContVidus">
      <div className="sectionCont">
        <div className="section">
          {expand ? <ExpandLessIcon
            sx={{ cursor: 'pointer' }}
            color='primary'
            onClick={() => {
              expand ? setExpand(false) : setExpand(true)
            }} /> :
            <ExpandMoreIcon
              sx={{ cursor: 'pointer' }}
              color='primary'
              onClick={() => {
                expand ? setExpand(false) : setExpand(true)
              }} />}
          <h4 className="sectionName">Kaina</h4>
        </div>
      </div>
      <div className={expand ? 'modalContVidus' : 'modalContVidus hidden'}>
        <div className="mediagos">
          <List
            data={info.medziagos}
            info={info}
          />
        </div>
        <div className="viso-kaina">
          <div className="row summaryCont">
            <div className="summaryTypography midTxt">Medžiagos viso:</div>
            <div className="summaryTypography">{(medziaguSkaiciuokle(info.medziagos).toFixed(2))}€</div>
          </div>
          <div className="row summaryCont">
            <div className="summaryTypography midTxt">Darbo laikas:</div>
            <div className="summaryTypography">{MakeLaikas(new Date(info?.pradirbtasLaikas?.pradėta), new Date(info?.pradirbtasLaikas?.pabaigta))}</div>
            <div className="summaryTypography">{info.statusas == t('samata') || info.statusas == t('samata_baigta') ? '0.00' : TikLaikas(new Date(info?.pradirbtasLaikas?.pradėta), new Date(info?.pradirbtasLaikas?.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis'))}€</div>
          </div>
          <div className="row summaryCont">
            <div className="summaryTypography midTxt">Nuolaida:</div>
            <div className="summaryTypography">{info.nuolaida ? info.nuolaida : 0}%</div>
            <div className="summaryTypography">-{(TikLaikas(new Date(info?.pradirbtasLaikas?.pradėta), new Date(info?.pradirbtasLaikas?.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis')) * ((info.nuolaida / 100))).toFixed(2)}€</div>
          </div>

          <div className="row summaryCont">
            <div className="summaryTypography midTxt">Suma:</div>
            <div className="summaryTypography">{Suma(new Date(info?.pradirbtasLaikas?.pradėta), new Date(info?.pradirbtasLaikas?.pabaigta), info.medziagos, 
            info.statusas == t('samata') || info.statusas == t('samata_baigta') ? 0 : t('daugiklis'),
             t('iskvietimoMokestis'))}€</div>
          </div>
          {info.nuolaida > 0 ?
            <div className="row summaryCont">
              <div className="summaryTypography midTxt">Suma su nuolaida:</div>
              <div className="summaryTypography">{(Suma(new Date(info?.pradirbtasLaikas?.pradėta),
                new Date(info?.pradirbtasLaikas?.pabaigta),
                info.medziagos,
                info.statusas == t('samata') || info.statusas == t('samata_baigta') ? 0 : t('daugiklis'),
                t('iskvietimoMokestis')) - ((TikLaikas(new Date(info?.pradirbtasLaikas?.pradėta), new Date(info?.pradirbtasLaikas?.pabaigta), info.medziagos, t('daugiklis'), t('iskvietimoMokestis')) * ((info.nuolaida / 100))))).toFixed(2)}€</div>
            </div>
            : ''}
        </div>
      </div>
    </div>
  )
}

export default Price

const List = ({ data, update }) => {
  var list = data || []
  const { type } = useContext(TaskContext)
  var bendras = 0
  return (
    <ol >
      {Object.keys(list).length > 0 ?
        Object.keys(list).map((e, i) => {
          return (
            <li key={e + '-row-panaudotos'}>
              <div className='row spaceBetween'>
                <div>
                  <p>{list[e].pavadinimas}</p>
                  <div className='row'>
                    <p className='space'> {list[e].kiekis}vnt. </p>
                    <p className='space'> {(list[e].kiekis * list[e].kaina).toFixed(2)}€</p>
                  </div>
                </div>
                <div className='hidden'>{bendras += list[e].kiekis * list[e].kiekis}</div>
              </div>
            </li>
          )
        })
        : ''}
    </ol>
  )
}

export const MakeLaikas = (start, end) => {
  if (!end && start) return '00:00'
  var spentDays = end.getDay() - start.getDay()
  var spentHours = end.getHours() - start.getHours()
  var spentMinutes = end.getMinutes() - start.getMinutes()
  if (spentMinutes < 0) {
    spentHours = spentHours - 1
    spentMinutes = spentMinutes + 60
  }
  else if (spentMinutes < 10) { spentMinutes = "0" + spentMinutes }
  if (spentMinutes)
    return (<span>
      {spentDays ? spentDays + ':' : ''}{spentHours}:{spentMinutes}
    </span>)
}
export const MakeTime = (start, end) => {
  if (!end && start) return '00:00'
  var spentDays = end.getDay() - start.getDay()
  var spentHours = end.getHours() - start.getHours()
  var spentMinutes = end.getMinutes() - start.getMinutes()
  var ats = ''
  if (spentMinutes < 0) {
    spentHours = spentHours - 1
    spentMinutes = spentMinutes + 60
  }
  else if (spentMinutes < 10) { spentMinutes = "0" + spentMinutes }
  return end - start
  return spentHours + ':' + spentMinutes

}

export const medziaguSkaiciuokle = (medziagos = null) => {
  var suma = 0
  if (medziagos && Object.keys(medziagos).length > 0) {
    Object.keys(medziagos).map(e => {
      suma += parseFloat(medziagos[e].kaina * medziagos[e].kiekis)
    })
    return suma
  } else {
    return 0
  }
}
export const Suma = (start, end, medziagos, daugiklis, iskvietimo) => {
  var spentDays = end.getDay() - start.getDay()
  var spentHours = end.getHours() - start.getHours()
  var spentMinutes = end.getMinutes() - start.getMinutes()
  var counter = 30 //kas kiek laikas skaiciuojamas
  var total = (spentDays * 1440) + (spentHours * 60) + spentMinutes
  var ats = timeLapse(total / counter, daugiklis)
  var medziagos = medziagos
  var suma = medziaguSkaiciuokle(medziagos)
  console.log(daugiklis)
  return parseFloat(suma + ats).toFixed(2) + ''
}
export const TikLaikas = (start, end, medziagos, daugiklis, iskvietimo) => {
  var spentDays = end.getDay() - start.getDay()
  var spentHours = end.getHours() - start.getHours()
  var spentMinutes = end.getMinutes() - start.getMinutes()
  var counter = 30 //kas kiek laikas skaiciuojamas
  var total = (spentDays * 1440) + (spentHours * 60) + spentMinutes
  var ats = timeLapse(total / counter, daugiklis)
  return parseFloat(ats).toFixed(2) + ''
}
export const timeLapse = (counter, bill) => {
  var ats = 0
  for (let index = 0; index <= counter; index++) {
    ats += bill
  }
  if (ats == 0 || ats < bill * 2) {
    ats = bill * 2
  }
  return ats
}
