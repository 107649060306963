import { useContext, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { t } from 'i18next';
import Delete from "../assets/delete.png";
import Edit from "../assets/edit.png";
import CustomButton from '../Components/Mui/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { useStrapiObjectClass } from '../hooks/useStrapiObject';
import { Loader } from '../Components/Loader';
import DefaultModal from '../Components/Modal';
import NaujienosModal from '../Components/Naujienos/NaujienosModal';
import { addImagesToPost } from '../Components/ImagesAddComponent';
import { TaskContext } from '../AppStates';

const Naujienos = () => {
  const [all, setAll] = useState([])
  const [info, setInfo] = useState({})
  const [modalIsOpen, setIsOpen] = useState(false);
  const [imgArray, setImageArray] = useState([])
  const [id, setId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false)
  const [r, setR] = useState(0)
  const { token, path } = useContext(TaskContext)
  const { data, single: naujiena, getAll, getSingle, update, create: newNaujiena, DELETE } = useStrapiObjectClass({ name: 'naujienos', extraFilter: '&populate=*&sort=date:desc' })
  useEffect(() => { getAll() }, [getAll, r])
  useEffect(() => {
    setAll(data);
    if (id) { getSingle(id) }
  }, [data])
  // useEffect(() => {
  //   if (id) setInfo(naujiena.attributes)
  // }, [naujiena])
  useEffect(() => {
    if (id)
      getSingle(id)
  }, [id])
  const [alert, setAlert] = useState(false)
  return (
    <div className='containerNews'>
      <div className='newsBtnCont'>
        <CustomButton
          startIcon={<AddIcon />}
          onClick={() => {
            setIsOpen(true)
            setInfo({})
            setId(null)
          }}
          text='prideti_naujiena'
          className='reverseColorBtn'
        />
      </div>
      {all.length > 0 ? (
        <div className='lentele naujienos'>
          {all.map((item, i) => (
            <div key={'naujiena-' + i} className="naujiena">
              {/* {item.attributes.showImg.data != undefined ? */}
                <img className='naujienaGridImgimg' key={'naujiena-img-' + i} src={item?.attributes?.showImg?.data?.attributes.url || ''} alt='Pav' />
              {/* : ''} */}
              <h2 className='naujiena_title' key={'naujiena-title-' + i}>{item.attributes.title}</h2>
              {/* <h3 className='naujiena_subtitle' key={'naujiena-subtitle-' + i}>{item.attributes.subtitle}</h3> */}
              {item.attributes.date ?
                <h3 className='naujiena_subtitle' key={'naujiena-date-' + i}>{item.attributes.date.split('T')[0].replace('-', '.').replace('-', '.')}</h3>
                : ''}
              <div className='newsTableBtnCont'>
                <div className='deleteImgNews'>
                  <img src={Edit} onClick={() => {
                    setInfo({})
                    setId(item.id)
                    // setInfo(item.attributes)
                    setIsOpen(true)
                  }} />
                </div>
                <div className='deleteImgNews ' onClick={() => {
                  setDeleteModal(true); setId(item.id)
                }}>
                  {/* {t('trinti')} */}
                  <img src={Delete} />
                </div>
              </div>
            </div>
          )
          )}
        </div>
      ) : (
        <div><Loader colorMain='#db643e' /></div>
      )}
      {modalIsOpen ?

        <div className='modalCont'>
          <DefaultModal isOpen={modalIsOpen}
            requestClose={() => { setIsOpen(false); setId(null); setImageArray([]) }}
            classnames={'modal'}
            element={
              <NaujienosModal
                imagesArr={imgArray}
                deploy={naujiena?.attributes || {}}
                setIsOpen={setIsOpen}
                id={id}
                r={() => { setR(r + 1) }}
                newNaujiena={(info) => {
                  newNaujiena(info).then((data) => {
                    addImagesToPost(path, imgArray, data.data.id, token, 'showImg', "naujiena")
                      .then(() => {
                        setId(data.data.id)
                        setR(r + 1);
                      })
                  })
                }}
                update={update}
                setAlert={setAlert}
                imagesForCreate={(v) => { setImageArray(v); }}
              />
            }
          />
        </div>
        :
        ''
      }
      <DefaultModal isOpen={deleteModal}
        requestClose={() => { setDeleteModal(false); setId(null); }}
        classnames={'modal3'}
        element={<div
          className="centerContainer1 column">
          <button className="closeBtnMod" onClick={() => {
            setDeleteModal(false)
          }}>✕</button>
          <div className="modalInfo" >
            <h4>{t('ar_tikrai_istrinti')}</h4>
          </div>
          <div  >

            <button
              onClick={() => {
                DELETE(id, setR); setR(r + 1); setDeleteModal(false);
              }}
              className=" btn primary btnWide">{t('taip')}</button>
          </div>
        </div>}
      />
    </div >
  )
}

export default Naujienos


