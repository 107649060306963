import React, { useEffect } from 'react'
import CustomSignature from './MainModal/CustomSignature'
import { useContext, useState } from 'react';
import Info from './MainModal/Info'
import SfInfo from './MainModal/SfInfo'
import Modal from 'react-modal';
import { Box } from '@mui/material';
import Photos from './MainModal/Photos';
import Comments from './MainModal/Comments';
import Materials from './MainModal/Materials';
import Price from './MainModal/Price';
import Discount from './MainModal/Discount';
import Payment from './MainModal/Payment';
import Time from './MainModal/Time';
import { useStrapiObjectClass } from '../hooks/useStrapiObject';
import CustomButton from './Mui/CustomButton';
import { useTranslation } from 'react-i18next';
import { CheckStatus } from './Meistras';
import Pratesimas from '../functions/pratesimas';
import { TaskContext } from '../AppStates';



const Admin = ({ deploy, id, r, laikaKeitis, medziagos, darbuotojas }) => {
    const { user, path, token } = useContext(TaskContext)
    const { t } = useTranslation()
    const { data: old, getSingle: oldSingle, DELETE } = useStrapiObjectClass({ name: 'seni-d' })
    const [open, setOpen] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalIsOpen2, setModalIsOpen2] = useState(false)
    const [modalIsOpen3, setModalIsOpen3] = useState(false)
    const [modalIsOpen4, setModalIsOpen4] = useState(false)
    const [info, setInfo] = useState(deploy)
    const ForMaterial = [
        t('planuojama'),
        t('priskirtas'),
        t('vykdoma'),
        t('neapmoketa'),
        t('apmoketa'),
        t('priskirtas_tesiamas'),
        t('pavedimu'),
        t('pavedimu_pratestas'),
        t('nepriskirtas_pratestas'),
        t('samata')
    ]
    const pratesimuNaikinimui = [
        t('planuojama'),
        t('pavedimu_pratestas'),
        t('nepriskirtas_pratestas')
    ]
    const ForDiscountPriceSignaturePayment = [
        t('neapmoketa'),
        t('apmoketa'),
        t('pavedimu'),
        t('pavedimu_pratestas'),
        t('samata'),
    ]
    const ForStartJob = [
        t('priskirtas'),
        t('priskirtas_tesiamas')
    ]

    useEffect(() => { setInfo(deploy) }, [deploy, r])
    // useEffect(() => { setInfo(darbas) }, [darbas])

    const { data: darbas, getSingle, update: update } = useStrapiObjectClass({ name: 'darbai', extraFilter: 'populate=*' })
    const update1 = async (id = id, data = info) => { await update(id, data); r() }
    // const pratesti = async (id, statusas) => { await Pratesimas(path, token, 'seni-d/', 'darbai/' + id, setInfo, info, id, user, statusas); getSingle(id, setInfo) }
    const pratesti = async (id, statusas) => { await Pratesimas(path, token, 'seni-d/', 'darbai/' + id, setInfo, info, id, statusas); r() }

    return (

        <div className='testModal column'>
            {info.statusas == t('pavedimu') || info.statusas == t('pavedimu_pratestas') ?
                <CustomButton
                    color='success'
                    className='marginV10'
                    onClick={() => {
                        if (info.statusas == t('pavedimu')) {
                            info.statusas = t('apmoketa')
                            update(id, info)
                        }
                        else {
                            pratesti(id, t('apmoketa'))
                        }
                    }}
                    text='patvirtinti_mokejima'
                />
                : ''}
            {info.statusas == t('apmoketa') || info.statusas == t('neapmoketa') ?
                <CustomButton
                    text='pabaigti_uzsakyma'
                    onClick={() => {
                        setModalIsOpen(true)
                    }}
                />
                : ''
            }

            <Info
                info={info}
                setInfo={setInfo}
                laikaKeitis={laikaKeitis}
                id={id}
                r={r}
                update={update1}
            />
            <SfInfo
                info={info}
                setInfo={setInfo}
                id={id}
                r={r}
                update={update1}
            />
            <Photos
                info={info}
                id={id}
                r={r}
            />
            <Comments
                info={info}
                setInfo={setInfo}
                id={id}
                r={r}
                update={update1}
            />
            {CheckStatus(info.statusas, [t('samata'), t('samata_baigta')]) ?
                '' :
                <Time
                    info={info}
                    setInfo={setInfo}
                    update={update1}
                    id={id}
                />
            }
            {CheckStatus(info.statusas, [t('nepriskirtas')]) ?
                '' :
                <>
                    {CheckStatus(info.statusas, ForMaterial) ?
                        <>
                            <Materials
                                medziagos={medziagos}
                                info={info}
                                setInfo={setInfo}
                                update={update1}
                                id={id}
                            />
                            <Discount
                                info={info}
                                setInfo={setInfo}
                                update={update}
                                id={id}
                            />
                        </>
                        : ''}
                    {CheckStatus(info.statusas, ForDiscountPriceSignaturePayment) ?
                        <>

                            <Price
                                info={info}
                                setInfo={setInfo}
                            />
                            <CustomSignature
                                update={update1}
                                id={id}
                                info={info}
                                setInfo={setInfo}
                                r={r}
                            />
                            {CheckStatus(info.statusas, [t('samata'), t('samata_baigta')]) ?
                                '' :
                                <Payment
                                    info={info}
                                    setInfo={setInfo}
                                    update={update1}
                                    id={id}
                                    darbuotojas={darbuotojas}
                                    r={r}

                                />
                            }
                        </>
                        : ''
                    }
                </>
            }
            {CheckStatus(info.statusas, [t('planuojama'), t('nepriskirtas_pratestas')]) ?
                <CustomButton
                    className='marginV10'
                    onClick={() => { laikaKeitis(id, info) }}
                    text={'planuotiLaika2'}
                />
                : ''
            }
            {CheckStatus(info.statusas, [t('planuojama'), t('priskirtas_tesiamas')]) ?

                < CustomButton onClick={async () => {
                    await update(id, {
                        statusas:
                            t('nepriskirtas_pratestas'), darbuotojoId: null
                    });
                    r()
                }}
                    text='removeUser' />
                : ''
            }
            {console.log(info)}
            <div className='modalContVidus'>
                {CheckStatus(info.statusas, ForStartJob) ?
                    <CustomButton
                        className='marginV10'
                        color='success'
                        onClick={() => {
                            // info.pradirbtasLaikas = { pradėta: new Date() }
                            // info.statusas = t("vykdoma")
                            // update1(id, info)
                            // r()

                            setModalIsOpen2(true)
                        }}
                        text='pradetiDarba'
                    />
                    : ''
                }
                {CheckStatus(info.statusas, [t('vykdoma'), t('samata')]) ?
                    <CustomButton
                        className='marginV10'
                        color='error'
                        onClick={() => {
                            // info.pradirbtasLaikas = {
                            //     pradėta: info.pradirbtasLaikas.pradėta,
                            //     pabaigta: new Date()
                            // }
                            // info.statusas = t('neapmoketa')
                            // update1(id, info)
                            // r()

                            setModalIsOpen3(true)
                        }}
                        text='pabaigtiDarba'
                    />
                    : ''
                }
                {CheckStatus(info.statusas, pratesimuNaikinimui) ?
                    <CustomButton
                        className='marginV10'
                        color='error'
                        onClick={() => {
                            r()

                            setModalIsOpen4(true)
                        }}
                        text='panaikintiPratesima'
                    />
                    : ''
                }
            </div>

            <Modal
                isOpen={modalIsOpen}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen(false) }}
            >
                <div
                    className="centerContainer1 column">
                    <button className="closeBtnMod" onClick={() => {
                        setModalIsOpen(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_archyvuoti')}</h4>
                    </div>
                    <div  >

                        <CustomButton
                            onClick={() => {
                                info.statusas = t('pabaigta')
                                info.archyvuotas = true
                                update(id, info)
                                setModalIsOpen(false); r()
                            }}
                            text='taip'
                            className='margin5'
                        />

                        <CustomButton
                            onClick={() => {
                                info.statusas = t('pabaigta')
                                update(id, info)
                                setModalIsOpen(false); r()
                            }}
                            text='ne'
                            className='margin5'
                        />
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpen2}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen2(false) }}
            >
                <div
                    className="centerContainer1 column">
                    <button className="closeBtnMod" onClick={() => {
                        setModalIsOpen2(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_pradejote_darba')}?</h4>
                    </div>
                    <div  >

                        <CustomButton
                            onClick={() => {
                                info.pradirbtasLaikas = { pradėta: new Date() }
                                info.statusas = t("vykdoma")
                                update1(id, info)
                                setModalIsOpen2(false)
                                r()
                            }}
                            text='taip'
                            className='margin5'
                        />

                        <CustomButton
                            onClick={() => {

                                setModalIsOpen2(false); r()
                            }}
                            text='ne'
                            className='margin5'
                        />
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpen3}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen3(false) }}
            >
                <div
                    className="centerContainer1 column">
                    <button className="closeBtnMod" onClick={() => {
                        setModalIsOpen3(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_pabaigete_darba')}?</h4>
                    </div>
                    <div  >

                        <CustomButton
                            onClick={() => {
                                if (info.statusas != t('samata')) {
                                    info.pradirbtasLaikas = {
                                        pradėta: info.pradirbtasLaikas.pradėta,
                                        pabaigta: new Date()
                                    }
                                    info.statusas = t('neapmoketa')

                                }
                                else info.statusas = t('samata_baigta')
                                update1(id, info)


                                setModalIsOpen3(false)
                                r()
                            }}
                            className='margin5'
                            text='taip' />

                        <CustomButton
                            onClick={() => {
                                setModalIsOpen3(false);
                            }}
                            text='ne'
                            className='margin5'
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={modalIsOpen4}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen4(false) }}
            >
                <div
                    className="centerContainer1 column">
                    <button className="closeBtnMod" onClick={() => {
                        setModalIsOpen4(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('NoritePanaikintiPratesima')}?</h4>
                    </div>
                    <div  >

                        <CustomButton
                            onClick={() => {
                                info.pradirbtasLaikas = {
                                    pradėta: info.pradirbtasLaikas.pradėta,
                                    pabaigta: new Date()
                                }
                                // info.statusas = t('neapmoketa')
                                // update1(id, info)
                                // setModalIsOpen4(false)
                                let x = info.seni
                                console.log(info.seni, x[x.length - 1])
                                oldSingle(x[x.length - 1]).then(data => {
                                    let bex = x.splice(0, x.length - 1)
                                    update1(id, {
                                        seni: bex,
                                        statusas: data.attributes.statusas,
                                        medziagos: data.attributes.medziagos,
                                        issiustasPriminimas: true,
                                        darbuotojoId: data.attributes.darbuotojoId,
                                        pradirbtasLaikas: data.attributes.pradirbtasLaikas,
                                        Laikas: data.attributes.Laikas,
                                        data: data.attributes.data
                                    }
                                    )
                                }).then(() => { DELETE(x[x.length - 1]); r(); setModalIsOpen4(false) })
                            }}
                            className='margin5'
                            text='taip' />

                        <CustomButton
                            onClick={() => {
                                setModalIsOpen4(false);
                            }}
                            text='ne'
                            className='margin5'
                        />
                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default Admin