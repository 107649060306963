import { Tooltip } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import KategorijosNustatymas from "../functions/kategorijosNustatymas"
import Edit from "../assets/edit.png";
import DeletePng from "../assets/archive.png";
import { t } from 'i18next';


const MobileGrid = (props) => {
    var renderData = []
    const [info, setInfo] = useState(props.rows ? props.rows : [])
    useEffect(() => { setInfo(props.rows) }, [props])

    const add = (e) => {
        let add = ''
        if (e.seni) {
            add += '-' + parseInt(e.seni.length + 1)
        }
        return add
    }
    var e = props.e

    return (
        Array.isArray(info) ? (
            <div>
                {info.map((e, i) =>
                (<div key={'darbas' + e.id + 'mobile-grid' + i} className="mobile grid row" onClick={(event) => { e.modal(e.id) }}>
                    <div> <div className="bold"> {t('id')} </div><div>
                        {e.data.slice(0, 4) + '-' + e.id + add(e)}
                    </div></div>
                    <div><div className="bold">{t('data')}</div> <div>{e.data}</div></div>
                    <div><div className="bold">{t('statusas')}</div><div>{e.statusas}</div></div>
                    <div><div className="bold">{t('adresas')}</div><div>{e.adresas}</div></div>
                    <div><div className="bold">{t('tel')}</div><div>{e.tel}</div></div>
                    <div>
                        <div className="bold">{t('Kategorija')}</div>
                        <div>  <Kategorija e={e} /></div>
                    </div>
                    {/* <div className="mobEdit">
                        <div className="bold">{t('redaguoti')}</div>
                        <Delete e={e} />
                    </div> */}
                    <div><div className="bold">{t('meistras')}</div><div>{e.darbuotojoId}</div></div>
                    <div className="mobEdit">
                        <div className="bold">{t('archyvuoti')}</div>
                        <div>  <Archyvuoti e={e} /></div>

                    </div>
                </div>)
                )}
            </div>

        ) : (
            'No data'
        )
    )
}
export default MobileGrid

const Kategorija = (props) => {
    var e = props.e
    return (<div>
        <Tooltip title={e.kategorija}>
            <img
                className={e.kategorija + ' kategorijos'}
                src={KategorijosNustatymas(e.kategorija)}
                onClick={(event) => {
                    e.modal(e.id)
                }}
            /></Tooltip>
    </div>)
}
const Delete = (props) => {
    var e = props.e
    return (
        <div
            className="deleteImg"
            onClick={(event) => {
                e.modal(e.id)
            }}
        >
            <img src={Edit} />
        </div>
    );
}
const Archyvuoti = (props) => {
    var e = props.e
    return (
        <div
            className="deleteImg"
            onClick={(event) => {
                event.stopPropagation()
                e.archyvui(e.id)
            }}
        >
            <img src={DeletePng} />
        </div>
    );
}