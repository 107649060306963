import React, { useEffect } from 'react'
import Singnature from '../../functions/signature';
import { t } from 'i18next';
import Images, { addImagesToPost, imgDelete } from '../ImagesAddComponent';
import { useContext, useState } from 'react';
import { TaskContext } from '../../AppStates';
import { Loader } from '../Loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CustomButton from '../Mui/CustomButton';

const CustomSignature = ({ info, setInfo, id, update, r }) => {
    const { user, type, token, path } = useContext(TaskContext)
    const [yraParasas, setYraParasas] = useState(false)

    useEffect(() => {
        setYraParasas(info?.parasas.data ? true : false)
        console.log(info)
    }, [info])
    // if(!yraParasas){

    //     return <Loader/>
    // }
    const [expand, setExpand] = useState(true)

    return (
        <div className="modalCont modalContVidus">
            <div className="sectionCont">
                <div className="section">
                    {expand ? <ExpandLessIcon
                        sx={{ cursor: 'pointer' }}
                        color='primary'
                        onClick={() => {
                            expand ? setExpand(false) : setExpand(true)
                        }} /> :
                        <ExpandMoreIcon
                            sx={{ cursor: 'pointer' }}
                            color='primary'
                            onClick={() => {
                                expand ? setExpand(false) : setExpand(true)
                            }} />}
                    <h4 className="sectionName">Parašas</h4>
                </div>
            </div>
            <div className={expand ? ' modalContVidus' : ' modalContVidus hidden'}>
                <div>
                    <Singnature
                        t={(value) => { t(value) }}
                        parasas={info?.parasas || ''} //ar yra parasas
                        delete={async (value) => { let x = await imgDelete(path, value, token); r(); setYraParasas(false); return x }}
                        r={() => { r() }}
                        save={async (value) => {
                            let x = await addImagesToPost(path, [value], id, token, 'parasas', 'darbas').then(data => { 
                                
                                r()
                                })
                            setYraParasas(true)
                            return x
                        }} />
                </div>

            </div>
        </div>
    )
}

export default CustomSignature