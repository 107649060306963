import './css/Modal.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import React, { useContext, useEffect, useState, } from 'react';
import { TaskContext } from '../AppStates';
import './css/calendar.css'
import { Calendar } from 'react-multi-date-picker';
import ImportAll from '../functions/importAll';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CustomButton from './Mui/CustomButton';


const ModalTimePickerVidus = (props) => {
    const { path, token, type, user } = useContext(TaskContext)
    const [darbai, setDarbai] = useState([])
    const [grafikai, setGrafikai] = useState([])
    const [kategorija, setKategorija] = useState('')
    const [datos, setDatos] = useState({});
    const [kdate, ksetDate] = useState([]);
    const [laikas, setLaikas] = useState('')
    const [laikai, setLaikai] = useState([])
    const [dienos, setDienos] = useState([])
    const [uzimtos, setUzimtos] = useState([])
    const [galimos, setGalimos] = useState([])
    const [seniD, setSeniD] = useState(props.seni)
    const [disabledSave, setDisabledSave] = useState(true)

    const [seni, setSeni] = useState([])
    const [dabartiniai, setDabartiniai] = useState([])
    const [darbuotojas, setDarbuotojas] = useState(props.darbuotojas)
    const get = async () => {
        var more = ''
        if (type != 0) { more = '&filters[darbuotojoId][$eq]=' + user.id }
        if (darbuotojas?.id) { more = '&filters[darbuotojoId][$eq]=' + darbuotojas.id }
        await ImportAll(path, token, 'darbai/?filters[statusas][$ne]=Atšauktas&filters[kategorija][$containsi]=' + kategorija + more, setSeni);
        await ImportAll(path, token, 'seni-d/?filters[kategorija][$containsi]=' + kategorija + more, setDabartiniai);
    }
    useEffect(() => { setDarbai(seni.concat(dabartiniai)) }, [seni, dabartiniai])
    const get2 = async () => {
        var more = ''
        if (type != 0) { more = '&filters[userId][$eq]=' + user.id }
        if (darbuotojas?.id) { more = '&filters[userId][$eq]=' + darbuotojas.id }
        await ImportAll(path, token, 'darbo-dienos/?filters[Kategorija][$containsi]=' + t('kategorija').indexOf(kategorija) + more, setGrafikai);
    }
    useEffect(() => {
        setKategorija(props.info.attributes.kategorija)
        console.log(props)
    }, [])
    useEffect(() => {
        get();
        get2();
    }, [kategorija])
    useEffect(() => {
        var g = []
        var d = []
        if (grafikai.length > 0) {
            grafikai.map(e => {
                var obj = e.attributes.datos
                g.push(Object.keys(obj).map(k => {
                    return {
                        data: k.replaceAll('/', '-'),
                        laikas: obj[k].laikas
                    }
                }))
            })
            setDienos(g)
        }

        if (darbai.length != 0) {

            darbai.map(e => {
                console.log(e)
                if (e.attributes && e.attributes.data)
                    d.push({
                        data: e.attributes?.data.map(k => { return k }),
                        laikas: e.attributes.Laikas[0]
                    })
            })
            setUzimtos(d)
        }
    }, [grafikai, darbai])
    useEffect(() => { setDatos(suskaiciuotiDienas(uzimtos, dienos, t)) }, [uzimtos, dienos])

    useEffect(() => {
        setLaikas('')
        if (kdate.month) {
            let x = datos[getData(kdate)].laikai.map(e => {
                if (e.value > 0)
                    return e.laikas
                else
                    return ''
            })
            setLaikai(x)
        }
    }, [kdate])

    const getData = (date) => {
        if (date.month)
            return new Date(date.year, date.month.number - 1, date.day + 1).toISOString().split("T")[0]
    }
    const laikaiGet = (multi, single) => {
        if (multi.indexOf(single) > -1) {
            return true
        } else { return false }
    }

    const checkTimes = (diena) => {

        let arr = diena.laikai.map(e => { if (e.value > 0) { return true } })
        if (arr.indexOf(true) > -1) {
            return false
        }
        return true
    }



    const weekDays = ["S", "P", "A", "T", "K", "Pn", "Š"]
    const months = ["Sausis", "Vasaris", "Kovas", "Balandis", "Gegužė", "Birželis", "Liepa", "Rugpjūtis", "Rugsėjis", "Spalis", "Lapkritis", "Gruodis"]


    return (

        <div className='TimePickerContainer'>
            <h2>{t('kalendorius')}</h2>
            <div className='column '>
                <div className="calendarContainer">
                    <Calendar
                        mapDays={({ date }) => {
                            if (!datos[getData(date)] || checkTimes(datos[getData(date)])) return {
                                disabled: true,
                                style: { color: 'rgba(0, 0, 0, 0.33)' },
                                // onClick: () => alert("weekends are disabled")
                            }
                        }}
                        calendarType='ISO 8601'
                        minDate={new Date()}
                        value={kdate}
                        weekDays={weekDays}
                        months={months}
                        // selectMultiple={true}
                        onFocusedDateChange={ksetDate}
                        weekStartDayIndex={1}
                    />
                </div>
                <div className="iskvietimasLaikas">
                    {t('laikas').map(e => (
                        laikaiGet(laikai, e) ?
                            <CustomButton
                                onClick={(k) => { if (laikaiGet(laikai, e)) { setLaikas(e) } }}
                                key={e}
                                // disabled={!laikaiGet(laikai, e)?true:false}
                                variant={laikas == e ? 'contained' : 'outlined'}
                                text={e}
                                className='margin5'
                            />
                            : ''
                    )
                    )}
                </div>

                <CustomButton
                    disabled={laikas ? false : true}
                    onClick={() => {
                        let save = {
                            data: [getData(kdate)],
                            Laikas: [laikas],
                            statusas: props.info.attributes.statusas == t('planuojama') ? t('priskirtas_tesiamas') : t('nepriskirtas')
                        }
                        if (seniD && seniD[0]) {
                            save.statusas = t('nepriskirtas_pratestas')
                        }
                        if (darbuotojas.id) {
                            save.statusas = t('priskirtas')
                        }
                        if (darbuotojas.id && seniD && seniD[0]) {
                            save.statusas = t('priskirtas_tesiamas')
                        }
                        props.update(props.info.id, save)
                    }}
                    // className={disabledSave ? '' : 'disabled'}
                    text='issaugoti'
                />
            </div>

        </div>
    )
}
export default ModalTimePickerVidus




const suskaiciuotiDienas = (uzimtos, dienos, t) => {
    var suskaiciuotos = {}
    dienos.map(grafikai => {
        grafikai.map(diena => {
            if (!suskaiciuotos[diena.data]) {
                suskaiciuotos[diena.data] = {
                    data: diena.data,
                    laikai: t('laikas').map((e, i) => {
                        return {
                            value: 0,
                            laikas: e,
                            add: () => { suskaiciuotos[diena.data].laikai[i].value += 2 },
                            subs: () => { suskaiciuotos[diena.data].laikai[i].value -= 1 }
                        }
                    })
                }
            }

            suskaiciuotos[diena.data].laikai.map((l, i) => {//data set
                // diena.laikas.map((e) => {//laikas 
                if (diena.laikas.indexOf(l.laikas) != -1) {
                    l.add()
                }

                // })
            })
        }
        )

    })
    uzimtos.map(darbas => {
        if (suskaiciuotos[darbas.data])
            suskaiciuotos[darbas.data].laikai.map(l => {
                if (l.laikas == darbas.laikas) {
                    l.subs()
                }
            })
    })
    return suskaiciuotos
}
const theme = createTheme({
    palette: {
        primary: {
            main: '#DB643E'
        }
    }
})

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        color: '#DB643E',
        background: '#f0eee7',
        width: '100%',

    }),


    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}