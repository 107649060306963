import Modal from 'react-modal';
const DefaultModal = ({ isOpen, classnames, element, requestClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            ariaHideApp={false}
            className={classnames}
            shouldCloseOnOverlayClick={true}
            onRequestClose={requestClose}
        >
            {element}
        </Modal>
    )
}
export default DefaultModal 
