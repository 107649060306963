import './css/Modal.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import React, { useEffect, useState, } from 'react';
import { ValidateUser } from '../functions/validation'
import Alert from '@mui/material/Alert';
import { useContext } from 'react';
import { TaskContext } from '../AppStates';
import UserPickerSelectKablukas from './UserPickerSelectKablukas.js';
import CustomButton from './Mui/CustomButton';

const ModalVidus = ({ r, data, master, setDarbuotojas, kategorija, id,
    update, darbuotojas, updateUser, modalGetDarbuotojai }) => {
    const { t } = useTranslation()
    const { type } = useContext(TaskContext)
    const [info, setInfo] = useState(data.attributes || {})
    const [alert, setAlert] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const change = (name, value, index, pavadinimas, kaina) => {
        var arr = info.vienetai
        arr[index] = value
        setInfo({ ...info, vienetai: arr })
    }
    useEffect(() => { setInfo(data.attributes) }, [data])
    const changeUser = () => { modalGetDarbuotojai() }
    return (info && master && Object.keys(info).length != 0 && Object.keys(master).length != 0 ?
        <div>
            <div className='kablukasTexts'>
                <TextField
                    key={'Pavadinimas'}
                    label={t('ValstybinisNumeris')}
                    // multiline
                    disabled={type != 0 ? true : false}
                    maxRows={1}
                    value={info.Numeris ? info.Numeris : ''}
                    onChange={(e) => {
                        setInfo({ ...info, Numeris: e.target.value })
                    }}
                    className=' marginV10'
                />
                {/* <UserPickerSelectKablukas
                    disabled={disabled ? true : false}
                    key={'userPickerKablukas'}
                    deploy={info}
                    id={id}
                    darbuotojas={info.darbuotojoId}
                    r={r}
                    update={update}
                    className=' marginV10'
                /> */}

                <div className='kablukoVidujeMedziagos'>
                    {info && info.medziagos ? master.attributes.medziagos.map((e, i) => (
                        <div className='row' key={i}>
                            <TextField
                                disabled
                                key={i + '-Pavadinimas'}
                                label={t('pavadinimas')}
                                multiline
                                value={e.pavadinimas ? e.pavadinimas : ''}
                                size='small'
                                className='margin5 widthProc'
                            />
                            {/* <TextField
                                disabled
                                key={i + '-mato_vienetas'}
                                label={t('mato_vienetas')}
                                maxRows={1}
                                value={e.mato_vienetas ? e.mato_vienetas : ''}
                                size='small'
                                className='margin5'
                            /> */}
                            <TextField
                                disabled
                                key={i + '-Reikalingas-Kiekis'}
                                label={t('ReikalingasKiekis')}
                                // multiline
                                maxRows={1}
                                value={e.kiekis ? e.kiekis : ''}
                                size='small'
                                className='margin5 widthProc3'
                            />
                            <TextField
                                className={info.vienetai[i] && parseInt(e.kiekis) > parseInt(info.vienetai[i]) || e.kiekis == '' ? 'Truksta margin5 widthProc3' : parseInt(info.vienetai[i]) > parseInt(e.kiekis) ? 'Perteklius margin5 widthProc3' : 'bull margin5 widthProc3'}
                                key={i + '-Kiekis'}
                                label={ t('Kiekis') + ' ' + e.mato_vienetas }
                                disabled={type != 0 ? true : false}
                                // multiline
                                maxRows={1}
                                // error={ValidateUser(parseInt(info.vienetai[i]), "number") ? false : true}
                                // helperText={"Kiekį sudaro tik skaičiai"}
                                value={info.vienetai[i] ? info.vienetai[i] : ''}
                                onChange={value => {
                                    if (ValidateUser(value.target.value, "number")) {
                                        change('vienetai', value.target.value, i)
                                    }
                                }}
                                size='small'

                            />
                            
                            <TextField
                                disabled
                                key={i + '-KainaVnt'}
                                label={t('Kaina') + ' ' + e.mato_vienetas}
                                maxRows={1}
                                value={e.kaina ? e.kaina : ''}
                                size='small'
                                className='margin5 widthProc3 kabMarginLine'
                            />

                        </div>
                    )) : ''}
                </div>
                <div className='gridColumn'>
                    {
                        type != 0 ?
                            '' :
                            <CustomButton
                                onClick={() => {
                                    if (info.medziagos) { update(id, info) }
                                    setAlert(true)
                                    setTimeout(() => { setAlert(false) }, 3000)

                                }}
                                text='issaugoti'
                                className='width100'
                            />
                    }
                    {
                        type != 0 ?
                            '' :
                            <CustomButton
                                onClick={() => {
                                    var save = []
                                    master.attributes.medziagos.map(e => {
                                        save.push(e.kiekis)
                                    })
                                    if (info.medziagos) {
                                        update(id, { vienetai: save })
                                        r()
                                    }
                                    setAlert(true)
                                    setTimeout(() => { setAlert(false) }, 3000)

                                }}
                                text='atnaujinti_kabluka'
                                className='width100'
                            />}
                </div>
            </div>
            {
                alert ?

                    <Alert
                        variant="outlined"
                        severity="success"
                    >
                        {t('issaugota')}
                    </Alert>
                    : ''
            }

        </div>
        : ''
    )
}
export default ModalVidus


