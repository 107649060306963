import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { TaskContext } from "../AppStates";


export const useStrapiObjectClass = (options, defaultValue = []) => {
    const { token, path } = useContext(TaskContext);
    const { name, path: url = path, extraFilter } = options;
    const [data, setData] = useState(defaultValue);
    const [single, setSingle] = useState({});

    const getAll = useCallback(async (what = name + '?pagination[start]=0&pagination[limit]=9999999') => {
        let filter = ''
        if (extraFilter)
            filter = extraFilter
        axios
            .get(url + what + filter, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                // console.log(what, response.data.data)
                setData(response.data.data)
                return response.data.data
            })
            .catch((error) => {
                console.log('An error occurred:', error);
            });
    }, [name, url, token, extraFilter]);

    const getSingle = useCallback(async (id, setter) => {
        let filter = ''
        if (extraFilter)
            filter = extraFilter
        return axios
            .get(url + name + '/' + id + '?' + filter, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if (setter)
                    setter(response.data.data)

                setSingle(response.data.data)
                return response.data.data
            })
            .catch((error) => {
                console.log('An error occurred:', error);
            });
    }, [name, token, url]);

    const update = useCallback(async (id, info, setter) => {
        try {
            var fe = await fetch(url + name + '/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(
                    {
                        data: info
                    }
                ),
            })
            // if (setter)
            //     setter(await fe.json())
            console.log(fe)
            // return fe.json()
            // setSingle(await fe.json())
        }
        catch (e) { console.log(e) }
    }, [name, token, url])

    const DELETE = useCallback(async (id, getAll) => {
        return fetch(url + name + '/' + id, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            console.log(response.json());
            if (getAll)
                getAll()
            return true
        }).catch((e) => { console.log(e); })
    }, [name, token, url]);

    const create = useCallback(async (info = {}, setter) => {
        try {
            var fe = await fetch(url + name, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ data: info }),
            })
            var jsonn = await fe.json()
            var errorsM = ''
            if (jsonn?.error?.status == 400) {
                // console.log('js', jsonn, Object.names(jsonn.error.details).length)
                if (Object.names(jsonn.error.details).length > 0) {
                    jsonn.error.details.errors.details.error.map(e => {
                        errorsM += (e.message + '\n')
                    })
                }
                else errorsM = jsonn.error.message
                alert(errorsM)
            }
            if (setter)
                setter(jsonn)
            return jsonn
        }
        catch (e) {
            console.log(e);
            alert(e)
        }
    }, [name, token, url])
    const addImagesToPost = useCallback(async (images, id, type, api) => {
        const formData = new FormData();
        images.forEach(img => {
            formData.append("files", img.file, img.name);
        })

        formData.append("ref", "api::" + api + "." + api);
        formData.append("refId", id);
        formData.append("field", type);
        try {
            if (images.length > 0) {
                const res = await fetch(`${path}upload`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    body: formData,
                });
                await res.json().then(data => console.log(data))
                return res
            }
        } catch (error) {
            console.error("error while adding images to post", error)
            return error.response
        }
    }, [name, token, url])

    return {
        data,
        single,
        getSingle,
        getAll,
        update,
        DELETE,
        create
    }
}
