import React, { useState } from 'react'
import CustomButton from '../Mui/CustomButton.js'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { TextField } from '@mui/material';

const Discount = ({info, setInfo, id, update}) => {
    const [expand, setExpand] = useState(true)

    return (
        <div className="modalCont modalContVidus">
            <div className="sectionCont">
                <div className="section">
                    {expand ? <ExpandLessIcon
                        sx={{ cursor: 'pointer' }}
                        color='primary'
                        onClick={() => {
                            expand ? setExpand(false) : setExpand(true)
                        }} /> :
                        <ExpandMoreIcon
                            sx={{ cursor: 'pointer' }}
                            color='primary'
                            onClick={() => {
                                expand ? setExpand(false) : setExpand(true)
                            }} />}
                    <h4 className="sectionName">Nuolaida</h4>
                </div>
            </div>
            <div className={expand ? 'modalContVidus' : 'modalContVidus hidden'}>
                <TextField
                    color="primary"
                    variant="outlined"
                    label="Nuolaida, %"
                    size="small"
                    margin="none"
                    value={info.nuolaida ? info.nuolaida : ''}
                    onChange={value => { setInfo({ ...info, nuolaida: value.target.value }) }}
                />
                <CustomButton
                    variant="contained"
                    color="primary"
                    size="small"
                    text='Pritaikyti'
                    onClick={()=>{update(id, info)}}
                />
            </div>
        </div>
        // <div>
        //     <CustomTextField
        //         key={'nuolaidos_kodas'}
        //         label={t("nuolaidos_kodas")}
        //         value={info.nuolaida ? info.nuolaida : ''}
        //         onChange={value => { setInfo({ ...info, nuolaida: value.target.value }) }}
        //     >
        //     </CustomTextField>
        // </div>
    )
}

export default Discount