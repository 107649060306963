

import { useEffect } from "react";
import { useContext, useState } from "react";
import TaskContextProvider, { TaskContext } from "../AppStates";
import importAll from "../functions/importAll";
import DataGridKablukas from "../Components/DataGridKablukas";
import ModalKablukas from "../Components/ModalKablukas";
import plus from '../assets/plus.png'
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import LoadingTimer from "../functions/LoadingOn";
import { Loader } from "../Components/Loader";
import { useStrapiObjectClass } from "../hooks/useStrapiObject";
import { useUser } from "../hooks/useUser";
import CustomButton from "../Components/Mui/CustomButton";
import AddIcon from '@mui/icons-material/Add';



const Kablukas = () => {
    const { token, user, path, type } = useContext(TaskContext)
    const { t } = useTranslation()
    const [kategorija, setKategorija] = useState([])
    const [loading, setLoading] = useState(true)
    const [sortBy, setSortBy] = useState(0)
    const [tvarkyta, setTvarkya] = useState([])
    const [tvarkyta2, setTvarkya2] = useState([])
    const [k, setK] = useState({})
    const [r, setR] = useState(0)

    const [modal, setModal] = useState(false)
    const [id, setId] = useState('')
    const { data: darbai, getAll: getAll, create: Create, DELETE } = useStrapiObjectClass({ name: 'kablukas', })
    const { data: darbaiMaster, getAll: getAllMaster } = useStrapiObjectClass({ name: 'master-kablukai', })
    const { users: useriai, getAllUsers } = useUser()
    const [, setDarbai] = useState(darbai || [])
    const [, setDarbaiMaster] = useState(darbaiMaster || [])

    useEffect(() => {
        getAll();
        getAllMaster();
        getAllUsers()
    }, [getAllMaster, getAll, getAllUsers])

    const [deleteModal, setDeleteModal] = useState(false)

    useEffect(() => { getAll(); getAllUsers(); }, [r])
    useEffect(() => { console.log(useriai) }, [useriai])
    // useEffect(() => { console.log('tvarkyta2', tvarkyta2) }, [tvarkyta2])
    useEffect(() => { if (darbai) redoData(darbai, setTvarkya, 'kablukas/') }, [darbai, useriai])
    useEffect(() => { if (darbaiMaster) redoData(darbaiMaster, setTvarkya2, 'master-kablukai/') }, [darbaiMaster])


    const getVardas = (element) => {
        let id = element.attributes.darbuotojoId
        console.log(useriai)
        let vardas = ''
        useriai.map(darbuotojas => {
            if (darbuotojas.id == id) {
                vardas = darbuotojas.Vardas + ' ' + darbuotojas.Pavarde
            }
        })
        return vardas
    }
    const redoData = async (darbai = [], setter, name) => {//data for dataGrid
        var arr = []
        darbai.forEach(element => {
            if (!user.is_admin && !user.is_buhaltere) {
                if (element.attributes.darbuotojoId != user.id) {
                    return
                }
            }
            let vardas = getVardas(element) || ''
            arr.push(
                {
                    id: element.id,
                    Numeris: element.attributes.Numeris,
                    darbuotojoId: vardas,
                    kategorija: element.attributes.kategorija,
                    statusas: element.attributes.statusas,
                    modal: (id, kategorija) => { setModal(true); setId(id); setKategorija(kategorija) },
                    delete: (id) => { setDeleteModal(true); setId(id) }
                }
            )
        });
        setter(arr)
        return arr
    }
    const createKablukas = () => {

        Create({
            medziagos: darbaiMaster[0].attributes.medziagos,
            kategorija: 'Kablukas',
            Numeris: '',
            vienetai: darbaiMaster[0].attributes.medziagos.map(e => {
                return e.kiekis
            })
        }).then(data => {
            setId(data.data.id); setKategorija('kalbukas'); setModal(true)
            getAll()
        });
    }
    if (!darbaiMaster) {
        return <Loader />
    }
    return (
        <div className='container'>
            <LoadingTimer />
            {darbai.length == 0 ? (
                <div className='kablBtnCont'>
                    {type == 0 ?
                        <CustomButton
                            startIcon={<AddIcon />}
                            className='reverseColorBtn margin5'
                            onClick={() => {
                                createKablukas()
                            }}
                            text='prideti_kabluka'
                        />
                        : ''}
                </div>

            ) : (<>
                {modal && id ?
                    <ModalKablukas kablukai={darbai} id={id} kategorija={kategorija} setIsOpen={setModal} modalIsOpen={modal} path={path} token={token} r={getAll} setR={getAll} />
                    :
                    ''
                }
                <div className="darbuotojaiPos">
                    <div className='kablBtnCont'>
                        {type == 0 ?

                            <>
                                <CustomButton
                                    startIcon={<AddIcon />}
                                    className='reverseColorBtn margin5'
                                    onClick={() => {
                                        // path, token, what, id, info, get, flag
                                        createKablukas()
                                    }}
                                    text='prideti_kabluka'
                                />


                                <CustomButton
                                    startIcon={<AddIcon />}
                                    className='reverseColorBtn margin5'
                                    onClick={() => {
                                        if (tvarkyta2[0]) {
                                            setId(tvarkyta2[0].id)
                                            setKategorija(tvarkyta2[0].kategorija)
                                            setModal(true)
                                        }
                                    }}
                                    text='redaguoti_pagrindini_kabluka'
                                />
                            </>
                            : ''}
                    </div>
                    <div className='kablukasLent'>

                        <DataGridKablukas data={tvarkyta} dataMaster={tvarkyta2} />
                    </div>
                </div>
            </>
            )}


            <Modal
                isOpen={deleteModal}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setDeleteModal(false) }}
            >
                <div
                    className="centerContainer1 column">


                    <button className="closeBtnMod" onClick={() => {
                        setDeleteModal(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_tikrai_istrinti')}</h4>
                    </div>
                    <div  >

                        <button
                            onClick={async () => {
                                DELETE(id, getAll); setDeleteModal(false);
                            }}
                            className=" btn primary btnWide">{t('taip')}</button>
                    </div>
                </div>
            </Modal>

        </div>
    )
}
export default Kablukas


const sorting = (item, i) => {
    let start = item.slice(0)
    var arr
    if (i == 0) {
        arr = item.sort((a, b) => parseInt(a.id) > parseInt(b.id) ? 1 : -1)
        return arr
    }
    else if (item != start) {

        arr = item.sort((a, b) => a.attributes[[weNeed.tags[i]]] > b.attributes[[weNeed.tags[i]]] ? 1 : -1)
        return arr
    }
    else {
        arr = item.sort((a, b) => a.attributes[[weNeed.tags[i]]] < b.attributes[[weNeed.tags[i]]] ? 1 : -1)
        return arr
    }
}

const weNeed = {
    names: ['ID', 'Data', 'Laikas', 'Kategorija', 'Statusas', 'Veiksmas'],
    tags: ['id', 'data', 'Laikas', 'kategorija', 'statusas', 'IDNr']
}